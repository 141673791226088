/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';
import { ARTICLE_DETAIL } from '../../../constants/containerConstants';
import Transform from '../../../data-transform';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { DFP } from '../../Common/Dfp';
import { isEmpty } from 'lodash';
import { getItemStyleByContent } from '../../../utils/articleUtils';
import { inlineImageData, inlineVideoData, isApp, getDFPAdFields } from '../../../utils/utils';
import { ArticleDetailContentRange } from '../../../constants/articleDetailConstants';
import DFPAd from '../../DFPAd';

const CenterContent = ({ t, fields, isDFPScript }) => {
  const { manutds } = fields;
  return (
    <React.Fragment>
      {ArticleDetailContentRange.map((item, index) => {
        const textBoxKey = `TextBox${item}`;
        const videoKey = `Video${item}`;
        const imageKey = `Image${item}`;
        const captionKey = `Caption${item}`;
        const selectCardKey = `SelectCard${item}`;
        return (
          <React.Fragment key={index}>
            {/* --- Content Panel Start -----*/}
            {fields &&
              fields[textBoxKey] &&
              fields[textBoxKey].value &&
              fields[textBoxKey].value.length > 0 && (
                <div className="home-article-detail__content-panel" key={`content-panel${index}`}>
                  <div className="home-content-panel__details">
                    <div className="home-content-panel__details-text">
                      <div className="paragraph">
                        <RichText field={fields[textBoxKey]} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            {/* - Content Panel End -----*/}
            {/* - Image Panel start ----*/}
            {!isEmpty(fields && fields[imageKey] && fields[imageKey].value) &&
              isEmpty(fields && fields[videoKey] && fields[videoKey].value) && (
                <div className="home-article-detail__image-panel">
                  <Transform
                    key={`image${index}`}
                    componentName="image"
                    gridClass="grid-7"
                    isInlineImage={true}
                    tracking={false}
                    caption={fields[captionKey] && fields[captionKey].value}
                    data={inlineImageData({
                      crops:
                        fields[imageKey] && fields[imageKey].value && fields[imageKey].value.Crops,
                    })}
                    source="aws"
                    showStatsbar={false}
                    hideGradient={true}
                  />
                </div>
              )}
            {/* -- Image Panel Ends ----*/}
            {/* --- video panel---------*/}
            {!isEmpty(fields && fields[videoKey] && fields[videoKey].value) && (
              <div key={`video${index}`} className="home-article-detail__video-panel">
                <Transform
                  componentName="video"
                  gridClass="grid-7"
                  isInlineVideo={true}
                  tracking={false}
                  caption={fields[captionKey] && fields[captionKey].value}
                  data={inlineVideoData({
                    crops:
                      fields[imageKey] && fields[imageKey].value && fields[imageKey].value.Crops,
                    video: fields[videoKey] && fields[videoKey].value,
                  })}
                  source="aws"
                  showStatsbar={false}
                  isArticleDetail={true}
                  noVariant={true}
                  isEmbedVideo={true}
                />
              </div>
            )}
            {/* --- video panel---------*/}
            {/* -selectcard rendering---*/}
            {fields &&
              fields[selectCardKey] &&
              fields[selectCardKey].map((item, index) => {
                const style = getItemStyleByContent(item && item.fields);
                return (
                  <div key={`select-card-${index}`} className={style.className}>
                    <Transform
                      key={index}
                      data={item}
                      container={ARTICLE_DETAIL}
                      source="curate"
                      gridClass={style.gridClass}
                      isArticleDetail={true}
                      showStatsbar={false}
                      isInlineVideo={true}
                      noVariant={true}
                      type={'selectCard'}
                      isEmbedVideo={true}
                    />
                  </div>
                );
              })}
            {item === 2 && isDFPScript && (
              <DFPAd
                key={index}
                rendering={{ dataSource: manutds && manutds.value && manutds.value.Id }}
                fields={getDFPAdFields(
                  '[1024, 768, 320]',
                  '[970, 250]',
                  !isApp() ? '[750, 200]' : '[728, 90]',
                  '[300, 250]',
                  !isApp()
                    ? '/11820346/Hercules_ROS_970x250//750x200//300x250'
                    : '/11820346/Hercules_App_970x250//728x90//300x250',
                  manutds
                )}
              />
            )}
            {/*----- selectcard rendering ends ----*/}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

export default withTranslation()(CenterContent);
