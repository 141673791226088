/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useMemo, useState, useRef } from 'react';
import { getRandomId, deviceManagerUtil, playHereMedia } from '../../../utils/utils';
import BrightcoveClass, { pauseOnNextPrev } from '../../../utils/brightcove';
import { withTranslation } from 'react-i18next';
import { useSponsorValue } from '../../../context/sponsorContext';
import { THIRTY_SECOND, FIVE_SECOND } from '../../../constants/mutvConstants';
import { createLocalData, postDataToAPI } from '../../Mutv/WatchSync';
import { ACCOUNT_SUBSCRIPTION } from '../../../constants/globalConstants';
import { continueOnOtherAction, playHereAction } from '../../../actions/sponsorAction';

const InlineVideo = (props) => {
  const Brightcove = useMemo(() => new BrightcoveClass(), []);
  const [
    {
      userLoginDetail,
      isLoginChecked,
      deviceSynclimitReached,
      deviceInfo,
      continueOnOther,
      playHere,
    },
    dispatch,
  ] = useSponsorValue();
  const dataRef = useRef(null);
  const postRef = useRef(null);
  const loginRef = useRef(null);
  const deviceManagerObj = useRef(null);
  const {
    itemid,
    data,
    type,
    playerStatus,
    setShowSwitching,
    isMutv,
    myListResponse,
    lastResponseTime,
    reportStatus,
    status,
    dispatch: mutvDispatch,
    setSavePlayerStatus,
    liveRandomId,
    contentAccessState,
    playerStop,
    isMediaPlaying,
    isCloseIcon,
    isEmbedVideo,
  } = props;
  const [vidId] = useState(getRandomId());
  loginRef.current = { userLoginDetail, isLoginChecked };
  let isAdPlaying = false;
  let lastTriggerPos = null;
  useEffect(() => {
    if (lastResponseTime) {
      dataRef.current = { lastResponseTime, myListResponse, status };
    }
  }, [lastResponseTime, status]);

  useEffect(() => {
    if (lastResponseTime) {
      postRef.current = { reportStatus };
    }
  }, [reportStatus]);

  useEffect(() => {
    setTimeout(() => {
      Brightcove.brightCoveWebPlayer(
        vidId,
        itemid,
        'inlineVideo',
        data,
        {},
        (playerObj) => {
          console.log('creating BrightCove player');
          playerObj.on('ready', () => {
            setShowSwitching && setShowSwitching(true);
          });
          playerObj && playerObjActions(playerObj);
          playerObj && deviceManagerFunc(playerObj);
        },
        type,
        isMutv,
        isEmbedVideo
      );
    }, 100);
    return () => {
      Brightcove.closeVideo();
    };
  }, [Brightcove]);
  useEffect(() => {
    // Usage.
    const isMutvVideo = isMutv && Object.keys(isMutv).length > 0;
    !isMutvVideo && window.addEventListener('scroll', handler);
    return () => {
      !isMutvVideo && window.removeEventListener('scroll', handler);
    };
  }, []);

  //Device Mangement handling for subscribed content
  useEffect(() => {
    if (isLoginChecked && userLoginDetail) {
      deviceManagerObj.current = deviceManagerObj &&
        !deviceManagerObj.current && {
          UID: userLoginDetail && userLoginDetail.UID,
          deviceID: localStorage.getItem('deviceID'),
          dispatch: dispatch,
          deviceName: props.t('MUTVWebPlayer'),
        };
      const deviceId =
        deviceManagerObj && deviceManagerObj.current && deviceManagerObj.current.deviceID;
      const deviceCheck = deviceSynclimitReached && deviceInfo && deviceInfo.deviceId != deviceId;
      deviceCheck && Brightcove.pauseVideo();
    }
  }, [isLoginChecked, userLoginDetail, deviceSynclimitReached, deviceInfo]);

  useEffect(() => {
    if (continueOnOther && Brightcove) {
      Brightcove.closeVideo();
      continueOnOtherAction(dispatch, false);
      isMutv && isMutv.isEpg ? playerStop() : playerStop(false);
    }
  }, [continueOnOther]);
  useEffect(() => {
    playHereMedia(playHere, Brightcove, playHereAction, dispatch);
  }, [playHere]);

  useEffect(() => {
    if (
      Brightcove &&
      playerStatus &&
      playerStatus.isPodcast &&
      playerStatus.currentScreen === 'podcastEpisode'
    ) {
      if (playerStatus.podcast === 'pause') {
        const status = Brightcove.playerObj && Brightcove.playerObj.paused();
        setSavePlayerStatus && setSavePlayerStatus(status ? 'pause' : 'playing');
        Brightcove.pauseVideo();
      } else if (playerStatus.podcast === 'playing') {
        Brightcove.playVideo();
      }
    } else if (
      Brightcove &&
      playerStatus &&
      playerStatus.isPodcast &&
      playerStatus.currentScreen === 'videoEpisode'
    ) {
      if (playerStatus.video === 'pause') {
        Brightcove.pauseVideo();
      } else if (playerStatus.video === 'playing') {
        Brightcove.playVideo();
      }
    }
  }, [playerStatus]);

  useEffect(() => {
    if (liveRandomId && Brightcove) {
      Brightcove.liveEpgAnalytics(itemid, data);
    }
  }, [liveRandomId]);

  const playerObjActions = (playerObj) => {
    playerObj.on('playing', () => {
      if (contentAccessState === ACCOUNT_SUBSCRIPTION && isCloseIcon) {
        const mediaObj = {
          status: true,
          userDetail: deviceManagerObj && deviceManagerObj.current,
        };
        isMediaPlaying(mediaObj);
      }
    });
    playerObj.on('pause', () => {
      contentAccessState === ACCOUNT_SUBSCRIPTION &&
        isCloseIcon &&
        isMediaPlaying &&
        isMediaPlaying(false);
      if (isMutv) {
        const dur = parseInt(playerObj.duration());
        if (!playerObj.seeking() && !playerObj.ended()) {
          const phPos = parseInt(playerObj.currentTime());
          postWatchData(dur * 1000, phPos * 1000);
        }
      }
    });
    playerObj.on('timeupdate', () => {
      const dur = parseInt(playerObj.duration());
      if (!isAdPlaying) {
        let phPos = parseInt(playerObj.currentTime());
        if (isMutv && checkTime(phPos, lastTriggerPos, FIVE_SECOND)) {
          createLocalData(dur * 1000, phPos * 1000, isMutv.heroData); //Local Data Save
        }
        if (isMutv && checkTime(phPos, lastTriggerPos, THIRTY_SECOND)) {
          postWatchData(dur * 1000, phPos * 1000); //API call
        }
        lastTriggerPos = phPos;
      }
    });
    playerObj.on('ended', () => {
      if (isMutv) {
        const dur = parseInt(playerObj.duration());
        const phPos = parseInt(playerObj.currentTime());
        if (playerObj.ended() && phPos && dur) {
          postWatchData(dur * 1000, phPos * 1000);
        }
        isMutv.isPodcast && playerStop && setTimeout(() => playerStop(false), 50);
      }
    });
    playerObj.on('ads-pod-started', function (evt) {
      isAdPlaying = true;
    });
    playerObj.on('ads-pod-ended', function (evt) {
      isAdPlaying = false;
    });
  };

  const deviceManagerFunc = (playerObj) => {
    const manageObj = { deviceObj: deviceManagerObj.current, actionObj: playerObj };
    deviceManagerUtil(manageObj);
  };

  const postWatchData = (dur, playPos) => {
    postDataToAPI({
      dur,
      playPos,
      heroData: isMutv.heroData,
      myListResponse: dataRef.current && dataRef.current.myListResponse,
      userLoginDetail: loginRef.current && loginRef.current.userLoginDetail,
      lastResponseTime: dataRef.current && dataRef.current.lastResponseTime,
      isLoginChecked: loginRef.current && loginRef.current.isLoginChecked,
      reportStatus: postRef && postRef.current && postRef.current.reportStatus,
      dispatch: mutvDispatch,
      status: dataRef.current && dataRef.current.status,
    });
  };
  const checkTime = (phPos, lastTriggerPos, time) => {
    return phPos && phPos % time === 0 && phPos !== 0 && phPos !== lastTriggerPos ? true : false;
  };

  const handler = () =>
    raf(() => {
      const tester = document.querySelector(`#${vidId}`);
      if (tester && !gambitGalleryIsInView(tester)) {
        const getcurrentVideo = document.querySelector(`#${vidId}`);
        if (getcurrentVideo) {
          const videoAttr = getcurrentVideo.querySelector('video-js');
          if (videoAttr) {
            const videoAttrId = videoAttr.getAttribute('id');
            pauseOnNextPrev(videoAttrId);
          }
        }
      }
    });
  // checker function
  const gambitGalleryIsInView = (el) => {
    const scroll = window.scrollY || window.pageYOffset;
    const boundsTop = el.getBoundingClientRect().top + scroll;
    const viewport = {
      top: scroll,
      bottom: scroll + window.innerHeight,
    };
    const bounds = {
      top: boundsTop,
      bottom: boundsTop + el.clientHeight,
    };
    return (
      (bounds.bottom >= viewport.top && bounds.bottom <= viewport.bottom) ||
      (bounds.top <= viewport.bottom && bounds.top >= viewport.top)
    );
  };
  // requestAnimationFrame
  const raf =
    window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    function (callback) {
      callback();
    };
  return (
    <figure>
      <div
        id={vidId}
        aria-label="video player"
        data-module="video"
        className="mu-item__player"
      ></div>
    </figure>
  );
};
export default withTranslation()(InlineVideo);
