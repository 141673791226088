/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { Fragment, useState, useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import StatsBar from '../../Common/StatsBar';
import LazyLoad from 'react-lazyload';
import ImgCrop from '../../Common/ImgCrop';
import {
  createTagList,
  getStatsBarData,
  getSponsorData,
  getItemId,
  selectImgCrop,
  validateCbr,
  createTagArr,
  isApp,
  createDestinationUrl,
  isCollection,
  getLoggedInStatus,
  isMutvVideo,
  getParameterByName,
} from '../../../utils/utils';
import {
  CONTENT_TYPE,
  MUAppDeepLink,
  ACCOUNT_SUBSCRIPTION,
  FREE_TO_VIEW,
  REGISTERED,
} from '../../../constants/globalConstants';
import Sponsor from '../../Common/Sponsor';
import * as track from '../../../utils/analytics';
import { useModal } from '../../Common/Modal';
import loadable from '@loadable/component';
import InlineVideo from './InlineVideo';
import ErrorBoundary from '../../Common/ErrorBoundary';
import Loader from '../../Common/Loader';
import { useSponsorValue } from '../../../context/sponsorContext';
import AccessBadge from '../../Common/AccessBadge';
import MU from '../../../constants/muConstants';
import BackgroundVideo from './BackgroundVideo';
const VideoModal = loadable(() => import('../../Modals/VideoModal/VideoModal'));
const LiveStreamModal = loadable(() => import('../../Modals/VideoModal/LiveStreamModal'));
const RegistrationModal = loadable(() =>
  import('../../Modals/RegistrationModal/RegistrationModal')
);

const Video = (props) => {
  const {
    t,
    fields,
    gridClass,
    isMatchcenterCard,
    data,
    navCards,
    container,
    tracking,
    isInlineVideo,
    heroVarient,
    showStatsbar,
    isDestinationPage,
    noVariant,
    isBlogCard,
    playIconTabIndex,
    isArticleDetail,
    isEmbedVideo,
    isRelative,
    hideGradient,
    isLiveStreamViaEPG,
    LiveStreamViaEPGCallback,
    isCloseIcon,
    isMediaPlaying,
    setLiveStreamEnded,
    isHeroSlider,
    currentIndex,
    CTATitle,
    CTATitleRegisteredUser,
    CTATitleSubscribedUser,
    currentActiveSlide,
  } = props;
  const { ContentAccess, ContentType, __Published, manutds } = fields;
  const [{ isUserLogin, isLoginChecked, isUserSubscribe, isUserSkyId }] = useSponsorValue();
  const { showModal, closeModal, isModalVisible } = useModal();
  const {
    showModal: cbrShowModal,
    closeModal: cbrCloseModal,
    isModalVisible: cbrModalVisible,
  } = useModal();
  const [successScrollState, setSuccessScrollState] = useState({
    x: '',
    y: '',
  });
  const [showLiveModal, setShowLiveModal] = useState(false);
  const [playInlineVideo, setPlayInlineVideo] = useState(false);
  const [portraitClass, setPortraitClass] = useState('');
  const [videoStyle, setVideoStyle] = useState({});
  const [imgUrlStyle, setImgUrlStyle] = useState({});
  const [portraitVideoClass, setPortraitVideoClass] = useState('');
  const containerRef = useRef(null);
  const [playBGVideo, setPlayBGVideo] = useState(false);
  const isLiveStream = fields.ContentType && fields.ContentType.value === 'livestream';
  const is360Video = fields.ContentType && fields.ContentType.value === 'video360degree';
  const imageCrops =
    fields.ThumbnailImage && fields.ThumbnailImage.value && fields.ThumbnailImage.value.Crops;
  const imageAltText =
    fields.ThumbnailImage && fields.ThumbnailImage.value && fields.ThumbnailImage.value.AltText;
  const isWebpAvailable =
    fields.ThumbnailImage &&
    fields.ThumbnailImage.value &&
    fields.ThumbnailImage.value.isWebpAvailable;
  const tagList = fields.manutds && fields.manutds.value && fields.manutds.value.Tags;
  const itemId = getItemId(props);
  const sponsorInfo = fields.Sponsorby && getSponsorData(fields.Sponsorby.fields);
  const destinationUrl =
    fields.manutds && fields.manutds.value && fields.manutds.value.DestinationUrl;

  const getBackgroundVideoID = (data) => {
    if (data?.value) {
      if (typeof data.value === 'string') {
        const mediaBGId = JSON.parse(data.value)?.mediaId;
        return mediaBGId ? mediaBGId : null;
      } else {
        return data?.value?.mediaId || data?.value?.MediaId;
      }
    } else return null;
  };
  useEffect(() => {
    if (fields?.MUTVHeroVideoBackground && getBackgroundVideoID(fields?.MUTVHeroVideoBackground)) {
      autoPlayBGVideo();
    }
  }, [isHeroSlider]);

  const getVariant = () => {
    if (noVariant) {
      return '';
    } else if (fields.Variant && fields.Variant.value) {
      const a = fields.Variant.value.toLowerCase();
      if (a === 'child' && gridClass && gridClass.includes('mini-card')) {
        return '';
      }
      return a;
    }
    return '';
  };

  const getAnalyticsData = (fields, contentStatus) => {
    const initialData = track.data('card');
    return {
      ...initialData,
      card_name: fields.ShortHeadline && fields.ShortHeadline.value,
      container_type: container,
      item_id: itemId,
      content_type: !isBlogCard ? fields.ContentType && fields.ContentType.value : 'blogentry',
      content_status: contentStatus,
      subscription_status: getLoggedInStatus({
        isLoginChecked,
        isUserLogin,
        isUserSubscribe,
        isUserSkyId,
      }),
      tag: fields.manutds && fields.manutds.value && fields.manutds.value.Tags,
      created_datetime: fields.__Published && fields.__Published.value,
      author: fields.AuthorName && fields.AuthorName.value,
      destination_url: destinationUrl,
    };
  };

  const trackCard = (fields, e, contentStatus) => {
    track.analytics(getAnalyticsData(fields, contentStatus), e);
  };

  useEffect(() => {
    if (isInlineVideo && containerRef.current) {
      // set container height width for inline video
      const timeOut = isDestinationPage ? 500 : 0;
      setTimeout(() => {
        setInlineVideoContainer();
      }, timeOut);
    }
    // else if (isNonMatchDayCard || isMatchcenterCard) {
    //   const vD =
    //     fields && fields.VideoFile && fields.VideoFile.value && fields.VideoFile.value.Orientation;
    //   if (vD) {
    //     const splittedVal = vD.split('x');
    //     let vH = +splittedVal[1];
    //     let vW = +splittedVal[0];
    //     if (vH > vW) {
    //       setPortraitClass('portrait-card');
    //     } else if (vH === vW) {
    //       setPortraitClass('square-card');
    //     }
    //   }
    // }
  }, [containerRef]);

  const linkURL = () => {
    return !isDestinationPage && !isLiveStream && !is360Video && tagList && isMutvVideo(tagList)
      ? createDestinationUrl(destinationUrl)
      : '#';
  };

  const setInlineVideoContainer = () => {
    const wH = window && window.innerHeight;
    const wW = window && window.innerWidth;
    const vD =
      fields && fields.VideoFile && fields.VideoFile.value && fields.VideoFile.value.Orientation;
    const videoContainer = containerRef.current;
    let cW = videoContainer && videoContainer.clientWidth;
    let cH = videoContainer && videoContainer.clientHeight;
    if (vD && cW && cH) {
      const splittedVal = vD.split('x');
      let vH = +splittedVal[1];
      let vW = +splittedVal[0];
      const videoProperties = {};
      if (vH > vW || vH == vW) {
        if (!isDestinationPage && vH > vW && wH < 728) {
          // remove close button margin
          cH = wH - 35;
        }
        setPortraitClass('portrait');
        setPortraitVideoClass('portrait-video');
        var ratio = vH / vW;
        if (vW > cW && vH <= cH) {
          vW = (vH / vW) * vW;
          videoProperties['width'] = vW;
          videoProperties['height'] = vH;
        } else if (vW < cW && vH >= cH) {
          vW = (cH / vH) * vW;
          vH = cH;
          videoProperties['width'] = vW;
          videoProperties['height'] = vH;
        } else if (cW < vW && cH < vH) {
          vW = (cH / vH) * vW;
          vH = cH;
          if (ratio === 1 && wW <= 768) {
            vW = wW;
            vH = vW;
          }
          videoProperties['width'] = vW;
          videoProperties['height'] = vH;
        } else {
          videoProperties['width'] = vW;
          videoProperties['height'] = vH;
        }

        let videoStyleObj = {};

        if (videoProperties && videoProperties.width && videoProperties.height) {
          videoStyleObj = {
            maxWidth: `${videoProperties.width}px`,
            paddingTop: `${videoProperties.height}px`,
            height: 'auto',
            opacity: 1,
            minHeight: 'auto',
          };
        }

        if (window && (window.orientation == 90 || window.orientation == -90)) {
          videoStyleObj.maxWidth = '';
          videoStyleObj.paddingBottom = '';
        } else {
          /* start changes for squire videos under accordion component pages */
          if (
            (document.querySelector('.contact-us__content') ||
              document.querySelector('.hero__accordian--content')) &&
            vH == vW
          ) {
            delete videoStyleObj.height;
          }
        }
        setVideoStyle(videoStyleObj);
        if (videoProperties && videoProperties.width && videoProperties.height) {
          setCropImg(videoProperties.width, videoProperties.height);
        }
      } else {
        cW = containerRef && containerRef.current && containerRef.current.clientWidth;
        cH = containerRef && containerRef.current && containerRef.current.clientHeight;
        if (cW && cH) {
          setCropImg(cW, cH);
        }
      }
    }
  };

  const setCropImg = (width, height) => {
    if (
      fields &&
      fields.ThumbnailImage &&
      fields.ThumbnailImage.value &&
      fields.ThumbnailImage.value.Crops
    ) {
      const crop = selectImgCrop(
        width,
        height,
        fields.ThumbnailImage.value.Crops,
        false,
        isWebpAvailable
      );
      crop && setImgUrlStyle({ backgroundImage: `url(${crop})`, opacity: 1 });
    }
  };

  const onCardClick = (e) => {
    setSuccessScrollState({ x: e.pageX, y: e.pageY });
    const showCbr = getCbrValue();
    const destPage = linkURL();
    tracking && trackCard(fields, e, showCbr && showCbr.accessCheck);
    if (
      !isHeroSlider &&
      isApp() &&
      isInlineVideo &&
      isArticleDetail &&
      !isEmbedVideo &&
      !isCollection()
    ) {
      window.location.href = createDestinationUrl(destinationUrl) + MUAppDeepLink;
      return true;
    }
    if (!isHeroSlider && destPage && destPage !== '#') {
      if (isCollection() && isApp() && isArticleDetail) {
        const win = window.open(destPage, '_blank');
        win && win.focus();
      } else {
        window.location.href = destPage;
      }
    } else {
      if (showCbr && showCbr.value) {
        cbrShowModal(destinationUrl);
      } else {
        if (
          isHeroSlider &&
          isApp() &&
          isInlineVideo &&
          isArticleDetail &&
          !isEmbedVideo &&
          !isCollection()
        ) {
          window.location.href = createDestinationUrl(destinationUrl) + MUAppDeepLink;
          return true;
        }
        if (isHeroSlider && destPage && destPage !== '#') {
          if (isCollection() && isApp() && isArticleDetail) {
            const win = window.open(destPage, '_blank');
            win && win.focus();
          } else {
            window.location.href = destPage;
          }
        } else if (isInlineVideo) {
          // Inline player function here
          setPlayInlineVideo(true);
        } else {
          if (isLiveStream || is360Video) {
            setShowLiveModal(true);
          } else {
            showModal();
          }
        }
      }
    }
  };

  const onError = () => {
    if (imgUrlStyle.backgroundImage && imgUrlStyle.backgroundImage.includes('.webp')) {
      const crop = imgUrlStyle.backgroundImage.replace(/\.[^/.]+$/, '.jpg)');
      setImgUrlStyle({ backgroundImage: crop, opacity: 1 });
    }
  };
  const getCbrValue = () => {
    return validateCbr({
      isUserLogin,
      isUserSubscribe,
      contentAccess: ContentAccess && ContentAccess.value,
      contentType: ContentType && ContentType.value,
      manutds,
    });
  };
  const retriveSuccessCallback = typeof window !== 'undefined' && getParameterByName('state', true);

  useEffect(() => {
    if (retriveSuccessCallback && isLoginChecked) {
      const successCallData = retriveSuccessCallback.split(',');
      if (successCallData && successCallData[0] === 'playMainVideo') {
        const isSubscribedContent = successCallData[1] && successCallData[1] === 'true';
        const userSubscribed = isUserSubscribe;
        if (!isSubscribedContent || (isSubscribedContent && userSubscribed)) {
          if (isInlineVideo) {
            // Inline player function here
            setPlayInlineVideo(true);
          } else {
            if (isLiveStream || is360Video) {
              setShowLiveModal(true);
            } else {
              showModal();
            }
          }
          const url = window.location.href;
          const urlMain = url.split('?')[0] ?? url;
          window.history.replaceState({}, null, urlMain);
        }
      }
    }
  }, [retriveSuccessCallback, isLoginChecked]);

  const successCallback = ({ showContent, isSubscribedContent }) => {
    if (showContent) {
      if (!MU.isIDMEnabled) {
        if (isInlineVideo) {
          // Inline player function here
          setPlayInlineVideo(true);
        } else {
          if (isLiveStream || is360Video) {
            setShowLiveModal(true);
          } else {
            showModal();
          }
        }
      } else {
        if (isInlineVideo || isLiveStream || is360Video) {
          return ['playMainVideo', isSubscribedContent];
        } else {
          return [
            'successScroll',
            isSubscribedContent,
            JSON.stringify(successScrollState.x),
            JSON.stringify(successScrollState.y),
          ];
        }
      }
    }
  };
  const contentAccessStateFunc = () => {
    const cbrValue = getCbrValue();
    return cbrValue.accessCheck;
  };

  const renderCBSModal = () => {
    const accessType = getCbrValue().accessCheck;
    return (
      <ErrorBoundary>
        <RegistrationModal
          successCallback={successCallback}
          closeModal={cbrCloseModal}
          fallback={<Loader />}
          previousActiveElement={document.activeElement}
          accessType={accessType}
          analyticsObj={getAnalyticsData(fields, accessType)}
        />
      </ErrorBoundary>
    );
  };
  const autoPlayBGVideo = () => {
    setPlayBGVideo(true);
  };

  const getBGMediaID = (data) => {
    if (data?.MUTVHeroVideoBackground && getBackgroundVideoID(data?.MUTVHeroVideoBackground)) {
      return getBackgroundVideoID(data?.MUTVHeroVideoBackground);
    }
    return '';
  };
  const videoData = () => {
    return {
      VideoID: {
        value: getBackgroundVideoID(fields?.MUTVHeroVideoBackground),
      },
      ContentType: { value: ContentType?.value },
      __Published: { value: __Published?.value },
      manutds: {
        value: {
          Author: manutds?.value?.Author,
          Tags: createTagList(manutds?.value?.Tags?.taglist_sm, manutds?.value?.Tags?.Category),
        },
      },
      ShortHeadline: { value: fields?.ShortHeadline?.value },
      ContentAccess: { value: contentAccessStateFunc() },
    };
  };

  const getCtaText = () => {
    if (!isUserLogin && contentAccessStateFunc() === REGISTERED) {
      return (
        CTATitleRegisteredUser && (
          <div className="red-btn-skew">
            <span>{CTATitleRegisteredUser}</span>
          </div>
        )
      );
    } else if (!isUserSubscribe && contentAccessStateFunc() === ACCOUNT_SUBSCRIPTION) {
      return (
        CTATitleSubscribedUser && (
          <div className="red-btn-skew">
            <span>{CTATitleSubscribedUser}</span>
          </div>
        )
      );
    }
    return (
      CTATitle && (
        <div className="red-btn-skew">
          <span>{CTATitle}</span>
        </div>
      )
    );
  };

  return (
    <Fragment>
      {cbrModalVisible && renderCBSModal()}
      <div
        ref={containerRef}
        className={`item ${gridClass} ${isMatchcenterCard ? 'ratio-matchday' : 'ratio'} ${
          (isLiveStream || is360Video) && 'live-card'
        } ${portraitClass} ${heroVarient ? heroVarient : getVariant()}`}
        style={videoStyle}
        id={data && data.rawitemid_s}
        onClick={(e) => (!isHeroSlider ? onCardClick(e) : e.preventDefault())}
        onKeyPress={(e) => {
          e.which === 13 && onCardClick(e);
        }}
        data-impression={
          tracking
            ? track.impression({
                card_name: fields.ShortHeadline && fields.ShortHeadline.value,
                content_type: !isBlogCard
                  ? fields.ContentType && fields.ContentType.value
                  : 'blogentry',
              })
            : null
        }
        // tabIndex={isInlineVideo ? '0' : null}
      >
        <div className="mu-content">
          <div className={`mu-item video ${portraitVideoClass}`}>
            <a
              href={linkURL()}
              aria-hidden="true"
              tabIndex="-1"
              id="destLink"
              onClick={(e) => e.preventDefault()}
            >
              <div className="img-holder">
                {imageCrops && !playInlineVideo && (
                  <LazyLoad once={true} offset={100}>
                    <ImgCrop
                      figureStyle={imgUrlStyle}
                      imgCrop={imageCrops}
                      alt={imageAltText}
                      title={imageAltText}
                      isWebpAvailable={isWebpAvailable}
                      gridClass={gridClass}
                      onError={onError}
                      titleAvailable={hideGradient ? false : true}
                    ></ImgCrop>
                  </LazyLoad>
                )}
                {isHeroSlider &&
                  playBGVideo &&
                  currentIndex === currentActiveSlide &&
                  fields?.MUTVHeroVideoBackground &&
                  getBackgroundVideoID(fields?.MUTVHeroVideoBackground) && (
                    <>
                      <LazyLoad once={true} offset={100}>
                        <BackgroundVideo
                          data={videoData()}
                          // itemid={itemid_s}
                          isMutv={false}
                          isCloseIcon={false}
                          type="BGVideoAutoPlay"
                        />
                      </LazyLoad>
                    </>
                  )}
                {playInlineVideo && (
                  <InlineVideo
                    cardName={fields.Teaser && fields.Teaser.value}
                    author={fields.AuthorName && fields.AuthorName.value}
                    itemid={itemId}
                    data={fields}
                    playerStop={setPlayInlineVideo}
                    contentAccessState={contentAccessStateFunc()}
                    isMediaPlaying={isMediaPlaying && isMediaPlaying}
                    isCloseIcon={isCloseIcon}
                    isEmbedVideo={isEmbedVideo}
                  />
                )}
              </div>
            </a>
            {!isHeroSlider && (
              <div className="top-badges">
                <AccessBadge
                  contentAcessLocal={ContentAccess && ContentAccess.value}
                  contentTypeLocal={ContentType && ContentType.value}
                  tagListLocal={createTagArr(manutds)}
                />
              </div>
            )}
            {!isDestinationPage && !playInlineVideo && (
              <Sponsor
                contentType={fields.ContentType && fields.ContentType.value}
                varient={fields.Variant && fields.Variant.value}
                sponsorInfo={sponsorInfo}
                grid={gridClass}
                type={CONTENT_TYPE}
                isRelative={isRelative}
                author={fields.Author && fields.Author.value}
                cardName={fields.ShortHeadline && fields.ShortHeadline.value}
                container={container}
                createdDateTime={fields.__Published && fields.__Published.value}
                itemId={itemId}
                tags={fields.manutds && fields.manutds.value && fields.manutds.value.Tags}
              ></Sponsor>
            )}
            {!playInlineVideo &&
              (isHeroSlider ? (
                <div className="mu-item__info">
                  {fields.ShortHeadline && fields.ShortHeadline.value && (
                    <a
                      href="#"
                      onClick={(e) => e.preventDefault()}
                      tabIndex={!isInlineVideo ? '0' : '-1'}
                    >
                      <h2 className="mu-item__title">
                        <Text field={fields.ShortHeadline} />
                      </h2>
                      {!isInlineVideo && <span className="screenreader">&nbsp;{t('Video')}</span>}
                    </a>
                  )}
                  {fields.Teaser && fields.Teaser.value && (
                    <p className="mu-item__teasure">
                      <Text field={fields.Teaser} />
                    </p>
                  )}
                  <div className="btn" onClick={(e) => onCardClick(e)}>
                    <a
                      href="#"
                      onClick={(e) => e.preventDefault()}
                      tabIndex={!isInlineVideo ? '0' : '-1'}
                    >
                      {getCtaText()}
                    </a>
                  </div>
                </div>
              ) : (
                <div className="mu-item__info">
                  <div
                    className="mu-item__play video_play_dir"
                    role="button"
                    tabIndex={isInlineVideo ? (playIconTabIndex ? playIconTabIndex : '0') : null}
                  >
                    <span className="icon-play"></span>
                    {isInlineVideo && (
                      <span className="screenreader">
                        {`${
                          fields && fields.ShortHeadline && fields.ShortHeadline.value
                            ? `${fields.ShortHeadline.value} ${t('Video')}`
                            : t('Video')
                        }`}
                      </span>
                    )}
                  </div>
                  {fields.ShortHeadline && fields.ShortHeadline.value && (
                    <a
                      href="#"
                      onClick={(e) => e.preventDefault()}
                      tabIndex={!isInlineVideo ? '0' : '-1'}
                    >
                      <h2 className="mu-item__title">
                        <Text field={fields.ShortHeadline} />
                      </h2>
                      {!isInlineVideo && <span className="screenreader">&nbsp;{t('Video')}</span>}
                    </a>
                  )}
                  {fields.Teaser && fields.Teaser.value && (
                    <p className="mu-item__teasure">
                      <Text field={fields.Teaser} />
                    </p>
                  )}
                </div>
              ))}

            {!isInlineVideo && showStatsbar && (
              <StatsBar
                item={getStatsBarData(fields, isBlogCard)}
                type={isLiveStream ? 'livestream' : isHeroSlider ? 'heroSlider' : ''}
                tagListLocal={createTagArr(manutds)}
              ></StatsBar>
            )}
          </div>
        </div>
        {isInlineVideo && props.caption && (
          <div className="content-bar">
            <span className="icon-video"></span>
            <span>{props.caption}</span>
          </div>
        )}
        {(isLiveStream || is360Video) && showLiveModal && (
          <ErrorBoundary>
            <LiveStreamModal
              itemid={itemId}
              showModal={showLiveModal}
              videoData={fields}
              onModalClose={() => setShowLiveModal(false)}
              is360Video={is360Video}
              isLiveStream={isLiveStream}
              playerStop={setShowLiveModal}
              contentAccessState={contentAccessStateFunc()}
              isLiveStreamViaEPG={isLiveStreamViaEPG}
              LiveStreamViaEPGCallback={LiveStreamViaEPGCallback}
              setLiveStreamEnded={setLiveStreamEnded}
            />
          </ErrorBoundary>
        )}
      </div>
      {!(isLiveStream || is360Video) && isModalVisible && (
        <ErrorBoundary>
          <VideoModal
            cardName={fields.Teaser && fields.Teaser.value}
            author={fields.AuthorName && fields.AuthorName.value}
            closeModal={closeModal}
            itemid={itemId}
            data={fields}
            contentAccessState={contentAccessStateFunc()}
          />
        </ErrorBoundary>
      )}
    </Fragment>
  );
};
Video.defaultProps = {
  isInlineVideo: false,
  tracking: true,
  showStatsbar: true,
  noVariant: false,
  isRelative: false,
  hideGradient: false,
  isCloseIcon: false,
  isMediaPlaying: false,
  isLiveStreamViaEPG: false,
};
export default withTranslation()(Video);
