/**
 *  © 2022 -2025 DXC Technology, all rights reserved.
 *  Material published by DXC Technology on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useState } from 'react';
import { useSponsorValue } from '../../context/sponsorContext';
import { useLocation } from 'react-router-dom';
import {
  onSearchFormSubmit,
  getPageUrl,
  replaceAllValueInQuery,
  closeSearch,
  keyAsPerOS,
} from '../../utils/squizSearchUtils';
import { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import MU from '../../constants/muConstants';
import { Helmet } from 'react-helmet';
import { DARK_THEME, MODULE, RISTRICTED_KEYS } from '../../constants/globalConstants';
import Sponsor from '../Common/Sponsor';
import { SEARCH } from '../../constants/containerConstants';
import * as track from '../../utils/analytics';
import {
  newSearchUrl,
  fetchSuggestionsAPI,
  fetchStaticConciergeAPI,
} from '../../utils/squizSearchApi';
import { getParameterByName, isApp } from '../../utils/utils';
import StaticResponse from './StaticResponse';
import xss from 'xss';
const NewSearch = (props) => {
  const { t } = props;

  const [{ isLoginChecked, isUserLogin, isUserSubscribe, userLoginDetail }] = useSponsorValue();
  const [searchInput, setSearchInput] = useState('');
  const [typeInput, setTypeInput] = useState('');
  const [trackSearch, setTrackSearch] = useState('');
  const [staticStyle, setStaticStyle] = useState('block');
  const [dynamicStyle, setDynamicStyle] = useState('none');
  const [isError, setIsError] = useState(false);
  const [zeroResultError, setZeroResultErro] = useState({
    zeroresult: false,
    noResultSuggestionStatus: false,
  });
  const [searchResultFetchError, setSearchResultFetchError] = useState(false);
  const [headeranimation, setHeaderAnimation] = useState('block');
  const [inputSuggestionsData, setInputSuggestionsData] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState(-1);
  const Firstname =
    userLoginDetail &&
    userLoginDetail.profile &&
    userLoginDetail.profile.firstName &&
    userLoginDetail.profile.firstName;

  const redirectUrl = typeof window !== 'undefined' && window?.location?.href;
  const squizData = MU && MU.squizData;
  const isSquizEnable = squizData && squizData.squizEnable;
  const squizUrl = squizData && squizData.squizUrl;
  const errorHeadline = squizData && squizData.errorHeadline;
  const errorDescription = squizData && squizData.errorDescription;
  const retryButtonText = squizData && squizData.retryButtonText;
  const contactUsLabel = squizData && squizData.contactUsLabel;
  const contactSupportDestinationURL = squizData && squizData.contactSupportDestinationURL;
  const moodSadImage = squizData && squizData.gifImage;
  const location = useLocation();

  // DMPBL-945
  useEffect(() => {
    if (!location?.search) {
      const resultCountElement = document?.querySelector('.search-count-numeric');
      const resultCountText = resultCountElement && resultCountElement.textContent;
      const resultCount = resultCountText && parseInt(resultCountText.replace(/,/g, ''), 10);
      // if the search happened once, then only clear input field
      if (resultCount) {
        clearInput(true); // skip the route change
      }
    }
  }, [location]);

  useEffect(() => {
    // update search input and call newSearchUrl when URL changes
    let queryParam;
    let isURLhasMUTV;
    let checkUrlQuery = false;
    if (redirectUrl) {
      queryParam = getParameterByName('q', true);
      isURLhasMUTV = redirectUrl.indexOf('Search/mutv') !== -1;
      checkUrlQuery = true;
    }
    if (checkUrlQuery && isLoginChecked && queryParam) {
      let accessContent = getContentAccess();
      setStaticStyle('none');
      setSearchInput(queryParam);
      newSearchUrl(
        queryParam,
        errorHandler,
        searchResultDisplayHandler,
        accessContent,
        isURLhasMUTV
      );
    }
  }, [isLoginChecked]);

  const handleInputChange = (e) => {
    //on input type this event called
    setTrackSearch('searching');
    setSearchInput(e?.target?.value);
    setTypeInput(e?.target?.value);
    setIsError(false);
    setSelected(-1);
  };

  const clearInput = (skipHistory = false) => {
    //on clear input this event called and reset values
    let newurl = getPageUrl();
    if (!skipHistory && typeof window !== 'undefined' && window?.history?.pushState) {
      window.history.pushState({ path: newurl }, '', newurl);
    }
    animationDisplay();
    setDynamicStyle('none');
    setSearchInput('');
    setTrackSearch('');
    setIsError(false);
    setSelected(-1);
    setInputSuggestionsData('');
    setSearchResultFetchError(false);
  };

  const animationDisplay = () => {
    //show animation
    setHeaderAnimation('none');
    setStaticStyle('none');
    setTimeout(() => {
      setHeaderAnimation('block');
      setStaticStyle('block');
    }, 400);
  };
  const changeValOnArrow = (keycode, e) => {
    //on press up/down key this event called
    let keyVal;
    // down key
    if (keycode === 40) {
      if (selected < inputSuggestionsData.length - 1) keyVal = selected + 1;
      else if (selected === inputSuggestionsData.length - 1) keyVal = 0;
    } else {
      // up key
      if (selected > 0) keyVal = selected - 1;
      else if (selected === 0) keyVal = inputSuggestionsData.length - 1;
    }
    if (typeof keyVal === 'undefined') {
      setSelected(-1);
    } else {
      setSelected(keyVal);
    }
    if (keyVal > -1 && inputSuggestionsData && inputSuggestionsData[keyVal])
      setSearchInput(inputSuggestionsData[keyVal]?.disp);
  };

  const onInputKeyPress = (e) => {
    //on input key press this event called
    if (inputSuggestionsData.length > 0) {
      let keycode = e.which || e.keyCode;
      if (RISTRICTED_KEYS.indexOf(keycode) !== -1) {
        return false;
      }
      if (keycode === 38 || keycode === 40) {
        changeValOnArrow(keycode, e);
        return false;
      }
    }
  };

  const suggestionsEventByKey = (e, key) => {
    //on click suggestion list this event called
    setTrackSearch('searched');
    setInputSuggestionsData('');
    setSearchInput(key);
    updateUrl(key);
    setSelected(-1);
    let isURLhasMUTV;
    if (redirectUrl) {
      isURLhasMUTV = redirectUrl.indexOf('Search/mutv') !== -1;
    }
    //and called Squiz result page
    const accessContent = getContentAccess();
    newSearchUrl(key, errorHandler, searchResultDisplayHandler, accessContent, isURLhasMUTV);
  };

  const handleNoResultSuggestions = (e, tag) => {
    //on click suggestion list this event called
    const tagValue = tag.innerHTML.trim();
    console.log(tagValue);
    setSearchInput(tagValue);
    updateUrl(tagValue);
    let isURLhasMUTV;
    if (redirectUrl) {
      isURLhasMUTV = redirectUrl.indexOf('Search/mutv') !== -1;
    }
    //and called Squiz result page
    const accessContent = getContentAccess();
    newSearchUrl(tagValue, errorHandler, searchResultDisplayHandler, accessContent, isURLhasMUTV);
  };

  const onKeyDownHandler = (e) => {
    if (e.keyCode === 13 || e.key === 'Enter') {
      //on entered input with keywords this event called
      if (document && document.activeElement) {
        document.activeElement.blur();
      }
      onSubmitHandler(e);
    }
  };

  const onSubmitHandler = (e) => {
    setInputSuggestionsData('');
    setTrackSearch('searched');
    setSelected(-1);
    if (searchInput) updateUrl(searchInput);
    //and called Squiz result page
    const accessContent = getContentAccess();
    onSearchFormSubmit(e, errorHandler, searchResultDisplayHandler, accessContent);
  };

  const searchResultDisplayHandler = (staticresultdisplay, dynamicresultdisplay) => {
    //after a success of Squiz result page
    setInputSuggestionsData('');
    setStaticStyle(staticresultdisplay);
    setDynamicStyle(dynamicresultdisplay);
  };

  //event to handled the state if input element is not focused
  const handleBlur = () => {
    //add foucs to input element
    if (!searchInput) {
      setTrackSearch('');
    }
  };

  const errorHandler = (error, zeroresult, resultfetchstatus, noResultSuggestionStatus) => {
    //error handler
    setInputSuggestionsData('');
    setIsError(error);
    setZeroResultErro({ zeroresult, noResultSuggestionStatus });
    setSearchResultFetchError(resultfetchstatus);
  };

  const updateUrl = (key) => {
    //add query of searched input & contentAccess to current URL
    try {
      let globalSearchCont;
      if (typeof window !== 'undefined' && window?.location?.href?.indexOf('?q') > 0) {
        globalSearchCont = replaceAllValueInQuery(key, '#', '');
        if (key && key.indexOf('&') !== -1) {
          globalSearchCont = replaceAllValueInQuery(key, '&', '%26');
        }
        let newurl = getPageUrl() + '?q=' + globalSearchCont;
        if (typeof window !== 'undefined' && window?.history?.pushState) {
          window.history.pushState({ path: newurl }, '', newurl);
        }
      } else {
        globalSearchCont = key;
        if (key && key.indexOf('&') !== -1) {
          globalSearchCont = replaceAllValueInQuery(key, '&', '%26');
        }
        let newurl = getPageUrl() + '?q=' + globalSearchCont;
        typeof window !== 'undefined' && window.history.pushState({ path: newurl }, '', newurl);
      }
    } catch (e) {
      console.log('error', e);
    }
  };

  const getContentAccess = () => {
    //get contentAccess for query URL
    if (isUserSubscribe) {
      return 'subscribed';
    } else if (isUserLogin) {
      return 'registered';
    } else {
      return 'free';
    }
  };

  const inputSuggestEvent = (data) => {
    setSelected(-1);
    //stored suggestion API response lenght of 6
    if (data && data.length > 0) {
      setInputSuggestionsData(data?.splice(0, 6));
    }
  };

  useEffect(() => {
    if (typeInput?.length > 2) {
      //after 2nd input type call suggestion API
      fetchSuggestionsAPI(typeInput, inputSuggestEvent);
    } else {
      setInputSuggestionsData('');
    }
  }, [typeInput]);

  const staticConciergeDataEvent = (data) => {
    //store static concierge API data in sessionStorage
    typeof Storage !== 'undefined' &&
      sessionStorage.setItem('searchStaticResp', JSON.stringify(data));
    setIsLoading(true);
  };

  useEffect(() => {
    //call static concierge API
    fetchStaticConciergeAPI(staticConciergeDataEvent);
  }, []);

  useEffect(() => {
    //on press Escape button close search page
    const handleKeyDown = (event) => {
      if (event.keyCode === 27 || event.key === 'Escape') {
        event.preventDefault();
        closeSearch();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const scriptList = [
    `${squizUrl}/resources/${MU.squizData.collection}/${MU.squizData.profile}/js/global.js`,
    `${squizUrl}/resources/${MU.squizData.collection}/${MU.squizData.profile}/js/vendor.js`,
  ];

  useEffect(() => {
    //add Squiz JS files
    if (scriptList?.length > 0) {
      for (let i = 0; i < scriptList?.length; i++) {
        const v = document?.createElement('script');
        v.setAttribute('defer', 'defer');
        v.src = scriptList[i];
        document.body.appendChild(v);
      }
    }
  }, []);

  //dynamic padding for input box text based on clear button text
  useEffect(() => {
    const button = document?.querySelector('.clear_btn');
    const inputElement = document?.querySelector('.search_input');

    if (button && inputElement) {
      const buttonInnerWidth = button.clientWidth;
      inputElement.style.paddingRight = `${buttonInnerWidth + 10}px`;
    }
  }, [searchInput]);

  //analytics code
  useEffect(() => {
    const handleClick = (event) => {
      const { target } = event;
      const componentTabs = document?.getElementById('component-tabs');
      const playerProfile = document?.getElementById('component-player-profile');
      const relatedSearchesElement = target?.closest('#component-related-searches');
      const promotionBoxElement = target?.closest('.component-promo-box');
      const ecommerceCard = target?.closest('.swiper-slide');
      const resultCards = target?.closest('.search-result');

      //analytics code for tabs/categories
      if (componentTabs) {
        const allLinks = componentTabs?.querySelectorAll('[href]');
        if ([...allLinks]?.includes(event?.target)) {
          setTimeout(() => {
            const searchCountElement = document?.querySelector('.search-count-numeric');
            const searchInputText = document?.querySelector('.search_input');
            if (searchCountElement && searchInputText) {
              const searchCountText = searchCountElement?.textContent || '0';
              const resultCount = parseInt(searchCountText?.replace(/,/g, ''), 10) || 0;
              const searchQuery = searchInputText?.value || '';
              trackCard(searchQuery, resultCount);
            }
          }, 2000);
        }
      }

      //analytics code for playerProfile
      if (playerProfile && target?.closest('#component-player-profile')) {
        const playerNameElement = playerProfile?.querySelector('[data-impression]');
        const playerProfileElement = playerProfile?.querySelector('.view-profile');
        if (playerNameElement && playerProfileElement) {
          const playerNameText = playerNameElement?.getAttribute('data-impression');
          const parentElement = playerProfileElement?.parentElement;
          const href = parentElement ? parentElement?.getAttribute('href') : null;
          trackPlayerProfileCard(playerNameText, href, event);
        }
      }

      //analytics code for related keywords
      if (relatedSearchesElement) {
        let href = null;
        if (target?.tagName === 'STRONG' && target?.parentElement?.tagName === 'A') {
          href = target?.parentElement.getAttribute('href');
        } else if (target?.tagName === 'A' && target?.parentElement?.tagName === 'LI') {
          href = target?.getAttribute('href');
        }
        const parentTextContent = target?.parentElement
          ? target?.parentElement?.textContent?.trim()
          : '';
        const clickedUrl = squizUrl && href ? `${squizUrl}/search.html${href}` : null;
        trackLink(parentTextContent, clickedUrl, event);
        setTrackSearch('searched');
        setSearchInput(parentTextContent);
        updateUrl(parentTextContent);
      }

      //analytics code for promotional cards
      if (promotionBoxElement && target?.closest('.component-promo-box')) {
        const promotionTitleElement = promotionBoxElement?.querySelector('.curator-title');
        const anchorElement = promotionBoxElement?.querySelector('a');
        if (promotionTitleElement && anchorElement) {
          const cardTitle = promotionTitleElement?.textContent?.trim();
          const href = anchorElement?.getAttribute('href') || '';
          const clickedUrl = squizUrl && href ? `${squizUrl}/search.html${href}` : null;
          trackSearchCard(cardTitle, clickedUrl, 'promotion-card | search', event);
        }
      }

      //analytics code for e-commerce cards
      if (ecommerceCard && target?.closest('.swiper-slide')) {
        const ecommerceTitle = ecommerceCard?.querySelector('.ecommerce-title');
        const anchorElement = ecommerceCard?.querySelector('a');
        if (ecommerceTitle && anchorElement) {
          const cardTitle = ecommerceTitle?.textContent.trim();
          const href = anchorElement?.getAttribute('href') || '';
          const clickedUrl = squizUrl && href ? `${squizUrl}/search.html${href}` : null;
          trackSearchCard(cardTitle, clickedUrl, 'ecommerce-card | search', event);
        }
      }

      //analytics code for result cards
      if (resultCards && target?.closest('.search-result')) {
        if (typeof window !== 'undefined' && window?.location?.href) {
          sessionStorage.setItem('currentSearchPageURL', window.location.href);
        }
        const resultCardTitle = resultCards?.querySelector('h2');
        const anchorElement = resultCards?.querySelector('a');
        if (resultCardTitle && anchorElement) {
          const cardTitle = resultCardTitle?.textContent?.trim();
          const href = anchorElement?.getAttribute('href') || '';
          trackSearchCard(cardTitle, href, 'result card | search', event);
        }
      }
    };

    const searchResultsContainer = document?.querySelector('.search_result');
    if (searchResultsContainer) {
      searchResultsContainer.addEventListener('click', handleClick);
    }

    return () => {
      if (searchResultsContainer) {
        searchResultsContainer.removeEventListener('click', handleClick);
      }
    };
  }, []);

  const trackCard = (search, resultscount, e) => {
    const initialData = track.data('search');
    track.analytics(
      {
        ...initialData,
        search_term: search,
        search_result: resultscount,
      },
      e
    );
  };

  const trackPlayerProfileCard = (playername, href, e) => {
    const initialData = track.data('card');
    track.analytics(
      {
        ...initialData,
        card_name: playername,
        container_type: 'search',
        destination_url: href,
        content_type: 'search | player Profile card',
      },
      e
    );
  };

  const trackLink = (text, url, e) => {
    const initialData = track.data('link');
    track.analytics(
      {
        ...initialData,
        button_name: text,
        destination_url: url,
      },
      e
    );
  };

  const handleStaticCardRidirect = (ctaTitle, linkUrl, category, e) => {
    trackSearchCard(ctaTitle, linkUrl, category, e);
    if (typeof window !== 'undefined' && window?.location?.href) {
      sessionStorage.setItem('currentSearchPageURL', window.location.href);
    }
  };

  const trackSearchCard = (ctaTitle, linkUrl, category, e) => {
    const initialData = track.data('card');
    track.analytics(
      {
        ...initialData,
        card_name: ctaTitle,
        container_type: category,
        destination_url: linkUrl,
      },
      e
    );
  };

  const setCursorPointer = () => {
    setSelected(-1);
  };

  return (
    <>
      {isSquizEnable ? (
        <div className="squizSearch">
          {!isApp() && (
            //not for App; show header input element
            <div className="header_squiz">
              <button id="btn_search_close" className="btn_search_close" onClick={closeSearch}>
                <i className="cross-icon"></i>
              </button>
              <form
                className="search_form"
                style={{ display: headeranimation }}
                onKeyDown={onKeyDownHandler}
              >
                {isUserLogin && Firstname ? (
                  !trackSearch ? (
                    <span className="user_details">
                      {t('Hi')} {Firstname}
                    </span>
                  ) : searchInput && trackSearch === 'searching' ? (
                    <span className="user_details">
                      {Firstname} {t('issearchingfor')}...
                    </span>
                  ) : searchInput && trackSearch === 'searched' ? (
                    <span className="user_details">
                      {Firstname} {t('searchedfor')}...
                    </span>
                  ) : (
                    <span className="user_details">
                      {t('Hi')} {Firstname}
                    </span>
                  )
                ) : (
                  <span className="user_details">{t('HiThere')}</span>
                )}
                <input
                  className="search_input"
                  type="text"
                  enterkeyhint="search"
                  id="search_input"
                  autoComplete="off"
                  aria-label="search_input"
                  autoCorrect="off"
                  autoCapitalize="off"
                  spellCheck="false"
                  onKeyUp={(e) => onInputKeyPress(e)}
                  onChange={(e) => handleInputChange(e)}
                  onBlur={handleBlur}
                  value={searchInput}
                  placeholder={t('SearchplayersticketsFAQs')}
                  onClick={setCursorPointer}
                  style={{ caretColor: selected !== -1 ? 'transparent' : '#fff' }}
                />
                <input type="hidden" name="collection" value="manu~sp-manchester-united-search" />
                <input type="hidden" name="profile" value="search_preview" />

                {searchInput && (
                  //if input key then only show clear button
                  <button className="clear_btn" onClick={clearInput}>
                    {t('Clear')}
                  </button>
                )}
                {!searchInput && <span className="search_info">{keyAsPerOS(t)}</span>}
                {searchInput?.length > 2 && inputSuggestionsData?.length > 0 && (
                  //show suggestions list after 2nd input key
                  <div className="suggestBox">
                    <p>{t('Suggestions')}</p>
                    {inputSuggestionsData?.map((value, i) => {
                      return (
                        <span className="suggestionWrap" key={i}>
                          <a
                            className={`${selected === i ? 'selected' : ''}`}
                            onClick={(e) => suggestionsEventByKey(e, value.disp)}
                            href="#"
                          >
                            <span className="suggestionValue">{value.disp}</span>
                            <i className="next-arrow-icon"></i>
                          </a>
                        </span>
                      );
                    })}
                  </div>
                )}
              </form>
            </div>
          )}
          <div className="main_squiz">
            {searchResultFetchError ? (
              <div className="search-result-wrapper">
                <div className="icon-wrap">
                  {moodSadImage && (
                    <img src={`${MU && MU.assetUrl}/${xss(moodSadImage)}`} alt="moodSadImage" />
                  )}
                </div>
                {errorHeadline && <h3>{errorHeadline}</h3>}
                {errorDescription && <p>{errorDescription}</p>}
                <div className="error-btn-wrap">
                  {retryButtonText && (
                    <a href="#">
                      <button className="red-btn-skew err-btn" onClick={(e) => onSubmitHandler(e)}>
                        {retryButtonText}
                      </button>
                    </a>
                  )}
                  {contactUsLabel && (
                    <a href={contactSupportDestinationURL}>
                      <button className="white-btn-skew err-btn">{contactUsLabel}</button>
                    </a>
                  )}
                </div>
              </div>
            ) : (
              isLoading && (
                <div style={{ display: staticStyle }} className="static_result">
                  {/* show static result on load*/}
                  <div className="sponsor">
                    <div className="sponsor_box">
                      <Sponsor
                        type={MODULE}
                        moduleName={'searchpage'}
                        theme={DARK_THEME}
                        container={SEARCH}
                        cardName={'search'}
                        contentType={'search'}
                      />
                    </div>
                  </div>
                  {isError && (
                    //error screens
                    <div className="squiz_error_wrapper">
                      <div className="squiz_error">
                        <span className="icon-alert">
                          <i className="icon"></i>
                        </span>
                        {zeroResultError.zeroresult &&
                          (zeroResultError.noResultSuggestionStatus ? (
                            <p className="error_text">
                              {t('SquizNoResultsAreAvailableFor')}
                              <span className="serach_input_text">
                                &quot;{searchInput}&quot;.&nbsp;
                              </span>
                              {t('SquizYouCouldTry')}{' '}
                              <a
                                className="noResultSuggestionTxt"
                                onClick={(e) => handleNoResultSuggestions(e, e.target)}
                                href="#"
                              ></a>
                              , {t('SquizStartANewSearchOrUseTheOptionsBelow')}.
                            </p>
                          ) : (
                            <p className="error_text">
                              {t('Ooopsnoresultsfoundfor')}
                              <span className="serach_input_text">
                                {' '}
                                &quot;{searchInput}&quot;.&nbsp;
                              </span>
                              {t('Pleasetryagainorexploretheoptionsbelow')}
                            </p>
                          ))}
                      </div>
                    </div>
                  )}
                  <div className="box_content">
                    {/* show static concierge API response */}
                    {isLoading && (
                      <StaticResponse handleStaticCardRidirect={handleStaticCardRidirect} />
                    )}
                  </div>
                </div>
              )
            )}
            {/* shows Squiz result screen */}
            <div style={{ display: dynamicStyle }} className="search_result"></div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      <Helmet>
        <link
          rel="stylesheet"
          href={`${squizUrl}/resources/${MU.squizData.collection}/${MU.squizData.profile}/css/global.css`}
        />
        <link
          href={`${squizUrl}/resources/${MU.squizData.collection}/${MU.squizData.profile}/css/vendor.css`}
          rel="stylesheet"
        />
        <link
          href={`${squizUrl}/resources/${MU.squizData.collection}/${MU.squizData.profile}/css/tailwind.css`}
          rel="stylesheet"
        />
      </Helmet>
    </>
  );
};

export default withTranslation()(NewSearch);
