/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

/**
 * Sponsor constants
 */
export const FILTER = 'filter';
export const CONTENT_TYPE = 'contenttype';
export const BLOG_EVENT = 'blogevent';

export const MODULE = 'module';
export const DARK_THEME = 'dark';
export const LIGHT_THEME = 'light';

/**
 * Carousel constants
 */
export const HomePageHeroCarouselVarientLight = 'Light';
export const HomePageHeroCarouselVarientDark = 'Dark';

/**
 * webp constants
 */
export const WEBP_LOSSY = 'lossy';
export const WEBP_LOSSLESS = 'lossless';
export const WEBP_ALPHA = 'alpha';
export const WEBP_ANIMATION = 'animation';

/**
 * MegaNav constants
 */

export const SignedIn = 'signedin';
export const SignedOut = 'signedout';
export const Both = 'both';
export const SpotlightConstantsLive = 'live';
export const SpotlightConstantsResult = 'result';
export const SpotlightConstantsFixture = 'fixture';
export const AwayTeamCrestImage = 'AwayTeamCrestImage';
export const HomeTeamCrestImage = 'HomeTeamCrestImage';
export const CompetitionDarkBgLogo = 'CompetitionDarkBgLogo';
/**used for screenreader */
export const Versus = 'Versus';
export const SecondLeg = '2nd leg';
export const Aggregate = 'aggregate';
export const GoalBy = 'goal by';
export const HomeTeam = 'HomeTeam';
export const AwayTeam = 'AwayTeam';
export const Competition = 'Competition';
export const MatchDayUrl = '/en/matches/matchcenter?matchId=';
export const Match = 'Match';
export const SignInKey = 'SignIn';
export const RegisterKey = 'Register';
export const ActiveClass = 'active selected-nav';
export const SelectedNavClass = 'selected-nav';
export const MatchData = 'matchdata';
export const Mutv = 'mutv';

export const SUPPORTED_NEWS_GRID_CURATED_CARD = [
  'article',
  'gallery',
  'printproduct',
  'video',
  'quote',
  'eventmaster',
  'adcard',
  'livestream',
  'video360degree',
  'collection',
  'quiz',
  'image',
  'podcast',
  'poll',
  'unitedcompetition',
];
export const SUPPORTED_NEWS_GRID_SOLAR_CARD = [
  'article',
  'externalarticle',
  'printproduct',
  'video',
  'quote',
  'infographic',
  'image',
  'gallery',
  'collection',
  'quiz',
  'podcast',
  'poll',
  'unitedcompetition',
];
export const SUPPORTED_LISTING_CARD = [
  'article',
  'video',
  'infographic',
  'collection',
  'gallery',
  'quote',
  'playerprofile',
  'image',
  'social',
  'poll',
  'quiz',
  'externalarticle',
  'printproduct',
  'adcard',
  'podcast',
  'unitedcompetition',
];
export const THRESHOLD = 500;

export const gridArray = [
  'grid-6',
  'grid-6',
  'grid-3',
  'grid-3',
  'grid-3',
  'grid-3',
  'grid-3',
  'grid-3',
  'grid-3',
  'grid-3',
];

export const SUPPORTED_NEWS_LISTING_CARD = [
  'article',
  'externalarticle',
  'trivia',
  'audio',
  'video',
  'collection',
  'gallery',
  'chat',
  'fixtures',
  'lineup',
  'quote',
  'playerprofile',
  'image',
  'social',
  'poll',
  'quiz',
  'podcast',
  'unitedcompetition',
];

export const SUPPORTED_STATS_CONTENT_TYPE = ['matchdaystats', 'competitionstats', 'seasonstats'];
export const SUPPORTED_STATS_TEMPLATE_TYPE = [
  'percentage breakdown',
  'top 3',
  'head to head 4',
  'head to head number',
  'head to head percentage',
  'number breakdown',
  'single stat (3)',
  'player v team',
];

export const SUPPORTED_PLAYER_PROFILE_CAROUSEL_CARDTYPE = [
  'article',
  'image',
  'video',
  'unitedcompetition',
];

/**Video Analytics Constant */
export const VIDEO_PLAY = 'w_video_play';
export const VIDEO_PAUSE = 'w_video_pause';
export const VIDEO_STOP = 'w_video_stop';
export const VIDEO_PLAY_25 = 'w_video_play_25%';
export const VIDEO_PLAY_50 = 'w_video_play_50%';
export const VIDEO_PLAY_75 = 'w_video_play_75%';
export const VIDEO_COMPLETE = 'w_video_complete';
export const VIDEO_FULLSCREEN = 'w_button_click';
export const VIDEO_AD_START = 'w_video_ad_start';
export const VIDEO_AD_COMPLETE = 'w_video_ad_complete';
export const VIDEO_AD_CLICK = 'w_video_ad_click';

/**Button analytics constant */
export const SOCIAL_SHARE_MORE_BUTTON = 'Expand social';

/**Common Constants */
export const MUAppConstant = 'muapp';
export const MUAppDeepLink = '?request=muweb';

/** Auto Suggest Search Results */
export const MIN_CHARACTER = 2;
export const RISTRICTED_KEYS = [16, 17, 18, 37, 39, 91, 93];
export const SEARCH_THRESHOLD = 1000;

/**Myunited Analytics Constant */
export const countryNameArr = [
  'united kingdom',
  'cayman islands',
  'cocos (keeling) islands',
  'cook islands',
  'czech republic',
  'dominican republic',
  'falkland islands',
  'faroe islands',
  'marshall islands',
  'federated states of micronesia',
  'netherlands',
  'northern mariana islands',
  'philippines',
  'seychelles',
  'solomon islands',
  'turks and caicos islands',
  'united arab emirates',
  'united states',
  'british virgin islands',
  'united states virgin islands',
  'republic of congo',
  'democratic republic of congo',
  'republic of moldova',
  'united republic of tanzania',
];

export const ScreenNames = {
  OLD_TRAFFORD: 'Old Trafford & Events',
  TICKETS_AND_HOSPITALITY: 'Tickets & Hospitality',
  TERMS_CONDITIONS: 'Terms & Conditions',
  PRIVACY_POLICY: 'Privacy Policy',
  COOKIE_POLICY: 'Cookie Policy',
};

export const APP_ONLY = 'app';
export const STRUCTURE_DATA_SUPPORTED_CONTENT_TYPE = [
  'playerprofile',
  'managerprofile',
  'coachprofile',
  'article',
  'video',
  'image',
  'matchcarousel',
];

export const DefaultImage = 'DefaultImage';

export const nonEssCookies = [
  '__gads',
  '_cs_c',
  '_cs_id',
  '_cs_s',
  '_fbp',
  '_sdsat_landing_page',
  '_sdsat_lt_pages_viewed',
  '_sdsat_MUFC Stage MID',
  '_sdsat_pages_viewed',
  '_sdsat_session_count',
  '_sdsat_traffic_source',
  'AMCV_247646CB57C0095E7F000101%40AdobeOrg',
  'AMCVS_247646CB57C0095E7F000101%40AdobeOrg',
  's_cc',
  'QSI_HistorySession',
  's_gnr',
  'QSI_SI_eE9k0IktdWX45z7_intercept',
  'QSI_ActionSetHistory',
  'Q_INTER',
  'trctestcookie',
  'taboola global:user-id',
  '__cfduid',
  '_uetsid',
  '_uetvid',
  '_uetvid_exp',
  '_uetsid_exp',
];
export const nonEssLocalStorage = ['_uetsid', '_uetvid', '_uetvid_exp', '_uetsid_exp'];

/**
 *  Mutv Constants
 */
export const FREE_TO_VIEW = 'Free to View';
export const REGISTERED = 'Registered';
export const ACCOUNT_SUBSCRIPTION = 'Account Subscription';
export const ANONYMOUS = 'Anonymous';
export const SUBSCRIBER = 'Subscriber';
// Api security Keys constants
export const DEFAULT_AUTH_TOKEN = 'xyz12abc34';
export const SEPRATOR = 'xa#po#cop3f';

//Hybrid Constants
export const WV_REGISTERED = 'wjhd';
export const WV_SUBSCRIPTION = 'orbq';

//CBR content type contant array
export const CBR_CONTENTTYPES = [
  'article',
  'video',
  'podcast',
  'collection',
  'quiz',
  'gallery',
  'livestream',
];

//Device management status flags
export const PLAY = 'play';
export const PLAYING = 'playing';
export const STOP = 'stop';

//Keyboard events
export const Keyboard = {
  UP: 38,
  DOWN: 40,
  LEFT: 37,
  ENTER: 13,
  RIGHT: 39,
  ESCAPE: 27,
  TAB: 9,
  SPACEBAR: 32,
};

// UserinfoAnalytics
export const NONE = 'None';
