/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useState, useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { BrowsingModeDetector } from '../../utils/DetectPrivateBrowser';
import * as track from '../../utils/analytics';
import { NOTIFICATION_PROMPT } from '../../constants/containerConstants';
import { callBraze, isCookiesSelected } from '../../utils/brazeUtil';
const PushNotification = ({ item, currentPage, t }) => {
  const browserSet =
    item && item.customWebNotification && item.customWebNotification.allowedBrowsers;
  const browserSupport = browserSet ? true : false;
  const reappearTime = item && item.customWebNotification && item.customWebNotification.frequency;
  const [userAgent, setUserAgent] = useState(null);
  const [showDialogue, setShowDialogue] = useState(false);
  const [subscribe, setSubscribe] = useState(true);
  const [setting, setSetting] = useState(false);
  const dialogRef = useRef(null);
  useEffect(() => {
    setUserAgent(window.navigator.userAgent);
  }, []);

  useEffect(() => {
    if (userAgent && item && item.customWebNotification) {
      if (customSupported()) {
        init();
      } else {
        defaultInit();
      }
    }
  }, [userAgent]);
  const getOperaAgent = () => {
    return userAgent && userAgent.indexOf('OP') > -1;
  };

  const customSupported = () => {
    return (
      /^((?!chrome|android).)*safari/i.test(userAgent) &&
      !userAgent.match(/iPad/i) &&
      !userAgent.match(/iPhone/i)
    );
  };

  /**
   * {init to check notification permission}
   */
  const init = () => {
    // denied and allowed
    if (!window.Notification) {
      return false;
    } else {
      // checkPermission for safari
      checkPermission();
    }
  };
  /**
   *
   * @param {* detect private window} incognitoCallback
   */
  const detectPrivateWindow = (incognitoCallback) => {
    /*  start private browsing code */
    // You can test the browsing mode using a default callback
    var myCallback = function (browsingInIncognitoMode, BrowsingModeDetectorInstance) {
      console.log('Is private?', browsingInIncognitoMode);
      console.log('Browsing Mode:', BrowsingModeDetectorInstance.getBrowsingMode());
      if (browsingInIncognitoMode) {
        console.log('Incognito/Private browsing detected!');
        incognitoCallback(true);
        //return true;
      } else {
        incognitoCallback(false);
        console.log('Normal browsing');
      }
    };
    // Bots will be ignored:
    var BrowsingModeDetectorTest1 = new BrowsingModeDetector();
    BrowsingModeDetectorTest1.do(myCallback);
    // or BrowsingModeDetectorTest1.ignoringBots().do(myCallback);

    // Will return as private/incognito navigation when bots browsing:
    // BrowsingModeDetectorTest1.notIgnoringBots().do(myCallback);

    var callbackWhenNormalMode = function (BrowsingModeDetectorInstance) {
      console.log(
        'callbackWhenNormalMode called when',
        BrowsingModeDetectorInstance.getBrowsingMode()
      );
    };
    var callbackWhenIncognitoOrPrivateMode = function (BrowsingModeDetectorInstance) {
      console.log(
        'callbackWhenIncognitoOrPrivateMode called when',
        BrowsingModeDetectorInstance.getBrowsingMode()
      );
    };
    var defaultCallback = function (browsingInIncognitoMode, BrowsingModeDetectorInstance) {
      console.log(
        'This callback will be called either private or normal mode detected, optional though. Is private or incognito?',
        browsingInIncognitoMode
      );
      if (
        BrowsingModeDetectorInstance.getBrowsingMode() ===
        BrowsingModeDetectorInstance.BROWSING_NORMAL_MODE
      ) {
        console.log('Do something if is NORMAL_MODE!');
      } else {
        console.log('Do something if is INCOGNITO_PRIVATE_MODE!');
      }
    };
    var BrowsingModeDetectorTest2 = new BrowsingModeDetector();
    BrowsingModeDetectorTest2.setCallbackForNormalMode(callbackWhenNormalMode)
      .setCallbackForIncognitoOrPrivateMode(callbackWhenIncognitoOrPrivateMode)
      .do(defaultCallback);
    // You can test if is bot passing a callback:
    BrowsingModeDetectorTest2.isBotBrowsing(function (isBot) {
      console.log('Is Bot:', isBot);
    });
    /*end Private Brousing code */
  };

  /**
   * {detect browser on diffrent OS}
   */
  const browserDetect = () => {
    try {
      var module = {
        options: [],
        header: [
          window.navigator.platform,
          window.navigator.userAgent,
          window.navigator.appVersion,
          window.navigator.vendor,
          window.opera,
        ],
        dataos: [
          { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
          { name: 'Windows', value: 'Win', version: 'NT' },
          { name: 'iPhone', value: 'iPhone', version: 'OS' },
          { name: 'iPad', value: 'iPad', version: 'OS' },
          { name: 'Kindle', value: 'Silk', version: 'Silk' },
          { name: 'Android', value: 'Android', version: 'Android' },
          { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
          { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
          { name: 'Macintosh', value: 'Mac', version: 'OS X' },
          { name: 'Linux', value: 'Linux', version: 'rv' },
          { name: 'Palm', value: 'Palm', version: 'PalmOS' },
        ],
        databrowser: [
          { name: 'Edge', value: 'Edg', version: 'Edg' },
          { name: 'FxiOS', value: 'FxiOS', version: 'FxiOS' },
          { name: 'CriOS', value: 'CriOS', version: 'CriOS' },
          { name: 'OPiOS', value: 'OPiOS', version: 'OPiOS' },
          { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
          { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
          { name: 'Safari', value: 'Safari', version: 'Safari' },
          { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
          { name: 'Opera', value: 'Opera', version: 'Opera' },
          //{ name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
          { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' },
        ],
        init: function () {
          var agent = this.header.join(' '),
            os = this.matchItem(agent, this.dataos),
            browser = this.matchItem(agent, this.databrowser);
          return { os: os, browser: browser };
        },
        matchItem: function (string, data) {
          var i = 0,
            j = 0,
            html = '',
            regex,
            regexv,
            match,
            matches,
            version;

          for (var i = 0; i < data.length; i += 1) {
            regex = new RegExp(data[i].value, 'i');
            match = regex.test(string);
            if (match) {
              regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i');
              matches = string.match(regexv);
              version = '';
              if (matches) {
                if (matches[1]) {
                  matches = matches[1];
                }
              }
              if (matches) {
                matches = matches.split(/[._]+/);
                for (j = 0; j < matches.length; j += 1) {
                  if (j === 0) {
                    version += matches[j] + '.';
                  } else {
                    version += matches[j];
                  }
                }
              } else {
                version = '0';
              }
              return {
                name: data[i].name,
                version: parseFloat(version),
              };
            }
          }
          return { name: 'unknown', version: 0 };
        },
      };

      var browserObj = module.init();
      return browserObj;
    } catch (e) {
      console.log('browserDetect', e);
    }
  };

  /**
   * {default init used for browserset chrome/firefox}
   */
  const defaultInit = () => {
    try {
      if (browserSet) {
        var browserObj = browserDetect();
        if (browserObj) {
          if (browserObj.browser && browserObj.browser.name) {
            if (browserObj.browser.name === 'Firefox' || browserObj.browser.name === 'Mozilla') {
              if (browserSet && browserSet.includes('firefox')) {
                init();
              } else {
                isCookiesSelected() && callBraze('', item, currentPage);
              }
            } else if (browserObj.browser.name === 'Chrome' && getOperaAgent() === false) {
              if (browserSet && browserSet.includes('chrome')) {
                detectPrivateWindow(function (flag) {
                  if (!flag) {
                    init();
                  }
                });
              } else {
                isCookiesSelected() && callBraze('', item, currentPage);
              }
            } else {
              isCookiesSelected() && callBraze('', item, currentPage);
            }
          }
        } else {
          isCookiesSelected() && callBraze('', item, currentPage);
        }
      } else {
        isCookiesSelected() && callBraze('', item, currentPage);
      }
    } catch (e) {
      console.log('browser not detected', e);
    }
  };

  /**
   * {check timestamp reappearTime:30, configure at sitecore-end}
   */
  const checkTimeRules = () => {
    try {
      var reTime = reappearTime ? +reappearTime : 0;
      var storedTS = localStorage.getItem('cnd');
      var currentTime = new Date().getTime();
      if (storedTS) {
        storedTS = +storedTS;
        var storedTSObj = new Date(storedTS);
        var storedDateFromTS = storedTSObj.getDate();
        storedTSObj.setDate(storedDateFromTS + reTime);
        if (storedTSObj.getTime() < currentTime) {
          return true;
        }
        return false;
      } else {
        return true;
      }
    } catch (e) {
      console.error('Error getting timestamp in local storage', e);
    }
    return false;
  };
  /**
   * check permission to subscribe
   */
  const checkPermission = () => {
    const permission = Notification.permission;
    if (permission === 'default' && checkTimeRules()) {
      if (customSupported() || browserSupport) {
        // show custom notification
        setShowDialogue(true);
      } else {
        isCookiesSelected() && callBraze('', item, currentPage);
        setShowDialogue(false);
      }
    } else if (permission === 'denied') {
      if ((customSupported() && checkTimeRules()) || (browserSupport && checkTimeRules())) {
        setShowDialogue(true);
      }
    } else if (permission === 'allowed' || 'granted') {
      if (checkTimeRules() && (customSupported() || browserSupport)) {
        setShowDialogue(true);
      } else {
        isCookiesSelected() && callBraze('', item, currentPage);
        setShowDialogue(false);
      }
    }
  };
  const setStorageTimeStamp = () => {
    try {
      localStorage.setItem('cnd', new Date().getTime());
    } catch (e) {
      console.error('Error setting timestamp in local storage', e);
    }
  };
  const subscribeNotification = (e) => {
    e && e.preventDefault();
    let permission = Notification.permission;
    setShowDialogue(false);
    setStorageTimeStamp();
    console.log('Notification permission', permission);
    if (permission === 'denied' || permission === 'default') {
      // Optionally, request permission
      Notification.requestPermission().then(function (permission) {
        permission = Notification.permission;
        console.log('Notification permission after permitted', permission);
        if (permission === 'allowed' || permission === 'granted') {
          //setShowDialogue(false);
          isCookiesSelected() && callBraze('', item, currentPage);
          //setStorageTimeStamp();
          console.log('Permission granted for notifications!');
        } else if (permission === 'denied') {
          setSubscribe(false);
          setSetting(true);
          console.log('Notification permission denied.');
        }
      });
    } else {
      console.log('Notification permission in else', permission);
      //setShowDialogue(false);
      isCookiesSelected() && callBraze('', item, currentPage);
      //setStorageTimeStamp();
    }
    trackCard({ cardName: 'Notification_Opt_in' });
  };
  const subscribeLater = (e) => {
    e && e.preventDefault();
    setShowDialogue(false);
    setStorageTimeStamp();
    trackCard({ cardName: 'Notification_Later' });
  };

  const closeNotofication = (e) => {
    e && e.preventDefault();
    setShowDialogue(false);
    setStorageTimeStamp();
  };
  const trackCard = (data) => {
    const initialData = track.data('card');
    track.analytics({
      ...initialData,
      card_name: data.cardName,
      container_type: NOTIFICATION_PROMPT,
      destination_url: data.destination_url,
    });
  };
  const handleKeyDown = (e, type) => {
    const value = e ? e.keyCode || e.which : null;
    if (type === 'SubscribeNow' && value && value === 9 && !e.shiftKey) {
      e.preventDefault();
      setTimeout(() => {
        const a = dialogRef.current && dialogRef.current.querySelector('.popup__btn.negative-btn');
        a && a.focus();
      }, 0);
    } else if (type === 'Later' && value && value === 9 && e.shiftKey) {
      e.preventDefault();
      setTimeout(() => {
        const a = dialogRef.current && dialogRef.current.querySelector('.popup__btn.positive-btn');
        a && a.focus();
      }, 0);
    }
  };
  return (
    item &&
    item.customWebNotification &&
    showDialogue && (
      <React.StrictMode>
        <div
          className="custom-popup"
          role="dialog"
          aria-describedby="popup-title"
          data-impression={track.impression({
            card_name: 'Notification Prompt',
            content_type: 'Notification_Prompt',
          })}
        >
          <div className="popup-logo icon-mu-logo-cms-local"></div>
          {subscribe && (
            <div className="popup-body popup-subscribtion" ref={dialogRef}>
              <div className="popup__info">
                <h2 id="popup-title-popup-subscribtion">{item.customWebNotification.headline}</h2>
                <p>{item.customWebNotification.teaser}</p>
              </div>
              <div className="popup__btns">
                <a
                  href="/#"
                  onClick={(e) => subscribeLater(e)}
                  id="later"
                  className="popup__btn negative-btn"
                  role="button"
                  onKeyDown={(e) => handleKeyDown(e, 'Later')}
                >
                  <span>{t('Later')}</span>
                </a>
                <a
                  href="/#"
                  onClick={(e) => subscribeNotification(e)}
                  id="subscribe"
                  className="popup__btn positive-btn"
                  role="button"
                  onKeyDown={(e) => handleKeyDown(e, 'SubscribeNow')}
                >
                  <span>{t('SubscribeNow')}</span>
                </a>
              </div>
            </div>
          )}
          {setting && (
            <div className="popup-body popup-settings">
              <div className="popup__info ">
                <h2 id="popup-title-popup-settings">
                  {item.customWebNotification.settingMessageHeadline}
                </h2>
                <p tabIndex="0">{item.customWebNotification.settingMessageTeaser}</p>
              </div>
              <div className="popup__btns">
                <a
                  href={item.customWebNotification.ctaUrl}
                  onClick={
                    (() => setShowDialogue(false),
                    trackCard({
                      cardName: 'Notification_Find Out More',
                      destination_url: item.customWebNotification.ctaUrl,
                    }))
                  }
                  target={item.customWebNotification.ctaUrlTarget}
                  className="popup__btn positive-btn popup-settings"
                  role="button"
                >
                  <span>{t('Findoutmore')}</span>
                </a>
                <a
                  href="/#"
                  onClick={(e) => closeNotofication(e)}
                  aria-label="close"
                  role="button"
                  className="popup__close"
                >
                  <span className="icon-close-black"></span>
                </a>
              </div>
            </div>
          )}
        </div>
      </React.StrictMode>
    )
  );
};

export default withTranslation()(PushNotification);
