/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import { getLanguage, getParameterByName, getWayinCookie } from './utils';
import api from './muApi';
import MU from '../constants/muConstants';
import i18 from 'i18next';
import { GIGYAENDPOINT, SUBSCRIBE_ENCRYPTION } from '../constants/endpoints';
import {
  loginStart,
  loginComplete,
  registrationComplete,
  registrationStart,
  registrationSubmit,
  regVerificationInit,
} from './analytics';
import { Keyboard } from '../constants/globalConstants';
import xss from 'xss';
import {
  triggerSignUp,
  createEditProfileUrl,
  changePreferencesUrl,
  saveUserIDCookie,
  redirectToIDMLogin,
} from './idmUtils';

let baseURL = '';

export const showLogin = (getLoginDetail, isMutv, isSubscribedContent, analyticsObj) => {
  if (typeof window !== 'undefined') {
    showLoadingImage();
    window.getLoginDetail = getLoginDetail;
    if (!isMutv) {
      removeBlackTheme();
    }
    showRegisterLogin('gigya-login-screen', isSubscribedContent, analyticsObj);
    if (document.querySelector('.main-container')) {
      document.querySelector('.main-container').style.display = 'block';
    }
  }
};

const getServiceUrl = (serviceName) => {
  baseURL = `${MU.awsEndPoint}/${MU.awsAPIVersion}`;
  let apiName = '';
  switch (serviceName) {
    case 'activate': {
      apiName = GIGYAENDPOINT.ACTIVATE;
      break;
    }
    case 'parentconsentdetails': {
      apiName = GIGYAENDPOINT.PARENTCONSENTDETAILS;
      break;
    }
    case 'setparentconsent': {
      apiName = GIGYAENDPOINT.SETPARENTCONSENT;
      break;
    }
    case 'setemailverification': {
      apiName = GIGYAENDPOINT.SETEMAILVERIFICATION;
      break;
    }
    case 'verifyOnForgotPwd': {
      apiName = GIGYAENDPOINT.VERIFYONFORGOTPWD;
      break;
    }
    case 'preferencecentre': {
      apiName = GIGYAENDPOINT.PREFERENCECENTRE;
      break;
    }
    default:
      apiName = '';
  }
  return apiName;
};
const afterScreenLoad = (eventObj) => {
  if (eventObj && eventObj.currentScreen === 'gigya-update-profile-screen') {
    hideLoadingImage();
    onLoadCloseAttr();
    ariaRequiredUpdate();
    tooltipTraverseHandler();
  }

  if (eventObj && eventObj.currentScreen === 'gigya-update-profile-screen-second') {
    const numberElm = document.querySelectorAll('.numberonly');
    numberElm &&
      numberElm.length > 0 &&
      numberElm.forEach((Elm, i) => {
        Elm.addEventListener('keypress', (e) => {
          return onlyNumber(e);
        });
      });
  }

  if (eventObj && eventObj.currentScreen === 'gigya-update-profile-screen-third') {
    let preferenceCenterbtn = document.querySelector(
      '#gigya-update-profile-screen-third-form label a'
    );
    preferenceCenterbtn &&
      preferenceCenterbtn.addEventListener('click', () => {
        submitPreferenceSettings();
      });

    let preferenceElm = document.getElementById('mu-preference-form-settings');
    let preferenceElmVal = preferenceElm && preferenceElm.querySelector('#preference-email');
    let consent = '';
    let prefEmailElm = document.getElementById('preference-email');
    let preferenceConsentEle = document.getElementById('preference-consent');

    if (prefEmailElm && prefEmailElm.value) {
      prefEmailElm.value = eventObj.context.email;
    }
    if (preferenceElmVal && preferenceElmVal.value) {
      preferenceElmVal.value = eventObj.context.email;
    }
    eventObj.data && eventObj.data.marketingConsent === 'Yes'
      ? (consent = true)
      : (consent = false);
    if (preferenceConsentEle) {
      preferenceConsentEle.value = consent;
    }
  }
};

export const afterSubmit = (eventObj, isSubscribedContent) => {
  hideLoadingImage();
  let loginFormEle = document.getElementById('gigya-login-form');
  if (loginFormEle && loginFormEle.length > 0) {
    window.afterLoginSubmitFlag = true;
  }

  if (
    eventObj &&
    (eventObj.screen === 'gigya-register-screen' ||
      (eventObj.screen === 'gigya-login-screen' &&
        eventObj.response &&
        eventObj.response.errorMessage == 'Account Pending Registration'))
  ) {
    /*Start - Analytics tracking code for registrationSubmit Sent*/
    try {
      registrationSubmit(eventObj);
    } catch (e) {
      console.error('Error in tracking registration start');
    }
  }

  let customLangParams = getCustomLang();
  if (
    (eventObj && eventObj.screen === 'gigya-complete-registration-screen') ||
    (eventObj.screen === 'gigya-register-screen' &&
      eventObj.response &&
      eventObj.response.socialProviders &&
      eventObj.response.socialProviders === 'site')
  ) {
    if (eventObj.profile && eventObj.profile.age < 13) {
      if (eventObj && eventObj.response && eventObj.response.loginProvider !== 'site') {
        const route = `${getServiceUrl('activate')}isSocialAllowed?UID=${eventObj.response.UID}`;
        const isHardcoredGeo = true;
        return api
          .get({ route, baseURL, isHardcoredGeo, data: { UID: eventObj.response.UID } })
          .then((response) => {
            typeof window !== 'undefined' &&
              window.gigya &&
              window.gigya.accounts.showScreenSet({
                screenSet: 'MufcWeb-RegistrationLogin',
                startScreen: 'gigya-parentalConsent-screen',
                onAfterScreenLoad: onAfterLoad,
                customLang: customLangParams,
                onHide: addScroll,
                onBeforeScreenLoad: removeScroll,
                onError: errorHandler,
                context: {
                  uid: eventObj.response.UID,
                  name: eventObj.response.profile.firstName,
                  email: eventObj.response.profile.email,
                  lang: eventObj.response.requestParams.lang,
                },
              });
          })
          .catch((err) => {});
      }

      if (eventObj.context && eventObj.context.containerID) {
        typeof window !== 'undefined' &&
          window.gigya &&
          window.gigya.accounts.showScreenSet({
            screenSet: 'MufcWeb-RegistrationLogin',
            startScreen: 'gigya-parentalConsent-screen',
            onAfterScreenLoad: onAfterLoad,
            customLang: customLangParams,
            onHide: addScroll,
            containerID: eventObj.context.containerID,
            onError: errorHandler,
            context: {
              uid: eventObj.response.UID,
              name: eventObj.response.profile.firstName,
              email: eventObj.response.profile.email,
              lang: eventObj.response.requestParams.lang,
              containerID: eventObj.context.containerID,
            },
          });
      } else {
        typeof window !== 'undefined' &&
          window.gigya &&
          window.gigya.accounts.showScreenSet({
            screenSet: 'MufcWeb-RegistrationLogin',
            startScreen: 'gigya-parentalConsent-screen',
            onAfterScreenLoad: onAfterLoad,
            customLang: customLangParams,
            onHide: addScroll,
            onBeforeScreenLoad: removeScroll,
            onError: errorHandler,
            context: {
              uid: eventObj.response.UID,
              name: eventObj.response.profile.firstName,
              email: eventObj.response.profile.email,
              lang: eventObj.response.requestParams.lang,
            },
          });
      }
    } else {
      /*Start - Analytics tracking code for Verification Sent*/
      try {
        regVerificationInit(eventObj);
      } catch (e) {
        console.error('Error in tracking Verification Sent');
      }
      let showScreen;
      if (
        eventObj &&
        eventObj.response &&
        eventObj.response.data &&
        eventObj.response.data.migratedFrom &&
        eventObj.response.data.migratedFrom != null
      ) {
        showScreen = 'gigya-migrated-thank-you-screen';
      } else {
        showScreen = 'gigya-verification-sent-screen';
      }
      if (eventObj && eventObj.context && eventObj.context.containerID) {
        typeof window !== 'undefined' &&
          window.gigya &&
          window.gigya.accounts.showScreenSet({
            screenSet: 'MufcWeb-RegistrationLogin',
            startScreen: showScreen,
            onAfterScreenLoad: (e) => onAfterVerificationLoad(e, isSubscribedContent),
            onHide: addScroll,
            containerID: eventObj.context.containerID,
            onError: errorHandler,
            context: {
              uid: eventObj.response.UID,
              provider: eventObj.response.socialProviders,
              loginProvider: eventObj.response.loginProvider,
              isVerified: eventObj.response.isVerified,
              email: eventObj.response.profile.email,
              privacystatus: eventObj.response.data.marketingConsent,
            },
          });
      } else {
        typeof window !== 'undefined' &&
          window.gigya &&
          window.gigya.accounts.showScreenSet({
            screenSet: 'MufcWeb-RegistrationLogin',
            startScreen: showScreen,
            onAfterScreenLoad: (e) => onAfterVerificationLoad(e, isSubscribedContent),
            onHide: addScroll,
            onBeforeScreenLoad: removeScroll,
            onError: errorHandler,
            context: {
              uid: eventObj.response.UID,
              provider: eventObj.response.socialProviders,
              loginProvider: eventObj.response.loginProvider,
              isVerified: eventObj.response.isVerified,
              email: eventObj.response.profile.email,
              privacystatus: eventObj.response.data.marketingConsent,
            },
          });
      }
    }
  }
};

const onAfterVerificationLoad = (eventObj, isSubscribedContent) => {
  if (isSubscribedContent) {
    showLoadingImage('z-index: 9999999999;');
    const changeText = document.getElementById('preferenceCenterMsg2');
    setTimeout(() => {
      const dialogCaptionText = document.querySelector('.gigya-screen-dialog-caption');
      if (dialogCaptionText) {
        dialogCaptionText.style.display = 'block';
      }
    }, 800);
    if (changeText) {
      changeText.textContent = 'Processing for Payment...';
    }
    const removeContinue = document.getElementById('gigya-verification-sent-form');
    if (removeContinue) {
      removeContinue.style.display = 'none';
    }
    eventObj &&
      eventObj.context &&
      eventObj.context.uid &&
      addSubscription({ param: eventObj.context.uid, token: 'uid', isNewUser: true });
  } else {
    let consent;
    let pgcFirstNameEle = document.getElementById('pgcFirstName');
    let pgcLastNameEle = document.getElementById('pgcLastName');
    let pgcEmailEle = document.getElementById('pgcEmail');
    let lblParentElm = document.getElementById('lblParentalMessage');
    let prefCenterElm = document.getElementById('preferenceCenterMsg3');
    let lblSiteElm = document.getElementById('lblSiteMessage');
    let lblSocialMessage = document.getElementById('lblSocialMessage');
    let preferenceCenterMsg1 = document.getElementById('preferenceCenterMsg1');
    let prefEmailElm = document.getElementById('preference-email');
    let preferenceElm = document.getElementById('mu-preference-form-settings');
    let prefConsentElm = document.getElementById('preference-consent');
    if (
      eventObj &&
      eventObj.context &&
      eventObj.context.pgcFirstName &&
      eventObj.context.pgcLastName &&
      eventObj.context.pgcEmail
    ) {
      if (pgcFirstNameEle && pgcLastNameEle && pgcEmailEle) {
        pgcFirstNameEle.textContent = eventObj.context.pgcFirstName;
        pgcLastNameEle.textContent = eventObj.context.pgcLastName;
        pgcEmailEle.textContent = eventObj.context.pgcEmail;
      }

      if (lblParentElm) {
        lblParentElm.style.display = 'block';
      }
      if (prefCenterElm) {
        prefCenterElm.style.display = 'block';
      }
    } else {
      if (
        (eventObj && eventObj.response && eventObj.response.socialProviders === 'site') ||
        (eventObj && eventObj.context && eventObj.context.provider === 'site')
      ) {
        if (lblSiteElm) {
          lblSiteElm.style.display = 'block';
        }
      } else {
        if (lblSocialMessage) {
          lblSocialMessage.style.display = 'block';
        }
        if (preferenceCenterMsg1) {
          preferenceCenterMsg1.style.display = 'block';
        }
      }
      if (prefEmailElm) {
        prefEmailElm.value = eventObj.context.email;
      }

      if (preferenceElm && preferenceElm.querySelector('#preference-email')) {
        preferenceElm.querySelector('#preference-email').value = eventObj.context.email;
      }

      if (eventObj && eventObj.context && eventObj.context.privacystatus) {
        eventObj.context.privacystatus === 'Yes' ? (consent = true) : (consent = false);
      }
      if (prefConsentElm) {
        prefConsentElm.value = consent;
      }
    }
  }
};

const onAfterLoad = (eventObj) => {
  onLoadCloseAttr();
  document.getElementById('pgcEmail').value = '';
  document.getElementById('pgcUid').value = eventObj.context.uid;
  document.getElementById('userName').value = eventObj.context.name;
  document.getElementById('pgcSubmit').addEventListener('click', () => {
    document.querySelector('.parent-consent-error').textContent = '';
    let flag = true;
    let customVerificatoinElm = document.querySelectorAll(
      '#gigya-parentalConsent-screen .custom-verification [data-validate]'
    );
    customVerificatoinElm.forEach((Elm, i) => {
      let parentEmailError = i18.t('PleaseprovideParentGuardiansemaila');
      let parentInvalidEmail = i18.t('InvalidemailaddressformatPleasechec');
      let parentEmailErrorElm = document.querySelector('.parentEmailError');
      let emailContextVal = eventObj && eventObj.context && eventObj.context.email;
      if ((Elm && Elm.value && Elm.value.length === 0) || (Elm && Elm.value === emailContextVal)) {
        flag = false;
        if (Elm.id === 'pgcEmail') {
          if (parentEmailErrorElm) {
            parentEmailErrorElm.innerHTML = parentEmailError;
          }
          Elm.nextElementSibling &&
            Elm.nextElementSibling.classList.add(
              'gigya-error-code-400027',
              'gigya-error-msg-active'
            );
        } else {
          Elm.nextElementSibling &&
            Elm.nextElementSibling.classList.add(
              'gigya-error-code-400027',
              'gigya-error-msg-active',
              'gigya-error-display-active'
            );
        }
        Elm.classList.add('gigya-error');
      } else if (Elm && Elm.id == 'pgcEmail' && !emailRegexCheck(Elm.value)) {
        if (parentEmailErrorElm) {
          parentEmailErrorElm.innerHTML = parentInvalidEmail;
        }
        Elm.nextElementSibling &&
          Elm.nextElementSibling.classList.add('gigya-error-code-400027', 'gigya-error-msg-active');
        flag = false;
      } else {
        Elm && Elm.classList && Elm.classList.remove('gigya-error');
        if (Elm && Elm.nextElementSibling && Elm.nextElementSibling.tagName === 'SPAN') {
          Elm.nextElementSibling.classList.remove(
            'gigya-error-code-400027',
            'gigya-error-msg-active'
          );
        } else {
          Elm &&
            Elm.nextElementSibling &&
            Elm.nextElementSibling.classList &&
            Elm.nextElementSibling.classList.remove(
              'gigya-error-code-400027',
              'gigya-error-msg-active',
              'gigya-error-display-active'
            );
        }
      }
    });
    if (flag) {
      showLoadingImage();
      let firstName =
        document.getElementById('pgcFirstName') && document.getElementById('pgcFirstName').value;
      let lastName =
        document.getElementById('pgcLastName') && document.getElementById('pgcLastName').value;
      let email = document.getElementById('pgcEmail') && document.getElementById('pgcEmail').value;
      let relation =
        document.getElementById('gigya-dropdown-pgcRelation') &&
        document.getElementById('gigya-dropdown-pgcRelation').value;
      let userName =
        document.getElementById('userName') && document.getElementById('userName').value;
      let uid = document.getElementById('pgcUid') && document.getElementById('pgcUid').value;

      let dataObj = {
        pgcFirstName: firstName,
        pgcLastName: lastName,
        pgcEmail: email,
        pgcRelation: relation,
        u13Name: userName,
        uId: uid,
        locale: eventObj.context.lang,
      };

      const parentConsentDetailsCall = () => {
        const route = getServiceUrl('parentconsentdetails');
        const data = dataObj;
        const isHardcoredGeo = true;
        return api
          .post({ route, baseURL, data, isHardcoredGeo })
          .then((response) => {
            hideLoadingImage();
            typeof window !== 'undefined' &&
              window.gigya &&
              window.gigya.accounts.hideScreenSet({
                screenSet: 'MufcWeb-RegistrationLogin',
              });
            if (eventObj.context && eventObj.context.containerID) {
              typeof window !== 'undefined' &&
                window.gigya &&
                window.gigya.accounts.showScreenSet({
                  screenSet: 'MufcWeb-RegistrationLogin',
                  startScreen: 'gigya-verification-sent-screen',
                  onAfterScreenLoad: onAfterVerificationLoad,
                  onHide: addScroll,
                  containerID: eventObj.context.containerID,
                  onError: errorHandler,
                  context: {
                    pgcFirstName: firstName,
                    pgcLastName: lastName,
                    pgcEmail: email,
                  },
                });
            } else {
              typeof window !== 'undefined' &&
                window.gigya &&
                window.gigya.accounts.showScreenSet({
                  screenSet: 'MufcWeb-RegistrationLogin',
                  startScreen: 'gigya-verification-sent-screen',
                  onAfterScreenLoad: onAfterVerificationLoad,
                  onHide: addScroll,
                  onBeforeScreenLoad: removeScroll,
                  onError: errorHandler,
                  context: {
                    pgcFirstName: firstName,
                    pgcLastName: lastName,
                    pgcEmail: email,
                  },
                });
            }
          })
          .catch((err) => {
            debugger;
            hideLoadingImage();
            document.querySelector('.parent-consent-error').textContent = i18.t(
              'Sorryitlooksliketheresatemporary'
            );
          });
      };
      parentConsentDetailsCall();
    }
  });
};

const showLoadingImage = (obj = 'z-index: 99999') => {
  let htmlContent = '';
  htmlContent += `<div class="loader-container" style="${obj}​​​​​​">`;
  htmlContent += '<div class="mu-pageloader">';
  htmlContent += '<div class="loader-logo"></div>';
  htmlContent += '<div class="loader"></div>';
  htmlContent += '</div>';
  htmlContent += '</div>';
  document.getElementsByTagName('body')[0].insertAdjacentHTML('beforeend', htmlContent);
};

const hideLoadingImage = () => {
  let loaderConElm = document.querySelector('.loader-container');
  loaderConElm && loaderConElm.remove();
};

const onButtonSubmit = (eventObj) => {
  if (eventObj && eventObj.screen === 'gigya-reset-password-screen') {
    resetPassword();
  }
};

export const readCookie = (name) => {
  let nameEQ = name + '=';
  let ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

const allowUserLogin = (eventObj) => {
  /* Start - Analytics tracking code for loginComplete */
  try {
    loginComplete(eventObj);
  } catch (e) {}
  redirectToDestination();
  window.getLoginDetail(eventObj);
};

export const redirectToDestination = () => {
  if (getParameterByName('ret') != undefined || getParameterByName('ret') != null) {
    window.location = getQueryStringParameters();
  }
};
const setUserSession = (eventObj) => {
  localStorage.setItem('deviceID', eventObj && eventObj.UIDSignature);
};
const getLoginInfoResponse = (eventObj) => {
  if (eventObj && eventObj.errorCode === 0) {
    setUserSession(eventObj);
    if (
      ((eventObj.socialProviders === 'site' || eventObj.socialProviders === '') &&
        !eventObj.isVerified) ||
      (eventObj.data && eventObj.data.isSocialAllowed === 'false')
    ) {
      typeof window !== 'undefined' && window.gigya && window.gigya.accounts.logout();
      if (eventObj.context && eventObj.context.newUser === false) {
        if (
          eventObj.profile &&
          eventObj.profile.age < 13 &&
          eventObj.data.isPGCVerified === undefined
        ) {
          showRegisterLogin('gigya-parent-consent-pending-screen');
        } else if (eventObj.profile.age < 13 && eventObj.data.isPGCVerified === 'False') {
          showRegisterLogin('gigya-parent-consent-decline-screen');
        } else {
          showRegisterLogin('gigya-login-message-screen');
        }
      }
    } else if (
      eventObj.context &&
      eventObj.context.newUser === true &&
      eventObj.profile &&
      eventObj.profile.age < 13 &&
      (eventObj.loginProvider === 'googleplus' || eventObj.loginProvider === 'facebook')
    ) {
      typeof window !== 'undefined' && window.gigya && window.gigya.accounts.logout();
    } else if (eventObj.loginProvider === 'facebook' && !eventObj.isVerified) {
      typeof window !== 'undefined' && window.gigya && window.gigya.accounts.logout();
      if (eventObj.context && eventObj.context.newUser === false) {
        showRegisterLogin('gigya-login-message-screen');
      }
    } else {
      saveUserIDCookie(eventObj?.UID);
      allowUserLogin(eventObj);
    }
  } else {
    errorHandler(eventObj);
  }
};

export const loginEventHandler = (eventObj) => {
  if (eventObj && eventObj.context && eventObj.context.redirect) {
    typeof window !== 'undefined' &&
      window.gigya &&
      window.gigya.accounts.getAccountInfo({
        callback: getLoginInfoResponse,
        include: 'identities-active,profile,data',
        context: {
          newUser: eventObj.newUser,
          redirect: eventObj.context.redirect,
        },
      });
  } else {
    typeof window !== 'undefined' &&
      window.gigya &&
      window.gigya.accounts.getAccountInfo({
        callback: getLoginInfoResponse,
        include: 'identities-active,profile,data',
        context: { newUser: eventObj.newUser },
      });
  }
};

// export const checkLoggedStatus = () => {
//   if (typeof window !== 'undefined' && window.gigya) {
//     window.gigya.accounts.addEventHandlers({
//       onLogin: loginEventHandler,
//     });
//     return window.gigya.accounts.getAccountInfo({
//       callback: function (response) {
//         if (response.errorCode === 0) {
//           if (document.querySelector('[data-module="wayin"]') > 0) {
//             // *** Todo populateWayinForm(response) ***
//           }
//         }
//       },
//     });
//   }
// };

// export const resetPassword = () => {
// };

/* for testing of confirm password field */
const DisplayError = (inputField) => {
  inputField.ErrorMsg.classList.add(
    'gigya-error-code-400027',
    'gigya-error-type-server',
    'gigya-error-msg-active'
  );
  inputField.ErrorMsg.textContent = `${i18.t('Pleasecompletetheinformationrequired')}`;
  inputField.Password.classList.add('gigya-empty', 'gigya-error');
  inputField.Password.ariaInvalid = true;
  inputField.Password.required = true;
};
const verifyPassword = (inputField) => {
  if (inputField.length > 0) {
    inputField.map((item) => {
      DisplayError(item);
    });
  } else {
    DisplayError(inputField);
  }
};
export const resetPassword = () => {
  let fieldStatus = document.getElementById('lblStatus');
  let newPass = document.getElementById('gigya-password-newPassword');
  let retypePassword = document.getElementById('gigya-passsord-passwordRetype');
  let errorMsgNew = newPass.nextElementSibling.nextElementSibling;
  let errorMsgConfirm = retypePassword.nextElementSibling;
  let inputField;
  fieldStatus.textContent = '';
  if (
    newPass &&
    newPass.value.trim() == '' &&
    retypePassword &&
    retypePassword.value.trim() == ''
  ) {
    inputField = [
      { Password: newPass, ErrorMsg: errorMsgNew },
      { Password: retypePassword, ErrorMsg: errorMsgConfirm },
    ];
    verifyPassword(inputField);
    fieldStatus.style.display = 'none';
    fieldStatus.textContent = `${i18.t('Pleasecompletetheinformationrequired')}`;
  } else if (
    !newPass ||
    !newPass.value.trim() ||
    ((!retypePassword || !retypePassword.value.trim()) && fieldStatus)
  ) {
    inputField =
      newPass && newPass.value.trim() == ''
        ? { Password: newPass, ErrorMsg: errorMsgNew }
        : { Password: retypePassword, ErrorMsg: errorMsgConfirm };
    verifyPassword(inputField);
    fieldStatus.style.display = 'none';
    fieldStatus.textContent = `${i18.t('Pleasecompletetheinformationrequired')}`;
  } else if (
    (newPass && newPass.value.trim()) !== (retypePassword && retypePassword.value.trim())
  ) {
    fieldStatus.style.display = 'none';
    fieldStatus.textContent = `${i18.t('The passwords entered do not match')}`;
  } else {
    fieldStatus.style.display = 'block';
    typeof window !== 'undefined' &&
      window.gigya &&
      window.gigya.accounts.resetPassword({
        passwordResetToken: getParameterByName('pwrt'),
        newPassword: newPass && newPass.value,
        callback: afterResetPassword,
      });
  }
};

const afterResetPassword = (response) => {
  let newPass = document.getElementById('gigya-password-newPassword');
  let errorMsgNew = newPass.nextElementSibling.nextElementSibling;
  let fieldStatus = document.getElementById('lblStatus');
  let resetPassElm = document.querySelector('.reset-password-lblStatus');
  if (response && response.errorCode === 0) {
    typeof window !== 'undefined' &&
      window.gigya &&
      window.gigya.accounts.showScreenSet({
        screenSet: 'MufcWeb-ProfileUpdate',
        startScreen: 'gigya-reset-password-message-screen',
        onHide: addScroll,
        onBeforeScreenLoad: removeScroll,
        onError: errorHandler,
        onAfterScreenLoad: bindClickEvent,
      });
    setVerficationOnForgotPwd(response.UID);
  } else {
    if (fieldStatus) {
      if (response && response.errorCode === 400006) {
        fieldStatus.textContent = `${i18.t('YourPasswordResetLinkHasExpired')}`;
      } else if (response.errorMessage == 'Old Password Used') {
        errorMsgNew.textContent = 'Old Password Used';
        fieldStatus.style.display = 'none';
        errorMsgNew.setAttribute('style', 'display:block !important');
        errorMsgNew.classList.add(
          'gigya-error-code-400027',
          'gigya-error-type-server',
          'gigya-error-msg-active'
        );
        errorMsgNew.style.marginTop = '5px';
      } else {
        fieldStatus.textContent = response.errorMessage;
        fieldStatus.style.display = 'block';
        errorMsgNew.removeAttribute('style', 'display:block !important');
      }
    }
    if (resetPassElm) {
      resetPassElm.style.display = 'block';
    }
  }
};

export const bindClickEvent = () => {
  let resetPasswordElm = document.querySelector(
    '#gigya-reset-password-message-form a input.cta-btn-arw'
  );
  resetPasswordElm &&
    resetPasswordElm.addEventListener('click', () => {
      redirectToOrigin();
    });
};

export const redirectToOrigin = () => {
  window.location = `${window.location.origin}/${getLanguage()}`;
};

export const afterMessageLoad = (eventObj) => {
  let lblMessElm = document.getElementById('lblMessage');
  if (lblMessElm) {
    lblMessElm.innerHTML = eventObj.context.message;
  }
};

export const setUserVerfication = () => {
  let customLangParams = getCustomLang();
  let providerList = getProviderList(MU && MU.countryCode);
  let pwrtElm = getParameterByName('pwrt');
  let pgcElm = getParameterByName('pgc', true);
  let evlElm = getParameterByName('evl', true);
  if (pwrtElm != undefined || pwrtElm != null) {
    window.gigya &&
      window.gigya.accounts.showScreenSet({
        screenSet: 'MufcWeb-ProfileUpdate',
        startScreen: 'gigya-reset-password-screen',
        customLang: customLangParams,
        onHide: addScroll,
        onBeforeScreenLoad: removeScroll,
        onAfterScreenLoad: restPasswordCustomMessage,
        onError: errorHandler,
      });
  }
  if (pgcElm != null || pgcElm != undefined) {
    const setParentConsent = () => {
      const route = `${getServiceUrl('setparentconsent')}?pgc=${encodeURIComponent(pgcElm)}`;
      const isHardcoredGeo = true;
      return api
        .get({ route, baseURL, isHardcoredGeo })
        .then((response) => {
          window.gigya &&
            window.gigya.accounts.showScreenSet({
              screenSet: 'MufcWeb-RegistrationLogin',
              enabledProviders: providerList,
              startScreen: 'gigya-message-screen',
              onAfterScreenLoad: afterMessageLoad,
              onHide: addScroll,
              onBeforeScreenLoad: removeScroll,
              onError: errorHandler,
              context: {
                message: response && response.data && response.data.consentMsg,
              },
            });
        })
        .catch((err) => {
          showRegisterLogin('gigya-parent-consent-link-error-screen');
        });
    };
    setParentConsent();
  }

  if ((evlElm && evlElm != null) || (evlElm && evlElm != undefined)) {
    showLoadingImage();
    const route = `${getServiceUrl('setemailverification')}?evl=${encodeURIComponent(evlElm)}`;
    const isHardcoredGeo = true;
    return api
      .get({ route, baseURL, isHardcoredGeo })
      .then((response) => {
        if (response.data && response.data.message === 'success') {
          /* Start - Analytics tracking code for registrationComplete Sent */
          try {
            const eventObj = { response: { UID: response.data.UidGigya, loginProvider: 'native' } };
            registrationComplete(eventObj);
          } catch (e) {
            console.log('error in registrationComplete function');
          }
          setTimeout(() => {
            window.location = '/redirect/my-united-confirmation';
          }, 200);
        } else if (response.data && response.data.message === 'expired') {
          showRegisterLogin('gigya-emailexpired-message-screen');
        } else if (response.data && response.data.message === 'verified') {
          showRegisterLogin('gigya-emailverified-message-screen');
        }
      })
      .catch((err) => {
        showRegisterLogin('gigya-email-activation-link-error-screen');
      });
  }
};

const addScroll = (eventObj) => {
  let elmBody = document.getElementsByTagName('body');
  if (eventObj && eventObj.context && eventObj.context.screen === 'profileUpdate') {
    window.location.reload();
  } else {
    elmBody[0] && elmBody[0].classList.remove('scroll-remove');
  }
  if (document && document.body && document.body.classList.contains('scrollHideAll')) {
    elmBody[0] && elmBody[0].classList.remove('scrollHideAll');
  }
};

const removeScroll = (eventObj) => {
  let navCloseElm = document.querySelector('.nav-close');
  let bodyElm = document.querySelector('body');
  if (eventObj && eventObj.currentScreen === 'gigya-link-account-screen') {
    let seperator = document.querySelector('.account-linking-seprator');
    if (seperator && seperator.style.display !== 'none') {
      let seperatorElm = document.querySelectorAll(
        '.responsive',
        '.custom-space',
        '.gigya-container',
        '.gigya-visible-when',
        '.or-visbility'
      );
      seperatorElm[0].classList.remove(
        '.responsive',
        '.custom-space',
        '.gigya-container',
        '.gigya-visible-when',
        '.or-visbility'
      );
    }
  } else if (
    ('gigya-register-screen' === eventObj && eventObj.nextScreen) ||
    ('gigya-login-screen' === eventObj && eventObj.nextScreen)
  ) {
    navCloseElm && navCloseElm.addEventListener('click', (event) => {});
  }

  if (eventObj && eventObj.screen === 'gigya-reset-password-screen') {
    resetPassword();
  }
  bodyElm && bodyElm.classList && bodyElm.classList.add('scroll-remove', 'scrollHideAll');
};

export const getProviderList = (geoValue) => {
  let getProvderName = geoValue !== 'CN' ? 'facebook,googleplus,apple' : 'sina,wechat,apple';
  return getProvderName;
};

export const getCustomLang = () => {
  let customLangParams = {
    email_already_exists: i18.t('Anaccounthasalreadybeencreatedusin'),
    invalid_login_or_password: i18.t('Pleaseenteravalidemailaddressorpa'),
    there_is_no_user_with_that_username_or_email: i18.t('Thereisnoaccountregisteredwiththis'),
    email_address_is_invalid: i18.t('InvalidemailaddressformatPleasechec'),
    this_field_is_required: i18.t('Pleasecompletetheinformationrequired'),
    password_does_not_meet_complexity_requirements:
      '<b>' + i18.t('InvalidPassword') + ':</b>' + i18.t('PleaseMeetTheRequirementsBelow'),
    password_must_contain_at_least: i18.t('Yourpasswordmustcontainatleast'),
    num_of_the_following_groups: i18.t('aminimumof1uppercaseletter1lower'),
    passwords_do_not_match: i18.t('ThepasswordsentereddonotmatchPleas'),
    account_temporarily_locked_out: i18.t('Youhaveenteredtheincorrectpassword'),
    old_password_cannot_be_the_same_as_new_password: i18.t(
      'YourNewPasswordCannotBeTheSameAsYourOldPassword'
    ),
  };
  return customLangParams;
};

const showRegisterLogin = (startScr, isSubscribedContent, analyticsObj) => {
  let providerList = getProviderList(MU && MU.countryCode);
  let customLangParams = getCustomLang();
  let params = {
    screenSet: 'MufcWeb-RegistrationLogin',
    startScreen: startScr,
    customLang: customLangParams,
    enabledProviders: providerList,
    onAfterSubmit: (e) => afterSubmit(e, isSubscribedContent),
    onAfterScreenLoad: (e) => loadScreenSet(e, analyticsObj, isSubscribedContent),
    onHide: addScroll,
    onBeforeScreenLoad: beforeScreenLoad,
    onSubmit: onButtonSubmit,
    onError: errorHandler,
    onBeforeValidation: beforeSubmitValidationCheck,
    onFieldChanged: onChangeEnableButton,
    onBeforeSubmit: onSocialEmailValid,
  };
  typeof window !== 'undefined' && window.gigya && window.gigya.accounts.showScreenSet(params);
};

const onChangeEnableButton = (eventObj) => {
  let resetPasswrodSubmitElm = document.querySelector(
    '.gigya-reset-password-form .gigya-input-submit'
  );
  if (
    eventObj &&
    eventObj.screen === 'gigya-forgot-password-screen' &&
    eventObj.form === 'gigya-reset-password-form'
  ) {
    resetPasswrodSubmitElm && resetPasswrodSubmitElm.removeAttribute('disabled');
  }
};

const beforeSubmitValidationCheck = () => {
  let emailElm = document.querySelector('#gigya-register-form input#email');
  let passwordElm = document.querySelector('#gigya-register-form input#password');
  let reElm = document.querySelector('#gigya-register-form input#passwordRetype');
  setTimeout(function () {
    if (emailElm && emailElm.nextElementSibling && emailElm.nextElementSibling.nextElementSibling) {
      emailElm.nextElementSibling.nextElementSibling.innerHTML = i18.t(
        'Emailaddressfieldcannotbeleftblank'
      );
    }
    if (
      passwordElm &&
      passwordElm.nextElementSibling &&
      passwordElm.nextElementSibling.nextElementSibling
    ) {
      passwordElm.nextElementSibling.nextElementSibling.innerHTML = i18.t(
        'Passwordsfieldcannotbeleftblank'
      );
    }
    if (reElm && reElm.nextElementSibling && reElm.nextElementSibling.nextElementSibling) {
      reElm.nextElementSibling.nextElementSibling.innerHTML = i18.t(
        'Confirmpasswordfieldcannotbeleftblank'
      );
    }
  }, 100);
};

const onSocialEmailValid = () => {
  const emailFieldElm = document.getElementById('gigya-textbox-email');
  const classList = emailFieldElm.classList;
  const nextClassList = emailFieldElm.nextElementSibling;
  if (emailFieldElm && emailFieldElm.value === '') {
    classList.add('gigya-error');
    classList.remove('gigya-empty', 'gigya-valid');
    nextClassList.classList.add(
      'gigya-error-code-400027',
      'gigya-error-msg-active',
      'gigya-error-type-server'
    );
    nextClassList.innerHTML = i18.t('Emailaddressfieldcannotbeleftblank');
    return false;
  }
};

export const errorHandler = (eventObj) => {
  let gigyaErrors = [
    301001,
    400008,
    400029,
    400093,
    400106,
    400120,
    400122,
    401000,
    401010,
    403000,
    403006,
    403007,
    403010,
    403022,
    403023,
    403024,
    403035,
    403036,
    403040,
    403045,
    404004,
    405001,
    409012,
    409040,
    500000,
    500001,
    500002,
    500003,
    500014,
    500023,
    500026,
    500028,
    500031,
    500033,
    500034,
    504001,
    504002,
  ];
  if (gigyaErrors.indexOf(eventObj.errorCode) !== -1) {
    window.location.replace(`/${getLanguage()}/PageLoadFail`);
  }
};

const signupFocus = () => {
  const signupPageCheckbox = document.querySelectorAll(
    '.gigya-register-form .gigya-input-checkbox'
  );
  if (signupPageCheckbox && signupPageCheckbox[0].getAttribute('aria-hidden')) {
    signupPageCheckbox[0].setAttribute('tabindex', '-1');
  }
};

export const loadScreenSet = (eventObj, analyticsObj, isSubscribedContent) => {
  let dividerElm = document.querySelector('.gigya-divider-content');
  let socialElm = document.querySelector('.with-social-login');
  let containerElm = document.querySelector('.main-container');
  const bodyElm = document.getElementsByTagName('BODY');
  const blacktheme =
    bodyElm && bodyElm[0] && bodyElm[0].classList && bodyElm[0].classList.contains('black-theme');
  if (blacktheme) {
    bodyElm[0].classList.remove('black-theme');
  }
  // if (eventObj && eventObj.currentScreen === 'gigya-complete-registration-screen') {
  //   onKeyupValidation();
  // }
  if (eventObj && eventObj.currentScreen === 'gigya-login-screen') {
    emailValFn();
  }
  if (MU && MU.countryCode === 'CN') {
    if (dividerElm) {
      dividerElm.style.display = 'none';
    }
    if (socialElm) {
      socialElm.style.display = 'block';
    }
  }

  hideLoadingImage();
  let gigyaElm = document.getElementById('gigya-screen-dialog-page-overlay');
  let gigyaSelector = gigyaElm
    ? gigyaElm && gigyaElm.parentElement
    : document.getElementById('registerDiv');
  gigyaSelector &&
    gigyaSelector.classList &&
    gigyaSelector.classList.remove(
      'gigya-register-custom',
      'gigya-login-custom',
      'gigya-forgot-password-custom',
      'gigya-link-accounts-form-custom',
      'gigya-reauthentication-form-custom'
    );
  if (eventObj && gigyaSelector) {
    if (eventObj.currentScreen === 'gigya-login-screen') {
      gigyaSelector.classList && gigyaSelector.classList.add('gigya-login-custom');
      loginStart(eventObj, analyticsObj);
      gigyaLoginGetDictionary();
      hideAndShowPassword();
      socialProviderAccessible();
      onLoadCloseAttr();
      focusOnError();
    }
    if (eventObj.currentScreen === 'gigya-register-screen') {
      isSubscribedContent && getThemeInfo();
      gigyaSelector.classList && gigyaSelector.classList.add('gigya-register-custom');
      gigyaLoginGetDictionary();
      customPasswordValidation();
      socialProviderAccessible();
      onLoadCloseAttr();
      signupFocus();
    }

    if (eventObj.currentScreen === 'gigya-forgot-password-screen') {
      removeBlackTheme();
      gigyaSelector.classList && gigyaSelector.classList.add('gigya-forgot-password-custom');
    }
    if (eventObj.currentScreen === 'gigya-link-account-screen') {
      gigyaSelector.classList && gigyaSelector.classList.add('gigya-link-accounts-form-custom');
      gigyaLoginGetDictionary();
      hideAndShowPassword();
      socialProviderAccessible();
      onLoadCloseAttr();
    }
    if (eventObj.currentScreen === 'gigya-reauthentication-screen') {
      gigyaSelector.classList && gigyaSelector.classList.add('gigya-reauthentication-form-custom');
      hideAndShowPassword();
      gigyaLoginGetDictionary();
      socialProviderAccessible();
      onLoadCloseAttr();
    }
  }
  if (
    (eventObj && eventObj.currentScreen === 'gigya-link-account-screen') ||
    (eventObj && eventObj.currentScreen === 'gigya-complete-registration-screen')
  ) {
    themeChangeScreen(isSubscribedContent, false);
  } else if (eventObj && eventObj.currentScreen === 'gigya-register-screen') {
    themeChangeScreen(isSubscribedContent, true);
  }

  if (eventObj && eventObj.currentScreen === 'gigya-forgot-password-screen') {
    emailValFn();
  }
  if (
    eventObj &&
    (eventObj.currentScreen === 'gigya-register-screen' ||
      eventObj.currentScreen === 'gigya-link-account-screen' ||
      eventObj.currentScreen === 'gigya-reauthentication-screen')
  ) {
    /* Start - Analytics tracking code for registrationStart */
    registrationStart(null, analyticsObj);
    validatePassword();
    onLoadCloseAttr();
    emailValFn();
  } else if (eventObj && eventObj.currentScreen === 'gigya-verification-sent-screen') {
    onAfterVerificationLoad(eventObj);
  } else if (eventObj && eventObj.currentScreen === 'gigya-login-screen') {
    document.querySelector('.gigya-input-password') &&
      document.querySelector('.gigya-input-password').addEventListener('click', (e) => {
        e.preventDefault();
      });
  }

  if (
    eventObj &&
    (eventObj.currentScreen === 'gigya-register-screen' ||
      eventObj.currentScreen === 'gigya-complete-registration-screen')
  ) {
    document
      .getElementById('id_urn')
      .val(
        Math.round((new Date().getTime() - new Date('2017-12-01').getTime()) / 1000) +
          Math.floor(Math.random() * 100).toString()
      );
  }

  if (eventObj && eventObj.currentScreen === 'gigya-forgot-password-success-screen') {
    let resetMailElm = document.getElementById('reset_mail');
    let loginID =
      eventObj.response &&
      eventObj.response.requestParams &&
      eventObj.response.requestParams.loginID;
    if (resetMailElm) {
      resetMailElm.innerText = loginID;
    }
  }
  if (containerElm) {
    containerElm.style.display = 'block';
  }
};

const themeChangeScreen = (isSubscribedContent, titleHideClass) => {
  if (isSubscribedContent) {
    getThemeInfo();
    const blackThemeTitle = document.querySelector('.gigya-screen-dialog-caption');
    if (blackThemeTitle) {
      titleHideClass
        ? blackThemeTitle.classList.add('title-hide')
        : blackThemeTitle.classList.remove('title-hide');
    }
  }
};

const ariaRequiredUpdate = () => {
  const yearDropDownElm = document.querySelectorAll('#gigya-dropdown-119412947812715380');
  if (yearDropDownElm && yearDropDownElm.length > 0) {
    yearDropDownElm.forEach((element) => {
      element && element.setAttribute('aria-required', 'true');
    });
  }
  const monthDropDownElm = document.querySelectorAll('#gigya-dropdown-93707960692191820');
  if (monthDropDownElm && monthDropDownElm.length > 0) {
    monthDropDownElm.forEach((element) => {
      element && element.setAttribute('aria-required', 'true');
    });
  }
  const dateDropDownElm = document.querySelectorAll('#gigya-dropdown-78676557102405600');
  if (dateDropDownElm && dateDropDownElm.length > 0) {
    dateDropDownElm.forEach((element) => {
      element && element.setAttribute('aria-required', 'true');
    });
  }
  const memberNumber = document.querySelectorAll('#gigya-textbox-35441821385175400');
  if (memberNumber && memberNumber.value) {
    memberNumber[0].setAttribute('aria-label', memberNumber.value);
  } else if (memberNumber && !memberNumber.value) {
    memberNumber[0].setAttribute('aria-label', '');
  }
};

const removeBlackTheme = () => {
  var themeElement = document.querySelector('.black-theme');
  if (themeElement) {
    themeElement.classList.remove('black-theme');
  }
};

const addBlackTheme = () => {
  const getBody = document.getElementsByTagName('body')[0];
  getBody && getBody.classList.add('black-theme');
};

const getThemeInfo = () => {
  let mutvSection = document.querySelector('#gigya-register-screen .mutv-wrap');
  addBlackTheme();
  if (mutvSection) {
    mutvSection.style.display = 'block';
  }
};

export const showRegister = (getLoginDetail, isMutv, isSubscribedContent, analyticsObj) => {
  let containerElm = document.querySelector('.main-container');
  let bodyElm = document.querySelector('body');
  if (containerElm) {
    containerElm.style.display = 'block';
  }
  showLoadingImage();
  if (!isMutv) {
    removeBlackTheme();
  }
  showRegisterLogin('gigya-register-screen', isSubscribedContent, analyticsObj);
  if (bodyElm) {
    bodyElm.classList.add('register-custom-ui');
  }
  window.getLoginDetail = getLoginDetail;
};
const afterPassSubmit = (eventObj) => {
  let currentPassword = document.getElementById('password');
  let newPassword = document.getElementById('newPassword');
  let retypePassword = document.getElementById('passwordRetype');
  let newPassErr = newPassword.nextElementSibling.nextElementSibling.nextElementSibling;
  let currentPassErr = currentPassword.parentElement.nextElementSibling;
  let retypePassErr = retypePassword.parentNode.parentElement.nextElementSibling;
  if (eventObj.response.errorDetails == 'invalid loginID or password') {
    newPassErr.style.display = 'none';
    retypePassErr.style.display = 'none';
    currentPassErr.style.display = 'block';
  } else if (
    eventObj.response.errorDetails == "'password' cannot be the same as 'newPassword'" ||
    eventObj.response.errorDetails == 'Old Password Used'
  ) {
    newPassErr.style.marginTop = '25px';
    currentPassErr.style.display = 'none';
    retypePassErr.style.display = 'none';
    newPassErr.style.display = 'block';
  } else {
    newPassErr.style.display = 'none';
    currentPassErr.style.display = 'none';
    retypePassErr.style.padding = '30px 0px 30px 0px';
    retypePassErr.style.display = 'block';
  }
};
export const showChangePassword = () => {
  showLoadingImage();
  let customLangParams = getCustomLang();
  let params = {
    screenSet: 'MufcWeb-ProfileUpdate',
    startScreen: 'gigya-change-password-screen',
    onHide: addScroll,
    customLang: customLangParams,
    onBeforeScreenLoad: removeScroll,
    onAfterScreenLoad: changePasswordCustomMessage,
    onError: errorHandler,
    onAfterSubmit: afterPassSubmit,
  };
  typeof window !== 'undefined' && window.gigya && window.gigya.accounts.showScreenSet(params);
};

export const showReAuthScreen = () => {
  let providerList = getProviderList(MU && MU.countryCode);
  let params = {
    screenSet: 'MufcWeb-ReAuthentication',
    startScreen: 'gigya-reauthentication-screen',
    enabledProviders: providerList,
    onBeforeScreenLoad: removeScroll,
    onHide: addScroll,
    onError: errorHandler,
  };
  typeof window !== 'undefined' && window.gigya && window.gigya.accounts.showScreenSet(params);
};

export const validatePassword = () => {
  let loginPasswordRetype = document.querySelector(
    "#gigya-register-form input[name='passwordRetype']"
  );
  let loginPassword = document.querySelector("#gigya-register-form input[name='password']");
  loginPasswordRetype && loginPasswordRetype.setAttribute('disabled', false);
  loginPassword &&
    loginPassword.addEventListener('keyup', (e) => {
      if (loginPassword && loginPassword.value && loginPassword.value.length > 7) {
        loginPasswordRetype && loginPasswordRetype.removeAttribute('disabled', false);
      } else {
        loginPasswordRetype && loginPasswordRetype.setAttribute('disabled', true);
      }
    });
};

export const showEditProfile = () => {
  showLoadingImage();
  let context = { screen: 'profileUpdate' };
  let params = {
    screenSet: 'MufcWeb-ProfileUpdate',
    onAfterScreenLoad: afterScreenLoad,
    onHide: addScroll,
    onBeforeScreenLoad: removeScroll,
    context: context,
    onSubmit: onButtonSubmit,
    onError: errorHandler,
  };
  typeof window !== 'undefined' && window.gigya && window.gigya.accounts.showScreenSet(params);
};

const editProfile = () => {
  if (typeof window !== 'undefined' && MU.isIDMEnabled) {
    window.location = createEditProfileUrl();
  } else {
    showEditProfile();
  }
};
const setVerficationOnForgotPwd = (input) => {
  const route = `${getServiceUrl('setaccountverification')}?UID=${input}`;
  const isHardcoredGeo = true;
  return api
    .get({ route, baseURL, isHardcoredGeo })
    .then((response) => {})
    .catch((err) => {});
};

export const onlyNumber = (e) => {
  var keycode = e.which ? e.which : e.keyCode;
  if ((keycode < 48 || keycode > 57) && keycode !== 13) {
    e.preventDefault();
    return false;
  }
};

const showAvailableIDInfo = (response) => {
  let elmSel = document.querySelector;
  if (response && !response.isAvailable) {
    elmSel('.under-twitter .gigya-error-msg') &&
      elmSel('.under-twitter .gigya-error-msg').text(
        i18.t('AnAccountHasAlreadyBeenCreatedUsingThisEmailAddress')
      );
    if (elmSel('.under-twitter .gigya-error-msg')) {
      elmSel('.under-twitter .gigya-error-msg').style.visibility = 'visible';
    }
    elmSel('.custom-submit') && elmSel('.custom-submit').setAttribute('disabled', 'disabled');
  } else {
    elmSel('.under-twitter .gigya-error-msg') &&
      elmSel('.under-twitter .gigya-error-msg').innerText('');
    elmSel('.custom-submit') && elmSel('.custom-submit').removeAttribute('disabled', '');
  }
};

export const getAvailableIDInfo = (loginID) => {
  if (loginID !== '') {
    let error =
      document.querySelector('.social-error-msg') &&
      document.querySelector('.social-error-msg').innerText;
    if (error === '' || error === i18.t('AnAccountHasAlreadyBeenCreatedUsingThisEmailAddress')) {
      let params = {
        loginID: loginID,
        callback: showAvailableIDInfo,
      };
      typeof window !== 'undefined' &&
        window.gigya &&
        window.gigya.accounts.isAvailableLoginID(params);
    }
  }
};

const beforeScreenLoad = (eventObj) => {
  let closeElm = document.querySelector('.gigya-screen-dialog-close');
  onScreenLoad(eventObj);
  removeScroll(eventObj);
  closeElm && closeElm.querySelector('button') && closeElm.querySelector('button').focus();
};

export const onScreenLoad = (eventObj) => {
  let socialElm = document.querySelector('.social-screen-login');
  let emailElm = document.querySelector('.inputEmailValidate');
  let spanEmailValElm = document.querySelector('.spanEmailValidate');
  if (
    eventObj &&
    eventObj.nextScreen === 'gigya-complete-registration-screen' &&
    eventObj.profile &&
    eventObj.profile.email
  ) {
    socialElm && socialElm.setAttribute('disabled', 'disabled');
  }
  if (eventObj && eventObj.nextScreen === 'gigya-forgot-password-screen') {
    emailElm && emailElm.classList && emailElm.classList.remove('gigya-valid gigya-error');
    spanEmailValElm &&
      spanEmailValElm.classList &&
      spanEmailValElm.classList.remove('gigya-error-msg-active', 'gigya-error-display-active');
  }
};
export const submitPreferenceSettings = () => {
  window.open(changePreferencesUrl(), '_blank');
};

const emailValFn = () => {
  const resetInputFieldVal = document.querySelectorAll('.inputEmailValidate');
  if (resetInputFieldVal && resetInputFieldVal.length > 0) {
    resetInputFieldVal.forEach((element) =>
      element.addEventListener('blur', (evt) => {
        const invalidEmailAddressFormat = i18.t('InvalidemailaddressformatPleasechec');
        const emailAddressFieldCannotBeLeftBlank = i18.t('Emailaddressfieldcannotbeleftblank');
        const emailCompElem = evt.target.parentElement;
        const email = emailCompElem.querySelector('.inputEmailValidate.gigya-valid');
        const inputEmailElm = emailCompElem.querySelector('.inputEmailValidate');
        const emailValElm = emailCompElem.querySelectorAll('.spanEmailValidate');

        const formType = emailCompElem.parentElement.parentElement.parentElement.parentElement;
        formType &&
          formType.querySelector('.gigya-input-submit') &&
          formType.querySelector('.gigya-input-submit').removeAttribute('disabled');

        if (email && email.value !== '' && email.value !== undefined && emailValElm) {
          if (!emailRegexCheck(email.value)) {
            inputEmailElm && inputEmailElm.classList && inputEmailElm.classList.add('gigya-error');
            emailValElm[0] &&
              emailValElm[0].classList.add(
                'gigya-error-code-400027',
                'gigya-error-msg-active',
                'gigya-error-display-active'
              );
            emailValElm[0].textContent = invalidEmailAddressFormat;
          } else {
            inputEmailElm &&
              inputEmailElm.classList &&
              inputEmailElm.classList.remove('gigya-error');
            emailValElm[0] &&
              emailValElm[0].classList &&
              emailValElm[0].classList.remove(
                'gigya-error-code-400027',
                'gigya-error-msg-active',
                'gigya-error-display-active'
              );
            emailValElm[0].textContent = '';
          }
        } else if (
          (inputEmailElm && inputEmailElm.value == '') ||
          inputEmailElm.value == undefined
        ) {
          inputEmailElm && inputEmailElm.classList.add('gigya-error');
          emailValElm &&
            emailValElm[0].classList.add(
              'gigya-error-code-400027',
              'gigya-error-msg-active',
              'gigya-error-display-active'
            );
          emailValElm[0].textContent = emailAddressFieldCannotBeLeftBlank;
        } else if (emailValElm && emailValElm[0] && emailValElm[0].textContent !== '') {
          inputEmailElm && inputEmailElm.classList.add('gigya-error');
          emailValElm[0].classList.add(
            'gigya-error-code-400027',
            'gigya-error-msg-active',
            'gigya-error-display-active'
          );
          emailValElm[0].textContent = emailAddressFieldCannotBeLeftBlank;
        } else if (email && email.value && emailValElm) {
          inputEmailElm && inputEmailElm.classList.add('gigya-error');
          emailValElm[0] &&
            emailValElm[0].classList.add(
              'gigya-error-code-400027',
              'gigya-error-msg-active',
              'gigya-error-display-active'
            );
          emailValElm[0].textContent = emailAddressFieldCannotBeLeftBlank;
        }
      })
    );
  }
};

const emailRegexCheck = (email) => {
  let emailRegex = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
  if (emailRegex.test(email)) {
    return true;
  } else {
    return false;
  }
};

const getQueryStringParameters = () => {
  let urlParams = {},
    match,
    additional = /\+/g, // Regex for replacing additional symbol with a space
    search = /([^&=]+)=?([^&]*)/g,
    decode = function (s) {
      return decodeURIComponent(s.replace(additional, ' '));
    },
    query;

  query = xss(window.location.search.substring(1));

  let count_entries = 1;
  let redirect_url = '';
  while ((match = search.exec(query))) {
    urlParams[decode(match[1])] = decode(match[2]);
    if (count_entries > 1) {
      //if more than one query param exists
      redirect_url = redirect_url + '&' + decode(match[1]) + '=' + decode(match[2]);
    } else {
      redirect_url = redirect_url + decode(match[2]); //with single query param
    }
    count_entries = count_entries + 1; //count number of query params
  }
  return redirect_url;
};

const customPasswordValidation = () => {
  let lowercount = 1;
  let uppercount = 1;
  let minlengthcount = 1;

  const passwordCustomElm = document.querySelectorAll('._passwordCustomValidation');

  if (passwordCustomElm && passwordCustomElm.length > 0) {
    passwordCustomElm.forEach((evt) =>
      evt.addEventListener('keyup', (e) => {
        if (e && e.target && e.target.parentElement && e.target.parentElement.nextElementSibling) {
          e.target.parentElement.nextElementSibling.style.display = 'block';
        }
      })
    );
  }

  passwordCustomElm &&
    passwordCustomElm.forEach((element) =>
      element.addEventListener('keyup', (e) => {
        const passwordContainer =
          e && e.target && e.target.parentElement && e.target.parentElement.parentElement;
        if (passwordContainer) {
          const prevInputVal = e && e.target && e.target.value;
          if (e.target.value.length === 0) {
            const passwordElmLiN =
              passwordContainer.querySelector('.errorlist') &&
              passwordContainer.querySelector('.errorlist').getElementsByTagName('li');
            const arrayList = Array.from(passwordElmLiN);
            arrayList.forEach(function (eml) {
              eml.removeAttribute('style');
              eml.classList.remove('active');
              if (eml.querySelector('span.screenreader')) {
                eml.querySelector('span.screenreader').remove();
              }
              if (e && e.target) {
                e.target.classList.remove('password-notvalid-now');
                e.target.classList.remove('password-valid-now');
                if (
                  e.target.parentElement &&
                  e.target.parentElement.querySelector('.custom-newpassword-error')
                ) {
                  e.target.parentElement.querySelector('.custom-newpassword-error').innerHTML = '';
                }
              }
            });
            return;
          }

          let regex = ['[a-z]', '[A-Z]', '[0-9]', '[$@$!%*#?&()+,-.:;<=>^_`{|}~\'\\]\\/^[^" ]'];
          let passed = 0;

          for (let i = 0; i < regex.length; i++) {
            const passElmLi = passwordContainer
              .querySelector('.errorlist')
              .getElementsByTagName('li');
            const nodeLt = Array.from(passElmLi);
            const digiCount = passwordContainer.querySelector('._eightchar_');
            if (new RegExp(regex[i]).test(prevInputVal)) {
              if (i === 0) {
                nodeLt && nodeLt[0].classList.add('active');
                if (lowercount === 1) {
                  const elmLowerCount = passwordContainer.querySelector('._lower_');
                  const elmLowerDom = `<span class="screenreader">${i18.t(
                    'PasswordMetLowercaseCharacterCriteria'
                  )}</span>`;
                  lowercount++;
                  if (nodeLt[0] && nodeLt[0].querySelector('span.screenreader')) {
                    nodeLt[0].querySelector('span.screenreader').remove();
                  }
                  elmLowerCount && elmLowerCount.insertAdjacentHTML('beforeend', elmLowerDom);
                }
              }
              if (i === 1) {
                nodeLt && nodeLt[1].classList.add('active');
                if (uppercount === 1) {
                  const elmUpperCount = passwordContainer.querySelector('._upper_');
                  const elmUpperDom = `<span class="screenreader">${i18.t(
                    'PasswordMetUppercaseCharacterCriteria'
                  )}</span>`;
                  uppercount++;
                  if (nodeLt[1] && nodeLt[1].querySelector('span.screenreader')) {
                    nodeLt[1].querySelector('span.screenreader').remove();
                  }
                  elmUpperCount && elmUpperCount.insertAdjacentHTML('beforeend', elmUpperDom);
                }
              }
              if (i === 2) {
                nodeLt && nodeLt[2].classList.add('active');
                if (uppercount === 2) {
                  const elmNumberCount = passwordContainer.querySelector('._number_');
                  const elmNumberDom = `<span class="screenreader">${i18.t(
                    'PasswordMetNumericCharacterCriteria'
                  )}</span>`;
                  uppercount++;
                  if (nodeLt[2] && nodeLt[2].querySelector('span.screenreader')) {
                    nodeLt[2].querySelector('span.screenreader').remove();
                  }
                  elmNumberCount && elmNumberCount.insertAdjacentHTML('beforeend', elmNumberDom);
                }
              }
              if (i === 3) {
                nodeLt && nodeLt[3].classList.add('active');
                if (uppercount === 3) {
                  const elmSpecialCount = passwordContainer.querySelector('._special_');
                  const elmSpecialDom = `<span class="screenreader">${i18.t(
                    'PasswordMetSpecialCharacterCriteria'
                  )}</span>`;
                  uppercount++;
                  if (nodeLt[3] && nodeLt[3].querySelector('span.screenreader')) {
                    nodeLt[3].querySelector('span.screenreader').remove();
                  }
                  elmSpecialCount && elmSpecialCount.insertAdjacentHTML('beforeend', elmSpecialDom);
                }
              }
              if (prevInputVal.length >= 8 && digiCount) {
                digiCount.classList && digiCount.classList.add('active');
                if (minlengthcount === 1) {
                  const elmEightcharCount = passwordContainer.querySelector('._eightchar_');
                  const elmEightcharDom = `<span class="screenreader">${i18.t(
                    'PasswordMetMinimumCharacterCriteria'
                  )}</span>`;
                  minlengthcount++;
                  digiCount.querySelector('span.screenreader') &&
                    digiCount.querySelector('span.screenreader').remove();
                  elmEightcharCount &&
                    elmEightcharCount.insertAdjacentHTML('beforeend', elmEightcharDom);
                }
              } else {
                const minimumCharDom = `${i18.t(
                  'PasswordDoesNotMeetTheMinimumCharacterCriteria'
                )}</span>`;
                const minimumScreenR = digiCount.querySelector('span.screenreader');
                minlengthcount = 1;
                digiCount && digiCount.classList.remove('active');
                minimumScreenR && minimumScreenR.insertAdjacentHTML('beforeend', minimumCharDom);
              }
              passed++;
            } else {
              nodeLt[i] && nodeLt[i].classList && nodeLt[i].classList.remove('active');
              nodeLt[i] &&
                nodeLt[i].querySelector('span.screenreader') &&
                nodeLt[i].querySelector('span.screenreader').remove();
              /*To reset the screenreader value */

              if (i === 0) {
                if (lowercount > 1) {
                  if (nodeLt[0]) {
                    const lowerCharElm = `<span class='screenreader'>${i18.t(
                      'PasswordDoesNotMeetTheLowercaseCharacterCriteria'
                    )}</span>`;
                    nodeLt[0].insertAdjacentHTML('beforeend', lowerCharElm);
                    lowercount = 1;
                  }
                }
              }
              if (i === 1) {
                if (lowercount > 1) {
                  if (nodeLt[1]) {
                    const upperCharElm = `<span class='screenreader'>${i18.t(
                      'PasswordDoesNotMeetTheUppercaseCharacterCriteria'
                    )}</span>`;
                    nodeLt[1].insertAdjacentHTML('beforeend', upperCharElm);
                    lowercount = 1;
                  }
                }
              }
              if (i === 2) {
                if (lowercount > 1) {
                  if (nodeLt[2]) {
                    const numericCharElm = `<span class='screenreader'>${i18.t(
                      'PasswordDoesNotMeetTheNumericCharacterCriteria'
                    )}</span>`;
                    nodeLt[2].insertAdjacentHTML('beforeend', numericCharElm);
                    lowercount = 1;
                  }
                }
              }
              if (i === 3) {
                if (lowercount > 1) {
                  if (nodeLt[3]) {
                    const specialCharElm = `<span class='screenreader'>${i18.t(
                      'PasswordDoesNotMeetTheSpecialCharacterCriteria'
                    )}</span>`;
                    nodeLt[3].insertAdjacentHTML('beforeend', specialCharElm);
                    lowercount = 1;
                  }
                }
              }
              if (e.target.value.length < 8) {
                const charcCount = passwordContainer.querySelector('._eightchar_');
                if (charcCount) {
                  charcCount.style.color = '#2a2a2a';
                }
              }
            }
          }
          //Validate for length of Password.
          if (passed > 2 && prevInputVal && prevInputVal.length >= 8) {
            passed++;
          }

          //Display status.
          let color = '';
          let strength = '';
          let widthpercent = '';
          switch (passed) {
            case 0:
            case 1:
            case 2:
              strength = `${i18.t('Weak')}`;
              color = '#c70101';
              widthpercent = '25%';
              updateDomAndMessage(e.target);
              break;
            case 3:
            case 4:
              strength = `${i18.t('Good')}`;
              color = 'darkorange';
              widthpercent = '70%';
              updateDomAndMessage(e.target);
              break;
            case 5:
              strength = `${i18.t('Strong')}`;
              color = '#458500';
              widthpercent = '100%';
              updatedSucessMessage(e.target);
              break;
            default:
              strength = '';
          }

          const passwordElmLi = passwordContainer.querySelector(
            '.errorlist_wrapper .strength-info'
          );
          const passwordMessElm = passwordContainer.querySelector(
            '.errorlist_wrapper .message-content'
          );
          const calPercentageElm = passwordContainer.querySelector(
            '.errorlist_wrapper .calculated-percentage'
          );

          if (passwordElmLi) {
            passwordElmLi.style.display = 'block';
          }
          if (passwordMessElm) {
            passwordMessElm.innerHTML = `<b> ${i18.t('PasswordStrength')}</b>` + strength;
          }
          if (calPercentageElm) {
            calPercentageElm.style.cssText = `width:${widthpercent}; background-color:${color}`;
          }
        }
      })
    );

  hideAndShowPassword();
};

const updateDomAndMessage = (eml) => {
  if (eml && eml.target) {
    eml.target.classList && eml.target.classList.remove('password-valid-now');
    eml.target.classList && eml.target.classList.add('password-not', 'valid-now');
    let parentElm = eml.target.parentElement;
    if (parentElm.querySelector('.successmessage')) {
      parentElm.querySelector('.successmessage').textContent = '';
    }

    let isnewpassword = eml && eml.target && eml.target.getAttribute('id');
    if (isnewpassword === 'newPassword') {
      let passwordElm = eml && eml.target && eml.target.parentElement;
      if (
        (passwordElm && passwordElm.querySelector('.changepasswordErrorMsg')) ||
        passwordElm.querySelector('.custom-newpassword-error')
      ) {
        passwordElm.querySelector('.changepasswordErrorMsg').textContent = '';
        passwordElm.querySelector('.custom-newpassword-error').innerHTML = `<b>${i18.t(
          'InvalidPassword'
        )}</b>${i18.t('PleaseMeetTheRequirementsBelow')}`;
      }
    }
  }
};

const updatedSucessMessage = (iLM) => {
  if (iLM) {
    iLM.classList && iLM.classList.remove('password-notvalid-now');
    iLM.classList && iLM.classList.add('password-valid-now');
    if (
      iLM &&
      iLM.nextElementSibling &&
      iLM.nextElementSibling.classList &&
      iLM.nextElementSibling.classList.contains('successmessage')
    ) {
      if (iLM.nextElementSibling) {
        iLM.nextElementSibling.textContent = `${i18.t('ThisPasswordIsSecure')}`;
      }
    }
  }
};

const hideAndShowPassword = () => {
  const showPasswordBtn = document.querySelectorAll('.hideshowpassword');
  showPasswordBtn &&
    showPasswordBtn.forEach((el) =>
      el.addEventListener('click', (e) => {
        let elm = e && e.target && e.target.nextElementSibling;
        if (elm) {
          let typePass = elm.getAttribute('type');
          if (typePass === 'text') {
            elm.setAttribute('type', 'password');
            elm.focus();
            e.target.textContent = `${i18.t('ShowPassword')}`;
          } else if (typePass === 'password') {
            elm.setAttribute('type', 'text');
            elm.focus();
            e.target.textContent = `${i18.t('HidePassword')}`;
          }
        }
      })
    );
};

const focusOnError = () => {
  const formElmArr = [
    '#gigya-login-form .gigya-input-submit',
    '#gigya-register-form .gigya-input-submit',
    '.gigya-profile-form .gigya-input-submit',
    '.gigya-reset-password-form .gigya-input-submit',
    '#register-site-login .gigya-input-submit',
  ];
  formElmArr.forEach((el, i) =>
    document.querySelector(el).addEventListener('click', (e) => {
      setTimeout(() => {
        const errorField = document.querySelectorAll('.gigya-error');
        errorField && errorField[0] && errorField[0].focus();
      }, 2000);
    })
  );
};

const changePasswordCustomMessage = () => {
  customPasswordValidation();
  onLoadCloseAttr();
  const closeElm = document.querySelector('.gigya-screen-dialog-close');
  const dialogElm = document.querySelector('.gigya-screen-dialog');
  const loaderConElm = document.querySelector('.loader-container');
  loaderConElm && loaderConElm.remove();
  closeElm && closeElm.setAttribute('aria-label');
  closeElm && closeElm.querySelector('button').focus();
  dialogElm && dialogElm.setAttribute('aria-hidden', 'true');
};

export const restPasswordCustomMessage = (eventObj) => {
  let resetPasshitElm = document.querySelector('.resetpasswordhit');
  if (eventObj.currentScreen === 'gigya-reset-password-screen') {
    resetPasshitElm &&
      resetPasshitElm.addEventListener('click', () => {
        resetPassword();
      });
  }
  customPasswordValidation();
};

export const getfunction = (funName) => {
  switch (funName) {
    case 'showLogin();':
      showLogin();
      break;
    case 'showRegister();':
      triggerSignUp();
      break;
    case 'showEditProfile();':
      editProfile();
      break;
    case 'submitPreferenceSettings()':
      submitPreferenceSettings();
      break;
    default:
      break;
  }
};

export const gigyaLoginGetDictionary = () => {
  let count = 0;
  let setVarInt = setInterval(function () {
    let widgetLogin = document.querySelector(
        '#gigya-login-form .gigya-login-providers .gigya-login-providers-list'
      ),
      widgetRegister = document.querySelector(
        '#register-social-login .gigya-login-providers .gigya-login-providers-list'
      ),
      widgetAccount = document.querySelector(
        '#gigya-link-accounts-form .gigya-login-providers .gigya-login-providers-list'
      ),
      widgetReauth = document.querySelector(
        '#gigya-reauthentication-form .gigya-login-providers .gigya-login-providers-list'
      );

    if (widgetLogin) {
      if (widgetLogin.querySelector("[data-gigya-provider='facebook'] button.tabbing-button")) {
        widgetLogin.querySelector(
          "[data-gigya-provider='facebook'] button.tabbing-button"
        ).innerText = i18.t('LoginwithFacebook');
      }

      if (widgetLogin.querySelector("[data-gigya-provider='googleplus'] button.tabbing-button")) {
        widgetLogin.querySelector(
          "[data-gigya-provider='googleplus'] button.tabbing-button"
        ).innerText = i18.t('LoginwithGoogle');
      }

      if (widgetLogin.querySelector("[data-gigya-provider='sina'] button.tabbing-button")) {
        widgetLogin.querySelector(
          "[data-gigya-provider='sina'] button.tabbing-button"
        ).innerText = i18.t('LoginwithSinaWeibo');
      }

      if (widgetLogin.querySelector("[data-gigya-provider='wechat'] button.tabbing-button")) {
        widgetLogin.querySelector(
          "[data-gigya-provider='wechat'] button.tabbing-button"
        ).innerText = i18.t('LoginwithWechat');
      }

      if (widgetLogin.querySelector("[data-gigya-provider='apple'] button.tabbing-button")) {
        widgetLogin.querySelector("[data-gigya-provider='apple'] button.tabbing-button").innerHTML =
          '<span><i class="icon-apple"></i>' + i18.t('LoginwithApple') + '</span>';
      }
    }

    if (widgetRegister) {
      if (widgetRegister.querySelector("[data-gigya-provider='facebook'] button.tabbing-button")) {
        widgetRegister.querySelector(
          "[data-gigya-provider='facebook'] button.tabbing-button"
        ).innerText = i18.t('SignUpwithFacebook');
      }

      if (
        widgetRegister.querySelector("[data-gigya-provider='googleplus'] button.tabbing-button")
      ) {
        widgetRegister.querySelector(
          "[data-gigya-provider='googleplus'] button.tabbing-button"
        ).innerText = i18.t('SignUpwithGoogle');
      }

      if (widgetRegister.querySelector("[data-gigya-provider='sina'] button.tabbing-button")) {
        widgetRegister.querySelector(
          "[data-gigya-provider='sina'] button.tabbing-button"
        ).innerText = i18.t('SignUpwithSinaWeibo');
      }

      if (widgetRegister.querySelector("[data-gigya-provider='wechat'] button.tabbing-button")) {
        widgetRegister.querySelector(
          "[data-gigya-provider='wechat'] button.tabbing-button"
        ).innerText = i18.t('SignUpwithWechat');
      }

      if (widgetRegister.querySelector("[data-gigya-provider='apple'] button.tabbing-button")) {
        widgetRegister.querySelector(
          "[data-gigya-provider='apple'] button.tabbing-button"
        ).innerHTML = '<span><i class="icon-apple"></i>' + i18.t('SignUpwithApple') + '</span>';
      }
    }

    if (widgetAccount) {
      if (widgetAccount.querySelector("[data-gigya-provider='facebook'] button.tabbing-button")) {
        widgetAccount.querySelector(
          "[data-gigya-provider='facebook'] button.tabbing-button"
        ).innerText = i18.t('LoginwithFacebook');
      }

      if (widgetAccount.querySelector("[data-gigya-provider='googleplus'] button.tabbing-button")) {
        widgetAccount.querySelector(
          "[data-gigya-provider='googleplus'] button.tabbing-button"
        ).innerText = i18.t('LoginwithGoogle');
      }

      if (widgetAccount.querySelector("[data-gigya-provider='sina'] button.tabbing-button")) {
        widgetAccount.querySelector(
          "[data-gigya-provider='sina'] button.tabbing-button"
        ).innerText = i18.t('LoginwithSinaWeibo');
      }

      if (widgetAccount.querySelector("[data-gigya-provider='wechat'] button.tabbing-button")) {
        widgetAccount.querySelector(
          "[data-gigya-provider='wechat'] button.tabbing-button"
        ).innerText = i18.t('LoginwithWechat');
      }

      if (widgetAccount.querySelector("[data-gigya-provider='apple'] button.tabbing-button")) {
        widgetAccount.querySelector(
          "[data-gigya-provider='apple'] button.tabbing-button"
        ).innerHTML = '<span><i class="icon-apple"></i>' + i18.t('LoginwithApple') + '</span>';
      }
    }

    if (widgetReauth) {
      if (widgetReauth.querySelector("[data-gigya-provider='facebook'] button.tabbing-button")) {
        widgetReauth.querySelector(
          "[data-gigya-provider='facebook'] button.tabbing-button"
        ).innerText = i18.t('LoginwithFacebook');
      }

      if (widgetReauth.querySelector("[data-gigya-provider='googleplus'] button.tabbing-button")) {
        widgetReauth.querySelector(
          "[data-gigya-provider='googleplus'] button.tabbing-button"
        ).innerText = i18.t('LoginwithGoogle');
      }

      if (widgetReauth.querySelector("[data-gigya-provider='sina'] button.tabbing-button")) {
        widgetReauth.querySelector(
          "[data-gigya-provider='sina'] button.tabbing-button"
        ).innerText = i18.t('LoginwithSinaWeibo');
      }

      if (widgetReauth.querySelector("[data-gigya-provider='wechat'] button.tabbing-button")) {
        widgetReauth.querySelector(
          "[data-gigya-provider='wechat'] button.tabbing-button"
        ).innerText = i18.t('LoginwithWechat');
      }

      if (widgetReauth.querySelector("[data-gigya-provider='apple'] button.tabbing-button")) {
        widgetReauth.querySelector(
          "[data-gigya-provider='apple'] button.tabbing-button"
        ).innerHTML = '<span><i class="icon-apple"></i>' + i18.t('LoginwithApple') + '</span>';
      }
    }

    count += 1;
    if (count > 10) {
      clearInterval(setVarInt);
    }
  }, 1000);
};

export const redirectToSignIn = () => {
  if (MU && MU.isLoginRequire && readCookie(`${MU.isUserLoggedIn && MU.isUserLoggedIn}`) == null) {
    if (typeof window !== 'undefined') {
      window.location = xss(
        window.location.origin + '/' + getLanguage() + '/SignIn?ret=' + window.location.href
      );
    }
  }
};

const onLoadCloseAttr = () => {
  let getCloseElm;
  getCloseElm = setInterval(() => {
    let gigyaCloseAElm = document.querySelector('.gigya-screen-dialog-close');
    let closeTabOrder = document.querySelector('.gigya-screen-dialog-close a');
    let dialogCaptionTabOrder = document.querySelector('.gigya-screen-dialog-caption');
    if (gigyaCloseAElm) {
      gigyaCloseAElm.querySelector('a').setAttribute('aria-label', i18.t('close'));
      gigyaCloseAElm.querySelector('a').focus();
      gigyaCloseAElm.querySelector('a').classList.add('focus-visible', 'non-keyboard-outline');

      closeTabOrder.setAttribute('data-natural-tab-order', '0');

      dialogCaptionTabOrder.setAttribute('data-natural-tab-order', '1');

      clearInterval(getCloseElm);
    }
  }, 100);
};

export const socialProviderAccessible = () => {
  setTimeout(() => {
    let loginFbElm = document.querySelector(
      "#gigya-login-form [data-gigya-provider='facebook'] .tabbing-button"
    );
    let loginGoogleElm = document.querySelector(
      "#gigya-login-form [data-gigya-provider='googleplus'] .tabbing-button"
    );
    let loginAppleElm = document.querySelector(
      "#gigya-login-form [data-gigya-provider='apple'] .tabbing-button"
    );
    let loginSinaElm = document.querySelector(
      "#gigya-login-form [data-gigya-provider='sina'] .tabbing-button"
    );

    let signUpFbElm = document.querySelector(
      "#gigya-register-form [data-gigya-provider='facebook'] .tabbing-button"
    );

    let signUpGoogleElm = document.querySelector(
      "#gigya-register-form [data-gigya-provider='googleplus'] .tabbing-button"
    );

    let signUpAppleElm = document.querySelector(
      "#gigya-register-form [data-gigya-provider='apple'] .tabbing-button"
    );

    let signUpSinaElm = document.querySelector(
      "#gigya-register-form [data-gigya-provider='sina'] .tabbing-button"
    );

    let accountLinkFbElm = document.querySelector(
      "#gigya-link-accounts-form [data-gigya-provider='facebook'] .tabbing-button"
    );

    let accountLinkGoogleElm = document.querySelector(
      "#gigya-link-accounts-form [data-gigya-provider='googleplus'] .tabbing-button"
    );

    let accountLinkAppleElm = document.querySelector(
      "#gigya-link-accounts-form [data-gigya-provider='apple'] .tabbing-button"
    );

    let socialContainerElm = document.querySelector('.gigya-social-login-container');

    if (loginFbElm) {
      loginFbElm.setAttribute('aria-label', i18.t('LoginwithFacebook'));
      loginFbElm.removeAttribute('tabindex', '-1');
    }

    if (loginGoogleElm) {
      loginGoogleElm.setAttribute('aria-label', i18.t('LoginwithGoogle'));
      loginGoogleElm.removeAttribute('tabindex', '-1');
    }

    if (loginAppleElm) {
      loginAppleElm.setAttribute('aria-label', i18.t('LoginwithApple'));
      loginAppleElm.removeAttribute('tabindex', '-1');
    }

    if (loginSinaElm) {
      loginSinaElm.setAttribute('aria-label', i18.t('LoginwithSinaWeibo'));
      loginSinaElm.removeAttribute('tabindex', '-1');
    }

    if (signUpFbElm) {
      signUpFbElm.setAttribute('aria-label', i18.t('SignUpwithFacebook'));
      signUpFbElm.removeAttribute('tabindex', '-1');
    }

    if (signUpGoogleElm) {
      signUpGoogleElm.setAttribute('aria-label', i18.t('SignUpwithGoogle'));
      signUpGoogleElm.removeAttribute('tabindex', '-1');
    }

    if (signUpAppleElm) {
      signUpAppleElm.setAttribute('aria-label', i18.t('SignUpwithApple'));
      signUpAppleElm.removeAttribute('tabindex', '-1');
    }

    if (signUpSinaElm) {
      signUpSinaElm.setAttribute('aria-label', i18.t('SignUpwithSinaWeibo'));
      signUpSinaElm.removeAttribute('tabindex', '-1');
    }

    if (accountLinkFbElm) {
      accountLinkFbElm.setAttribute('aria-label', i18.t('LoginwithFacebook'));
      accountLinkFbElm.setAttribute('tabindex', '0');
    }

    if (accountLinkGoogleElm) {
      accountLinkGoogleElm.setAttribute('aria-label', i18.t('LoginwithGoogle'));
      accountLinkGoogleElm.setAttribute('tabindex', '0');
    }

    if (accountLinkAppleElm) {
      accountLinkAppleElm.setAttribute('aria-label', i18.t('LoginwithApple'));
      accountLinkAppleElm.setAttribute('tabindex', '0');
    }

    if (socialContainerElm) {
      socialContainerElm.querySelector('#social-buttons-login-caption') &&
        socialContainerElm
          .querySelector('#social-buttons-login-caption')
          .removeAttribute('aria-label');
    }
  }, 3000);
};

const checkCookie = (src) => {
  let cookieUrl;
  let userConsent = getWayinCookie('userConsentCookiePolicy');
  if (userConsent !== '') {
    if (userConsent === 'on') {
      cookieUrl = src + '&Consent=True';
    } else {
      cookieUrl = src + '&Consent=False';
    }
  } else {
    let isCurrentGeoinConsentList = MU && MU.isCurrentGeoinConsentList;
    userConsent = getWayinCookie('userConsentCookiePolicy');
    if (isCurrentGeoinConsentList) {
      if (userConsent === 'on') {
        cookieUrl = src + '&Consent=True';
      } else if (userConsent === 'off') {
        cookieUrl = src + '&Consent=False';
      }
    } else {
      cookieUrl = src + '&Consent=True';
    }
  }
  return cookieUrl;
};

export const addSubscription = ({ param, type, isNewUser }) => {
  const route = `${SUBSCRIBE_ENCRYPTION}?param=${param}`;
  api
    .get({ route })
    .then((response) => {
      const encryptedKey = response && response.data && response.data.encryptedData;
      if (encryptedKey && MU) {
        const url =
          type === 'token'
            ? `${MU.manageSubscriptionUrl}?token=${encryptedKey}`
            : `${MU.addSubscriptionUrl}?gigya_id=${encryptedKey}&return_url=${xss(
                window.location.href
              )}${isNewUser ? '&step=2' : ''}`;
        const cookieUrl = xss(checkCookie(url));
        window.location.href = cookieUrl;
      }
    })
    .catch((err) => {
      console.log('error in encryption');
    });
};

export const manageSubscription = () => {
  window.gigya &&
    window.gigya.accounts &&
    window.gigya.accounts.getJWT({
      callback: function (data) {
        addSubscription({ param: data.id_token, type: 'token' });
      },
    });
};

export const isSubscribedViaCrossPlatform = ({ userLoginDetail, ManageSubscription }) => {
  if (!ManageSubscription || !(ManageSubscription && ManageSubscription.length > 0)) {
    return null;
  }
  const PurchaseChannel =
    userLoginDetail &&
    userLoginDetail.data &&
    userLoginDetail.data.PurchaseChannel &&
    userLoginDetail.data.PurchaseChannel.toLowerCase();
  let manageIndividual;
  if (PurchaseChannel) {
    manageIndividual =
      PurchaseChannel !== 'web' &&
      ManageSubscription.find(
        (item) => item.channelName && item.channelName.toLowerCase() === PurchaseChannel
      );
  } else {
    manageIndividual = ManageSubscription.find((item) => item.channelName === '');
  }
  return manageIndividual ? manageIndividual : null;
};

const tooltipTraverseHandler = () => {
  try {
    if (typeof window !== 'undefined') {
      const popUpElm = document.querySelectorAll('.icon-info-icon');
      let targetElmF,
        tooltipAnchor = [];
      if (popUpElm && popUpElm.length > 0) {
        popUpElm.forEach((element, index) => {
          targetElmF = element && element.querySelector('.tooltip');
          tooltipAnchor[index] = targetElmF && targetElmF.querySelector('a');
          //ios-fix for foucs not getting on the tooltip
          if (element) {
            element.setAttribute('aria-label', 'true');
            //Make focus visislbe on TAB press in on tooltip box
            element.addEventListener('focus', (event) => {
              targetElmF = event.target && event.target.querySelector('.tooltip');
              if (tooltipAnchor[index] && targetElmF && targetElmF.style) {
                targetElmF.style.visibility = 'visible';
              }
            });
            // To make tooltip  disappear event get out of focus
            element.addEventListener('click', () => {
              if (targetElmF && targetElmF.style) {
                targetElmF.style.visibility = 'hidden';
              }
            });
            //To hanlde the backward navigation of the tooltip focus
            element.addEventListener('keydown', (event) => {
              let keycode = event.keyCode;
              event.preventDefault();
              event.stopPropagation();
              if (
                event &&
                targetElmF &&
                targetElmF.style &&
                keycode === Keyboard.TAB &&
                event.shiftKey
              ) {
                targetElmF.style.visibility = 'hidden';
                index - 1 >= 0 && popUpElm[index - 1].focus();
              }
            });
          }
        });
      }
      if (tooltipAnchor && tooltipAnchor.length > 0) {
        tooltipAnchor.forEach((element) => {
          //To get focus link in the tool tip
          element &&
            element.addEventListener('keydown', (event) => {
              event.preventDefault();
              event.stopPropagation();
              const parentElm = element.parentElement;
              if (
                event.keyCode === Keyboard.TAB &&
                !event.shiftKey &&
                parentElm &&
                parentElm.style
              ) {
                parentElm.style.visibility = 'hidden';
              }
            });
        });
      }
    }
  } catch (e) {
    console.log('Error', e);
  }
};
