/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import { Placeholder, Text, isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import React, { useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';
import Slider from 'react-slick';
import {
  HomePageHeroCarouselVarientDark,
  HomePageHeroCarouselVarientLight,
  SUPPORTED_STATS_CONTENT_TYPE,
  THRESHOLD,
} from '../../../constants/globalConstants';
import {
  afterChangeAccessbility,
  beforeChangeAccessbility,
  NextArrow,
  onInitAccessibility,
  PrevArrow,
  slideShowCount,
} from '../../../utils/carouselUtils';
import ImgCrop from '../../Common/ImgCrop';
import MU from '../../../constants/muConstants';
import Transform from '../../../data-transform';
import { THEMED_CAROUSEL } from '../../../constants/containerConstants';
import ThemedCarouselAutomation from '../ThemedCarouselAutomation';
import {
  checkDevice,
  getRandomId,
  getSponsorData,
  getActiveModalCount,
} from '../../../utils/utils';
import Sponsor from '../../Common/Sponsor';
import { CONTENT_TYPE } from '../../../constants/globalConstants';
import * as track from '../../../utils/analytics';
import Stats from '../../Stats';
import ErrorBoundary from '../../Common/ErrorBoundary';
const areEqual = (prevProps, nextProps) => true;
const ThemedCarousel = React.memo((props) => {
  const [slicker, setSlicker] = useState({});
  const [isTouchDevice, setIsTouchDevice] = useState(true);
  const [isExpEditor, setIsExpEditor] = useState(false);
  const [screenReaderText, setScreenReaderText] = useState('');
  const [uniqueId, setUniqueId] = useState(getRandomId());
  const refElement = useRef(null);
  const ctaUrl = null;
  let timeoutId;
  useEffect(() => {
    setIsExpEditor(isExperienceEditorActive());
    setIsTouchDevice(checkDevice());
  }, []);
  useEffect(() => {
    const touchDevice = checkDevice();
    if (!touchDevice) {
      window.addEventListener('resize', resizeListener);
    }
    return () => {
      !touchDevice && window.removeEventListener('resize', resizeListener);
    };
  }, []);
  const resizeListener = () => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      const modalCount = getActiveModalCount();
      setIsTouchDevice(checkDevice());
      modalCount === 0 && setUniqueId(getRandomId());
    }, THRESHOLD);
  };
  const slidesCount = () => {
    let count = 0;
    if (props.fields && props.fields.SelectCards && props.fields.SelectCards.length > 0) {
      count = props.fields.SelectCards.length;
    } else if (
      props.fields &&
      props.fields.carousel &&
      props.fields.carousel.value &&
      props.fields.carousel.value.documentsFromSolr &&
      props.fields.carousel.value.documentsFromSolr.response &&
      props.fields.carousel.value.documentsFromSolr.response.docs &&
      props.fields.carousel.value.documentsFromSolr.response.docs &&
      props.fields.carousel.value.documentsFromSolr.response.docs.length > 0
    ) {
      count = props.fields.carousel.value.documentsFromSolr.response.docs.length + 1;
    } else if (
      props.rendering &&
      props.rendering.placeholders &&
      props.rendering.placeholders['mufc-ph-carouselslider'] &&
      props.rendering.placeholders['mufc-ph-carouselslider'].length > 0
    ) {
      count = props.rendering.placeholders['mufc-ph-carouselslider'].length;
    }
    return count;
  };
  const trackLink = (ctaTitle, linkUrl, e) => {
    const initialData = track.data('link');
    track.analytics(
      {
        ...initialData,
        button_name: ctaTitle,
        destination_url: linkUrl,
      },
      e
    );
  };
  const varientClass = () => {
    if (
      props.fields &&
      props.fields.Skin &&
      props.fields.Skin.value &&
      props.fields.Skin.value === HomePageHeroCarouselVarientLight
    ) {
      return `carousal-${HomePageHeroCarouselVarientLight.toLowerCase()}`;
    } else {
      return `carousal-${HomePageHeroCarouselVarientDark.toLowerCase()}`;
    }
  };
  const bgnCTA = () => {
    if (
      props.fields &&
      props.fields.Image &&
      props.fields.Image.value &&
      (!props.fields.CTATitle || !props.fields.CTATitle.value) &&
      !ctaUrl
    ) {
      return 'bg-ncta';
    } else {
      return '';
    }
  };
  const isCurated = () => {
    if (isExpEditor && props.fields && (!props.fields.IsAuto || !props.fields.IsAuto.value)) {
      return 'curated';
    } else {
      return '';
    }
  };
  const firstcardCss = () => {
    if (
      props.fields &&
      props.fields.Image &&
      props.fields.Image.value &&
      props.fields.CTATitle &&
      props.fields.CTATitle.value &&
      ctaUrl
    ) {
      return 'first-slide';
    } else {
      return '';
    }
  };
  const ctaClass = () => {
    if (!ctaUrl) {
      return '';
    } else {
      return 'bg-cta';
    }
  };
  const renderExpEditior = () => {
    if (
      props.fields &&
      props.fields.CurateFromSelectCardsField &&
      props.fields.CurateFromSelectCardsField.value
    ) {
      return (
        <div className="carousal__info">
          <b>
            Slider CurationMode : Cards will appear from SelectCards field <br />
          </b>
        </div>
      );
    } else {
      return <div className="carousal__info">Slider CurationMode : Add Slider Components</div>;
    }
  };
  const mobileHeading = () => {
    // if (props.fields && props.fields.Image && props.fields.Image.value) {
    return 'bg-image';
    // } else {
    // return '';
    // }
  };
  const carousalDestination = (props, className) => {
    return (
      <React.Fragment>
        {props.fields.IsMore && props.fields.IsMore.value && (
          <a
            href={
              props.fields.RedirectUrl &&
              props.fields.RedirectUrl.value &&
              props.fields.RedirectUrl.value.hrefMuCustom
            }
            className={`carousal-destination ${className ? className : ''}`}
            tabIndex="0"
            onClick={(e) =>
              trackLink(
                props.fields.DisplayMoreTitle.value,
                props.fields.RedirectUrl.value.hrefMuCustom,
                e
              )
            }
          >
            <span aria-hidden="true">
              {props.fields.DisplayMoreTitle && <Text field={props.fields.DisplayMoreTitle} />}
            </span>
            <span className="screenreader">{props.t('ClickToSeeMoreContent')}</span>
          </a>
        )}
      </React.Fragment>
    );
  };
  const renderTitle = () => {
    if (ctaUrl && props.fields && props.fields.CTATitle && props.fields.CTATitle.value) {
      return (
        <div className="infowrapper">
          <div className="carousal-width-new">
            <div className="carousal-head">
              <div className="carousal__info">
                <div className="carousal_title_link">
                  <h2 className="carousal__title">
                    {props.fields.Title && (
                      <span>
                        <Text field={props.fields.Title} />
                      </span>
                    )}
                    {carousalDestination(props, 'desktopvisible')}
                    <div className="carousal-destination">
                      <div className="link-btn-white">
                        <a
                          href={ctaUrl}
                          tabIndex="0"
                          onClick={(e) => trackLink(props.fields.CTATitle.value, ctaUrl, e)}
                        >
                          <span>{<Text field={props.fields.CTATitle} />}</span>
                          <i className="icon-follow-live"></i>
                          <span className="screenreader">{props.t('ClickToSeeMoreContent')}</span>
                        </a>
                      </div>
                    </div>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="carousal-width-new">
          <div className="carousal-head">
            <div className="carousal__info">
              <div className="carousal_title_link all">
                <h2 className="carousal__title">
                  {props.fields && props.fields.Title && (
                    <span>
                      <Text field={props.fields.Title} />
                    </span>
                  )}
                  {carousalDestination(props, 'desktopvisible')}
                </h2>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };
  const nextPrevArrow = (oldIndex, newIndex, slicker, bypass) => {
    setTimeout(() => {
      if (oldIndex < newIndex || bypass) {
        slicker && slicker.prevArrow && slicker.prevArrow.classList.remove('clicked');
        slicker && slicker.nextArrow && slicker.nextArrow.classList.add('clicked');
      }
      if (oldIndex > newIndex) {
        slicker && slicker.prevArrow && slicker.prevArrow.classList.add('clicked');
        slicker && slicker.nextArrow && slicker.nextArrow.classList.remove('clicked');
      }
    }, 100);
  };
  const settings = () => {
    return {
      className: 'carousal-list center',
      lazyLoad: 'progressive',
      variableWidth: true,
      infinite: false,
      speed: 400,
      swipe: true,
      accessibility: false,
      slidesToShow: slideShowCount(),
      dots: true,
      slidesToScroll: slideShowCount(),
      draggable: false,
      // swipeToSlide: true,
      touchMove: true,
      mobileFirst: true,
      touchThreshold: 20,
      initialSlide: 0,
      rtl: false,
      arabic: MU.language === 'ar' ? true : false,
      prevArrow: <PrevArrow />,
      nextArrow: <NextArrow slideShow={slideShowCount()} />,
      appendDots: (dots) => {
        if (dots && dots.length) {
          let isActive = true;
          let list = dots.map((val, index) => {
            isActive = isActive ? val.props.className !== 'slick-active' : false;
            return (
              <li key={index} className={`${val.props.className} ${isActive ? 'active' : ''}`}>
                <i></i>
              </li>
            );
          });
          return (
            <div className="slick-dots">
              <ul tabIndex="-1" aria-hidden="true">
                {list}
              </ul>
            </div>
          );
        }
      },
      onLazyLoad: (e) => {
        if (e.includes(slidesCount() - 1))
          setTimeout(() => {
            if (refElement) {
              const slider = refElement.current;
              let list = slider && slider.querySelector('.slick-list');
              let slideTrack = list && list.querySelector('.slick-track');
              let slides = slideTrack && slideTrack.querySelectorAll('.slick-slide');
              let nextArrow = slider && slider.querySelector('.slider-next.slick-arrow');
              let prevArrow = slider && slider.querySelector('.slider-prev.slick-arrow');
              onInitAccessibility(slideTrack, slides, 'themed');
              setSlicker({
                slider: slider,
                list: list,
                track: slideTrack,
                slides: slides,
                nextArrow: nextArrow,
                prevArrow: prevArrow,
              });
            }
          }, 500);
      },
      beforeChange: (oldIndex, newIndex) => {
        setScreenReaderValue(newIndex);
        nextPrevArrow(oldIndex, newIndex, slicker);
        beforeChangeAccessbility(slicker, 'themed');
      },
      afterChange: () => {
        afterChangeAccessbility(slicker);
      },
    };
  };
  const setScreenReaderValue = (index) => {
    const a = index;
    const b = slideShowCount();
    const c = slidesCount();
    if (a + b <= c) {
      setScreenReaderText(`Displayed ${a + 1} of ${a + b} out of ${c}`);
    }
    setTimeout(() => {
      setScreenReaderText('');
    }, 300);
  };
  const renderCarousel = () => {
    // Have to check condition
    if (
      props.fields &&
      props.fields.carousel &&
      props.fields.carousel.value &&
      props.fields.carousel.value.documentsFromSolr
    ) {
      return (
        <ThemedCarouselAutomation
          fields={props.fields}
          settings={settings()}
          itemId={props.rendering.dataSource}
          slidesCount={slidesCount}
        />
      );
    } else if (props.fields && props.fields.CurateFromSelectCardsField) {
      return (
        <div
          className={`carousal ${varientClass()} hero-carousal__sponsor background-content ${bgnCTA()} ${isCurated()}`}
        >
          {props.fields.Image && props.fields.Image.value && (
            <div className={firstcardCss()} tabIndex="-1">
              <div className={`carousal-cover__wrapper ${ctaClass()}`}>
                <LazyLoad once={true} offset={100}>
                  <ImgCrop
                    className={'adaptive carousal-cover'}
                    imgCrop={props.fields.Image.value.Crops}
                    alt={props.fields.Image.value.AltText}
                    title={props.fields.Image.value.AltText}
                  />
                </LazyLoad>
                <Sponsor
                  contentType="Hero Carousel"
                  varient=""
                  sponsorInfo={
                    props.fields.Sponsorby && getSponsorData(props.fields.Sponsorby.fields)
                  }
                  grid="grid-3"
                  type={CONTENT_TYPE}
                  container={THEMED_CAROUSEL}
                  itemId={
                    props.rendering && props.rendering.dataSource && props.rendering.dataSource
                  }
                ></Sponsor>
              </div>
            </div>
          )}
          {isExpEditor && renderExpEditior()}
          <div
            className={`container ${isTouchDevice ? 'custom-container' : ''} ${
              slidesCount() <= slideShowCount() ? 'adjust' : ''
            }`}
          >
            {renderTitle()}
            <div className="carousal-width">
              <div className="carousal_title_link all mobile">
                {props.fields.Title && (
                  <h2 className={`carousal__skin-title ${mobileHeading()}`}>
                    <span>
                      <Text field={props.fields.Title} />
                    </span>
                    {carousalDestination(props, 'mobilevisible')}
                  </h2>
                )}
                {!props.fields.Title && carousalDestination(props, 'mobilevisible')}
              </div>
              {renderCarouselSlides()}
            </div>
          </div>
        </div>
      );
    }
  };
  const renderCarouselSlides = () => {
    if (
      props.fields &&
      props.fields.CurateFromSelectCardsField &&
      !props.fields.CurateFromSelectCardsField.value
    ) {
      return (
        <React.Fragment>
          {!isTouchDevice && isCurated() !== 'curated' && (
            <Placeholder
              name="mufc-ph-carouselslider"
              rendering={props.rendering}
              missingComponentComponent={() => {
                return null;
              }}
              render={(components) => {
                return <Slider {...settings()}>{components}</Slider>;
              }}
              container={THEMED_CAROUSEL}
              gridClass="grid-3"
              isCarousel={true}
              nativeCard={true}
            />
          )}
          {(isTouchDevice || isCurated() === 'curated') && (
            <div className="carousal-wrapper">
              <div className={`carousal-list custom-mobile-slider`}>
                <Placeholder
                  name="mufc-ph-carouselslider"
                  rendering={props.rendering}
                  missingComponentComponent={() => {
                    return null;
                  }}
                  renderEach={(components, i) => {
                    return (
                      <div className="mobile-listitem" role="listitem" key={i}>
                        {components}
                      </div>
                    );
                  }}
                  container={THEMED_CAROUSEL}
                  gridClass="grid-3"
                  isCarousel={true}
                  nativeCard={true}
                />
              </div>
            </div>
          )}
        </React.Fragment>
      );
    } else if (
      props.fields &&
      props.fields.CurateFromSelectCardsField &&
      props.fields.CurateFromSelectCardsField.value &&
      props.rendering &&
      props.rendering.fields &&
      props.rendering.fields.SelectCards &&
      props.rendering.fields.SelectCards.length > 0
    ) {
      return (
        <React.Fragment>
          {!isTouchDevice && isCurated() !== 'curated' && (
            <Slider {...settings()}>
              {props.rendering.fields.SelectCards.map((item, i) => renderFromSelectCards(item, i))}
            </Slider>
          )}
          {(isTouchDevice || isCurated() === 'curated') && (
            <div className="carousal-wrapper">
              <div className={`carousal-list custom-mobile-slider`}>
                {props.rendering.fields.SelectCards.map((item, i) => (
                  <div key={i} className="mobile-listitem" role="listitem">
                    {renderFromSelectCards(item, i)}
                  </div>
                ))}
              </div>
            </div>
          )}
        </React.Fragment>
      );
    }
  };
  const renderFromSelectCards = (item, i) => {
    if (
      item &&
      item.fields &&
      item.fields.ContentType &&
      item.fields.ContentType.value &&
      SUPPORTED_STATS_CONTENT_TYPE.includes(item.fields.ContentType.value.toLowerCase())
    ) {
      return <Stats fields={item.fields} id={item.id} isAws={false} isCarousel={true} />;
    } else {
      return (
        <Transform
          key={i}
          data={item}
          source="curate"
          container={THEMED_CAROUSEL}
          gridClass="grid-3"
          isCarousel={true}
          nativeCard={true}
        />
      );
    }
  };
  return (
    <ErrorBoundary>
      <div className="home-row" ref={refElement} key={uniqueId}>
        {renderCarousel()}
        {!isTouchDevice && (
          <div className="screenreader" aria-live="assertive">
            {screenReaderText}
          </div>
        )}
      </div>
    </ErrorBoundary>
  );
}, areEqual);
export default withTranslation()(ThemedCarousel);
