/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useRef, useState } from 'react';
import { isExperienceEditorActive, Placeholder, Text } from '@sitecore-jss/sitecore-jss-react';
import LazyLoad from 'react-lazyload';
import ImgCrop from '../../Common/ImgCrop';
import Slider from 'react-slick';
import * as track from '../../../utils/analytics';
import { HomePageHeroCarouselVarientLight, THRESHOLD } from '../../../constants/globalConstants';
import { checkDevice, getRandomId } from '../../../utils/utils';
import { withTranslation } from 'react-i18next';
import {
  PrevArrow,
  NextArrow,
  afterChangeAccessbility,
  beforeChangeAccessbility,
  onInitAccessibility,
  slideShowCount,
} from '../../../utils/carouselUtils';
import MU from '../../../constants/muConstants';
import { SHOP_CAROUSEL } from '../../../constants/containerConstants';
import ErrorBoundary from '../../Common/ErrorBoundary';
const areEqual = (prevProps, nextProps) => true;
const ShopCarousel = React.memo((props) => {
  const { DisplayMoreTitle, Image, IsMore, RedirectUrl, Title } = props.fields;
  const [isTouchDevice, setIsTouchDevice] = useState(true);
  const [slicker, setSlicker] = useState({});
  const [screenReaderText, setScreenReaderText] = useState('');
  const [uniqueId, setUniqueId] = useState(getRandomId());
  const refElement = useRef(null);
  let timeoutId;
  useEffect(() => {
    setIsTouchDevice(checkDevice());
  }, []);
  useEffect(() => {
    const touchDevice = checkDevice();
    if (!touchDevice) {
      window.addEventListener('resize', resizeListener);
    }
    return () => {
      !touchDevice && window.removeEventListener('resize', resizeListener);
    };
  }, []);
  const resizeListener = () => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      setIsTouchDevice(checkDevice());
      setUniqueId(getRandomId());
    }, THRESHOLD);
  };
  const slideCount = () => {
    if (
      props.rendering &&
      props.rendering.placeholders &&
      props.rendering.placeholders['mufc-ph-shopcarousel'] &&
      props.rendering.placeholders['mufc-ph-shopcarousel'].length > 0
    ) {
      return props.rendering.placeholders['mufc-ph-shopcarousel'].length;
    } else {
      return 0;
    }
  };
  const nextPrevArrow = (oldIndex, newIndex, slicker, bypass) => {
    setTimeout(() => {
      if (oldIndex < newIndex || bypass) {
        slicker && slicker.prevArrow && slicker.prevArrow.classList.remove('clicked');
        slicker && slicker.nextArrow && slicker.nextArrow.classList.add('clicked');
      }
      if (oldIndex > newIndex) {
        slicker && slicker.prevArrow && slicker.prevArrow.classList.add('clicked');
        slicker && slicker.nextArrow && slicker.nextArrow.classList.remove('clicked');
      }
    }, 100);
  };
  const settings = () => {
    return {
      className: 'carousal-list center',
      lazyLoad: 'progressive',
      variableWidth: true,
      infinite: false,
      speed: 400,
      dots: true,
      slidesToScroll: slideShowCount(),
      swipe: true,
      accessibility: false,
      slidesToShow: slideShowCount(),
      draggable: false,
      // swipeToSlide: true,
      touchMove: true,
      mobileFirst: true,
      touchThreshold: 20,
      initialSlide: 0,
      rtl: false,
      arabic: MU.language === 'ar' ? true : false,
      prevArrow: <PrevArrow />,
      nextArrow: <NextArrow slideShow={slideShowCount()} />,
      appendDots: (dots) => {
        if (dots && dots.length) {
          let isActive = true;
          let list = dots.map((val, index) => {
            isActive = isActive ? val.props.className !== 'slick-active' : false;
            return (
              <li key={index} className={`${val.props.className} ${isActive ? 'active' : ''}`}>
                <i></i>
              </li>
            );
          });
          return (
            <div className="slick-dots">
              <ul tabIndex="-1" aria-hidden="true">
                {list}
              </ul>
            </div>
          );
        }
      },
      onLazyLoad: (e) => {
        if (e.includes(slideCount() - 1)) {
          setTimeout(() => {
            if (refElement) {
              const slider = refElement && refElement.current;
              let list = slider && slider.querySelector('.slick-list');
              let slideTrack = list && list.querySelector('.slick-track');
              let slides = slideTrack && slideTrack.querySelectorAll('.slick-slide');
              let nextArrow = slider && slider.querySelector('.slider-next.slick-arrow');
              let prevArrow = slider && slider.querySelector('.slider-prev.slick-arrow');
              onInitAccessibility(slideTrack, slides, 'shop');
              setSlicker({
                slider: slider,
                list: list,
                track: slideTrack,
                slides: slides,
                nextArrow: nextArrow,
                prevArrow: prevArrow,
              });
            }
          }, 500);
        }
      },
      beforeChange: (oldIndex, newIndex) => {
        setScreenReaderValue(newIndex);
        nextPrevArrow(oldIndex, newIndex, slicker);
        beforeChangeAccessbility(slicker, 'shop');
      },
      afterChange: () => {
        afterChangeAccessbility(slicker);
      },
    };
  };
  const setScreenReaderValue = (index) => {
    const a = index;
    const b = slideShowCount();
    const c = slideCount();
    if (a + b <= c) {
      setScreenReaderText(`Displayed ${a + 1} of ${a + b} out of ${c}`);
    }
    setTimeout(() => {
      setScreenReaderText('');
    }, 300);
  };
  const variantClass = `carousal-${HomePageHeroCarouselVarientLight.toLowerCase()}`;
  const trackLink = (ctaTitle, linkUrl, e) => {
    const initialData = track.data('link');
    track.analytics(
      {
        ...initialData,
        button_name: ctaTitle,
        destination_url: linkUrl,
      },
      e
    );
  };
  const carousalDestination = () => {
    return (
      <React.Fragment>
        {IsMore && IsMore.value && (
          <a
            href={RedirectUrl.value.hrefMuCustom}
            target="_blank"
            rel="noreferrer"
            className="carousal-destination"
            tabIndex="0"
            onClick={(e) => trackLink(DisplayMoreTitle.value, RedirectUrl.value.hrefMuCustom, e)}
          >
            <span aria-hidden="true">
              <Text field={DisplayMoreTitle} />
            </span>
            <span className="screenreader">{props.t('AllKitbagProducts')}</span>
          </a>
        )}
      </React.Fragment>
    );
  };
  return (
    <ErrorBoundary>
      <div className="home-row shop-carousal" key={uniqueId}>
        <div
          className={`carousal kitbag ${
            Image && Image.value ? 'background-content bg-ncta' : variantClass
          } curated`}
        >
          {Image && Image.value && (
            <div className="carousal-cover__wrapper">
              <LazyLoad once={true} offset={100}>
                <ImgCrop
                  className={'adaptive carousal-cover'}
                  imgCrop={Image.value.Crops}
                  alt={Image.value.AltText}
                  title={Image.value.AltText}
                  onErrorClass="no-img"
                />
              </LazyLoad>
            </div>
          )}
          <div
            className={`container ${isTouchDevice ? 'custom-container' : ''} ${
              slideCount() <= slideShowCount() ? 'adjust' : ''
            } ${slideShowCount()}`}
          >
            <div className="carousal-width" ref={refElement}>
              <div className="carousal-head">
                <div className="carousal_title_link kitbag">
                  {Title && Title.value && !(Image && Image.value) && (
                    <h2 className="carousal__skin-title">
                      <span>
                        <Text field={Title} />
                      </span>
                      {carousalDestination()}
                    </h2>
                  )}
                  {Title && Title.value && (
                    <h2 className="carousal__title white">
                      <span>
                        <Text field={Title} />
                      </span>
                      {carousalDestination()}
                    </h2>
                  )}
                </div>
              </div>
              {isExperienceEditorActive() && (
                <div className="carousal__info">Slider CurationMode :Add Slider Components</div>
              )}
              {!isTouchDevice && !isExperienceEditorActive() && (
                <Placeholder
                  name="mufc-ph-shopcarousel"
                  rendering={props.rendering}
                  missingComponentComponent={() => {
                    return null;
                  }}
                  render={(components) => {
                    return <Slider {...settings()}>{components}</Slider>;
                  }}
                  container={SHOP_CAROUSEL}
                />
              )}
              {(isTouchDevice || isExperienceEditorActive()) && (
                <div className="carousal-wrapper">
                  <div className="carousal-list custom-mobile-slider">
                    <Placeholder
                      name="mufc-ph-shopcarousel"
                      rendering={props.rendering}
                      missingComponentComponent={() => {
                        return null;
                      }}
                      renderEach={(components, i) => {
                        return (
                          <div className="mobile-listitem" role="listitem" key={i}>
                            {components}
                          </div>
                        );
                      }}
                      container={SHOP_CAROUSEL}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {!isTouchDevice && (
          <div className="screenreader" aria-live="assertive">
            {screenReaderText}
          </div>
        )}
      </div>
    </ErrorBoundary>
  );
}, areEqual);
export default withTranslation()(ShopCarousel);
