/**
 *  © 2022-2023 DXC Technology, all rights reserved.
 *  Material published by DXC Technology on these web pages/mobile
 *  app may not be reproduced without permission.
 */

/**
 * Callback after braze sdk is loaded
 */
import MU from '../constants/muConstants';
import { checkTimeDifference, fnBrowserDetect, getCookie } from './utils';
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
const braze =
  typeof window !== 'undefined' && !isExperienceEditorActive() ? require('@braze/web-sdk') : null;

const userAgent = typeof window !== 'undefined' && window?.navigator?.userAgent;

const customSupported = () => {
  return (
    /^((?!chrome|android).)*safari/i.test(userAgent) &&
    !userAgent.match(/iPad/i) &&
    !userAgent.match(/iPhone/i)
  );
};

export const getBrazeFeatureFlg = (key) => {
  const featureFlag = braze?.getFeatureFlag(key);
  if (featureFlag?.enabled) {
    console.log(`feature flag is enabled for ${key}`);
    return true;
  } else {
    console.log(`feature flag is not enabled for ${key}`);
    return false;
  }
};

const notificationPermission = (notification) => {
  if (userAgent && notification?.customWebNotification) {
    const browserSet = notification?.customWebNotification?.allowedBrowsers;
    const browserObj = fnBrowserDetect();
    const checkBrowserSet = browserObj && browserSet?.includes(browserObj);
    if (checkBrowserSet || customSupported()) {
      return true;
    } else return false;
  } else return false;
};

export const isCookiesSelected = () => {
  const userConsent_on = getCookie('userConsentCookiePolicy') === 'on';
  const userConsent_off = getCookie('userConsentCookiePolicy') === 'off';
  const infoAgreeData = localStorage.getItem('infoAgree');
  const infoConsent = infoAgreeData && JSON.parse(infoAgreeData)?.value === 'yes';
  if (userConsent_on || userConsent_off || infoConsent) return true;
  else return false;
};

export const initBraze = () => {
  if (typeof window !== 'undefined' && MU?.brazeData?.brazeKey && MU?.brazeData?.baseUrl) {
    const userConsent_on = getCookie('userConsentCookiePolicy') === 'on';
    const userConsent_off = getCookie('userConsentCookiePolicy') === 'off';
    const infoAgreeData = localStorage.getItem('infoAgree');
    const infoConsent = infoAgreeData && JSON.parse(infoAgreeData)?.value === 'yes';
    const isNoCookies = userConsent_on || infoConsent ? true : userConsent_off && false;
    braze.initialize(MU.brazeData.brazeKey, {
      baseUrl: MU.brazeData.baseUrl,
      enableLogging: true,
      allowUserSuppliedJavascript: true,
      inAppMessageZIndex: 9999,
      noCookies: !isNoCookies,
    });
  }
};

const normalizeUrl = (url) => {
  // Ensure the URL starts with "http://" or "https://", if it doesn't, we add "https://" by default
  if (!/^https?:\/\//i.test(url)) {
    url = 'https://' + url;
  }
  // If URL has ?? then replace with page lang.
  if (url.includes('??') && url.split('/')[3] === '??') {
    url = url.replace('??', MU.language);
  }
  // Normalize the path by removing trailing slashes
  url = url.replace(/\/$/, '');

  // Normalize the path by removing *
  url = url.replace(/\*/g, '');

  // Normalize the subdomain (make it optional) and path (allow for the first-level path like "/xx")
  url = url.replace(/^https?:\/\/(www\.)?/, 'https://'); // Normalize to always use https://

  return url;
};
const excludedPages = ['Search', 'VideoEpisode'];
const excludedPattern = (currentPage) => {
  const videoElement = document.querySelector('video-js');
  const modalElement = document.querySelector('.mu-modal');
  const formElement = document.querySelector('.generic-form');
  const competitionElement = document.querySelector('.home-competition-detail');
  // Check if either videoElement or modalElement or formElement or competitionElement is present, and ensure the page is not in the excluded list
  if (
    videoElement ||
    modalElement ||
    formElement ||
    competitionElement ||
    excludedPages.some((page) => currentPage.includes(page))
  ) {
    console.log('Braze: At least one element is present, and the page is excluded.');
    return true;
  } else {
    console.log('Braze: Either no element is present, or the page is not excluded.');
    return false;
  }
};

const matchUrlPattern = (pgUrl, brazeUrl) => {
  // Normalize both URLs
  let normalizedPgUrl;
  let normalizedBrazeUrl;
  if (brazeUrl.endsWith('*')) {
    normalizedPgUrl = normalizeUrl(pgUrl);
    normalizedBrazeUrl = normalizeUrl(brazeUrl);
    if (normalizedPgUrl.includes(normalizedBrazeUrl)) {
      return true;
    } else return false;
  } else {
    normalizedPgUrl = normalizeUrl(pgUrl);
    normalizedBrazeUrl = normalizeUrl(brazeUrl);

    // Compare the normalized URLs
    return normalizedPgUrl === normalizedBrazeUrl;
  }
};

const isBrazeExcluded = (value) => {
  const currentUrl = window.location.href; // Get the current page URL
  const urlPatterns = value.split(',');
  const isUrlsMatch = urlPatterns.some((pattern) => matchUrlPattern(currentUrl, pattern));
  if (isUrlsMatch) {
    return true;
  } else return false;
};

const fetchInAppMsg = (inAppMessage, currentPage) => {
  const extras = inAppMessage.extras;
  console.log('Braze: inAppMessage', inAppMessage);
  if (Object.keys(extras).length === 1 && extras.hasOwnProperty('container_url_excludes')) {
    if (excludedPattern(currentPage) || isBrazeExcluded(extras['container_url_excludes'])) {
      braze.deferInAppMessage(inAppMessage);
      console.log(
        'Braze: Deferred In-App Message',
        'container_url_excludes',
        extras['container_url_excludes']
      );
    } else braze.showInAppMessage(inAppMessage);
  }
  if (Object.keys(extras).length !== 0) {
    for (const key in extras) {
      if (key === 'container_url' && extras[key] !== '') {
        if (
          ((extras.hasOwnProperty('container_url_excludes') &&
            !isBrazeExcluded(extras['container_url_excludes'])) ||
            !extras.hasOwnProperty('container_url_excludes')) &&
          !excludedPattern(currentPage)
        ) {
          const currentUrl = typeof window !== 'undefined' && window.location.href; // Get the current page URL
          const urlPatterns = extras[key].split(',');
          const isUrlsMatch = urlPatterns.some((pattern) => matchUrlPattern(currentUrl, pattern));
          console.log('Braze: key-value pair', 'container_url', extras[key], currentUrl);
          if (isUrlsMatch) {
            braze.showInAppMessage(inAppMessage);
          } else braze.deferInAppMessage(inAppMessage);
        } else braze.deferInAppMessage(inAppMessage);
      }
      if (key === 'container_type' && extras[key] !== '') {
        if (
          ((extras.hasOwnProperty('container_url_excludes') &&
            !isBrazeExcluded(extras['container_url_excludes'])) ||
            !extras.hasOwnProperty('container_url_excludes')) &&
          !excludedPattern(currentPage)
        ) {
          const currentLocation = typeof window !== 'undefined' && window.location.href; // Get the current page URL
          const currentHost = typeof window !== 'undefined' && window.location.host;
          const currentPath = currentHost && `${currentHost}/${MU.language}`;

          let matchUrl = '';
          const brazeKey = extras[key]?.toLowerCase();

          switch (brazeKey) {
            case 'united-now':
              matchUrl = `${currentPath}`;
              break;
            case 'mutv':
              matchUrl = `${currentPath}/mutv`;
              break;
            case 'myunited':
              matchUrl = `${currentPath}/myunited`;
              break;
            default:
              return false; // If the key doesn't match any known patterns
          }
          const isUrlsMatch = matchUrlPattern(currentLocation, matchUrl);
          if (isUrlsMatch) {
            console.log('Braze: key-value pair', 'container_type', extras[key]);
            braze.showInAppMessage(inAppMessage);
          } else braze.deferInAppMessage(inAppMessage);
        } else braze.deferInAppMessage(inAppMessage);
      }
    }
  } else braze.showInAppMessage(inAppMessage);
};
export const handleBraze = (notification, currentPage) => {
  braze && initBraze();

  // optionally show all in-app messages
  // Check if there are any deferred in-app messages
  const getDeferredInAppMsg = braze && braze.getDeferredInAppMessage();
  if (getDeferredInAppMsg) {
    console.log('Braze: Deferred In-App Message:', getDeferredInAppMsg);
    fetchInAppMsg(getDeferredInAppMsg, currentPage);
  }

  braze &&
    braze.subscribeToInAppMessage(function (inAppMessage) {
      if (inAppMessage instanceof braze.InAppMessage) {
        fetchInAppMsg(inAppMessage, currentPage);
      }
    });

  braze && MU?.language && braze.getUser().setCustomUserAttribute('web_language', MU.language);

  braze &&
    MU?.countryCode &&
    braze.getUser().setCustomUserAttribute('web_last_location', MU.countryCode);

  if (braze && notificationPermission(notification) && 'Notification' in window) {
    const permission = Notification?.permission;
    if (permission === 'allowed' || permission === 'granted') {
      // register for push
      braze.requestPushPermission();
      console.log('Braze-isPushBlocked', braze.isPushBlocked());

      console.log('Braze-isPushSupported', braze.isPushSupported());

      console.log('Braze-isPushPermissionGranted', braze.isPushPermissionGranted());
    }
  }
};

export const callBraze = (userData, notification, currentPage) => {
  handleBraze(notification, currentPage);

  if (braze && userData && userData.UID) {
    braze.changeUser(userData.UID);
  }

  // optionally set the current user's external ID before starting a new session
  if (
    braze &&
    userData &&
    userData.registeredTimestamp &&
    checkTimeDifference(userData.registeredTimestamp)
  ) {
    userData && setUserDataForBraze(braze, userData);
  }

  // `openSession` should be called last - after `changeUser` and `automaticallyShowInAppMessages`
  braze && braze.openSession();
};

export const setUserDataForBraze = (braze, userData) => {
  if (typeof window !== 'undefined' && userData) {
    if (userData && userData.profile) {
      const { firstName, lastName, email } = userData.profile;

      if (firstName) {
        braze.getUser().setFirstName(firstName);
      }

      if (lastName) {
        braze.getUser().setLastName(lastName);
      }

      if (email) {
        braze.getUser().setEmail(email);
      }
    }
  }
};
