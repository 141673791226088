/**
 *  © 2022 -2023 DXC Technology, all rights reserved.
 *  Material published by DXC Technology on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import {
  encodeURLParams,
  setCookie,
  getParameterByName,
  checkSubscription,
  createDestinationUrl,
  isApp,
  cleanUrl,
} from './utils';
import { createCodeVerifierNChallenge } from '../lib/PKCE';
import MU from '../constants/muConstants';
import { showLogin, showRegister } from './gigyaScreen';
import { onUserLogout } from '../components/MyUnited/unitedUtils';
import { SET_USER_PROFILE } from '../constants/endpoints';
import api from '../utils/muApi';
import secureLocalStorage from 'react-secure-storage';
import { handleAuthClick } from './calendarUtils';
import axios from 'axios';
import xss from 'xss';
import { jwtDecode } from 'jwt-decode';
import * as track from '../utils/analytics';
import { NONE } from '../constants/globalConstants';
// References
// https://auth0.com/docs/get-started/authentication-and-authorization-flow/add-login-using-the-authorization-code-flow-with-pkce
// https://www.stefaanlippens.net/oauth-code-flow-pkce.html

export const createBaseUrl = () =>
  typeof window !== 'undefined' && window.location.origin + '/' + MU.language;

const cookiesTimeout = 365;

const unWantedParams = ['scope', 'session_state', 'code', 'error'];

export const redirectUrl = () => {
  const loginRedirectUrl = typeof window !== 'undefined' && window.location.href;
  if (loginRedirectUrl) {
    const code = getParameterByName('code', true);
    if (code) {
      return cleanUrl(loginRedirectUrl, [...unWantedParams, 'state']);
      //return loginRedirectUrl.includes('?') ? loginRedirectUrl.split('?')[0] : createBaseUrl();
      // } else if (loginRedirectUrl.slice(-1) !== '/') {
      //   return loginRedirectUrl + '/';
    } else return loginRedirectUrl;
  } else {
    return createBaseUrl();
  }
};

export const generateCodes = () =>
  createCodeVerifierNChallenge().then(({ verifier, challenge }) => {
    sessionStorage.setItem('idm_verifier', verifier);
    sessionStorage.setItem('idm_challenge', challenge);
    return challenge;
  });

const createLoginUrl = (challenge, successCallback) => {
  if (successCallback) {
    return `${
      MU.iDMLoginURL
    }/connect/authorize?client_id=web&scope=openid full_profile offline_access status ticketing auth&response_type=code&prompt=login&redirect_uri=${redirectUrl()}&culture=${langParameters()}&code_challenge_method=S256&code_challenge=${challenge}&state=${successCallback}`;
  } else {
    return `${
      MU.iDMLoginURL
    }/connect/authorize?client_id=web&scope=openid full_profile offline_access status ticketing auth&response_type=code&prompt=login&redirect_uri=${redirectUrl()}&culture=${langParameters()}&code_challenge_method=S256&code_challenge=${challenge}`;
  }
};

const createRefreshLoginUrl = (challenge) => {
  return `${
    MU.iDMLoginURL
  }/connect/authorize?client_id=web&scope=openid full_profile offline_access status ticketing auth&response_type=code&prompt=none&redirect_uri=${redirectUrl()}&culture=${langParameters()}&code_challenge_method=S256&code_challenge=${challenge}`;
};
const createMUTVUrl = (challenge) =>
  `${
    MU.iDMLoginURL
  }/connect/authorize?client_id=web_tv&scope=openid full_profile offline_access status ticketing auth&response_type=code&redirect_uri=${redirectUrl()}&culture=${langParameters()}&code_challenge_method=S256&code_challenge=${challenge}&state=web_tv`;

const createSignupUrl = (challenge) => createLoginUrl(challenge) + '&acr_values=action:register';

const createMUTVSignupUrl = (challenge) => createMUTVUrl(challenge) + '&acr_values=action:register';

const createLogoutUrl = () => {
  return (
    `${
      MU.iDMLoginURL
    }/connect/endsession?post_logout_redirect_uri=${createBaseUrl()}&culture=${langParameters()}&id_token_hint=` +
    getIDMCookie('idm_id_token')
  );
};

export const checkLocalCodeMissing = () => !sessionStorage.getItem('idm_code');

const setIDMUserSession = (eventObj) => {
  localStorage.setItem('deviceID', eventObj && eventObj.UIDSignature);
};

const storeUserData = (data) => {
  if (isApp()) {
    localStorage.setItem('vyaktigat', JSON.stringify(data));
    localStorage.setItem('vyaktigat_time', new Date().getTime());
  } else {
    secureLocalStorage.setItem('userInfo', JSON.stringify(data));
    secureLocalStorage.setItem('userInfo_time', new Date().getTime());
  }
  setIDMUserSession(data);
};

export const removeUserData = () => {
  if (isApp()) {
    localStorage.removeItem('vyaktigat');
    localStorage.removeItem('vyaktigat_time');
  } else {
    secureLocalStorage.removeItem('userInfo');
    secureLocalStorage.removeItem('userInfo_time');
  }
};

export const userDataExpireTime = (value, userDataLogOutTime) => {
  // const mainValue = value.toLowerCase();
  // switch (mainValue) {
  //   case 'mutv':
  //     return 1;
  //   case 'myunited':
  //     return 0;
  //   default:
  //     return 5;
  // }
  if (userDataLogOutTime) {
    if (typeof userDataLogOutTime === 'string') {
      const integerValue = parseInt(userDataLogOutTime);
      if (integerValue) {
        return integerValue / 60;
      }
    } else if (userDataLogOutTime) {
      return userDataLogOutTime / 60;
    }
  }
  return 0;
};
export const getUserData = (value) => {
  let getUserLocalData;
  if (isApp()) {
    getUserLocalData = localStorage.getItem(value);
  } else {
    getUserLocalData = secureLocalStorage.getItem(value);
  }
  return JSON.parse(getUserLocalData);
};

const getTokenExp = (token) => {
  const decoded = jwtDecode(token);
  return decoded?.exp;
};

export const fetchIDMToken = (code, _cbS, _cbF, code_verifier, currentPage, client_id) => {
  axios({
    url: `${MU.iDMLoginURL}/connect/token`,
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', accept: 'application/json' },
    data: encodeURLParams({
      client_id: client_id,
      grant_type: 'authorization_code',
      code: code,
      code_verifier: code_verifier,
      redirect_uri: redirectUrl(),
    }),
  })
    .then((response) => {
      const { error, access_token, refresh_token, id_token } = response.data;
      if (access_token) {
        setIDMCookie('idm_access_token', access_token, false, getTokenExp(access_token.toString()));
      }
      if (refresh_token) {
        setIDMCookie('idm_refresh_token', refresh_token, 90);
      }
      if (id_token) {
        setIDMCookie('idm_id_token', id_token, false, getTokenExp(id_token.toString()));
      }
      sessionStorage.removeItem('idm_verifier');
      sessionStorage.removeItem('idm_challenge');

      if (error) {
        if (currentPage === 'myunited') window.location = createBaseUrl();
        else {
          _cbF();
        }
      } else {
        _cbS();
      }
    })
    .catch((err) => {
      sessionStorage.removeItem('idm_verifier');
      sessionStorage.removeItem('idm_challenge');
      _cbF();
      console.log(err.message);
    });
};

export const getNewToken = (refresh_token, _cb, currentPage, client_id) => {
  axios({
    url: `${MU.iDMLoginURL}/connect/token`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      accept: 'application/json',
    },
    data: encodeURLParams({
      client_id: client_id,
      grant_type: 'refresh_token',
      refresh_token: refresh_token,
    }),
  })
    .then((response) => {
      const { error, access_token, refresh_token, id_token } = response.data;
      if (access_token) {
        setIDMCookie('idm_access_token', access_token, false, getTokenExp(access_token.toString()));
      }
      if (refresh_token) {
        setIDMCookie('idm_refresh_token', refresh_token, 90);
      }
      if (id_token) {
        setIDMCookie('idm_id_token', id_token, false, getTokenExp(id_token.toString()));
      }
      if (error) {
        afterLogout();
        if (currentPage === 'myunited') window.location = createBaseUrl();
      } else {
        _cb();
      }
    })
    .catch((err) => {
      afterLogout();
      console.log(err.message);
      window.location = createBaseUrl();
    });
};

export const fetchUserInfo = (savedAccessToken, _cbS, _cbF) => {
  axios({
    url: `${MU.iDMLoginURL}/connect/userinfo`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      accept: 'application/json',
      Authorization: 'Bearer ' + savedAccessToken,
    },
  })
    .then((response) => {
      storeUserData(response.data);
      _cbS(response.data);
    })
    .catch((err) => {
      console.log(err.message);
      _cbF();
    });
};

export const redirectToIDMLogin = (check, successCallback) => {
  generateCodes().then((challenge) => {
    if (typeof window !== 'undefined') {
      if (check === 'page_refresh') {
        window.location = createRefreshLoginUrl(challenge);
      } else {
        window.location = createLoginUrl(challenge, successCallback);
      }
    }
  });
};

export const afterLogout = () => {
  sessionStorage.removeItem('idm_code');
  sessionStorage.removeItem('idm_verifier');
  sessionStorage.removeItem('idm_challenge');
  eraseIDMCookie('idm_access_token');
  eraseIDMCookie('idm_refresh_token');
  eraseIDMCookie('idm_id_token');
};

export const redirectToIDMSignUp = () => {
  generateCodes().then((challenge) => {
    if (typeof window !== 'undefined') window.location = createSignupUrl(challenge);
  });
};

export const contentLoginFlow = (getLoginDetail) => {
  let successCallback;
  if (getLoginDetail) {
    successCallback = getLoginDetail();
  }
  redirectToIDMLogin('', successCallback);
};

export const redirectToIDMMUTVSignUp = () => {
  generateCodes().then((challenge) => {
    if (typeof window !== 'undefined') window.location = createMUTVSignupUrl(challenge);
  });
};

export const triggerSignUp = (getLoginDetail, isMutv) => {
  if (MU.isIDMEnabled) {
    redirectToIDMSignUp();
  } else {
    showRegister(getLoginDetail, isMutv);
  }
};

export const checkIDMFlow = (isUserLogin) => {
  if (isUserLogin && checkLocalCodeMissing()) {
    redirectToIDMLogin();
  }

  if (window) {
    let returnUrl = window?.location?.href;
    if (returnUrl?.includes('code=')) {
      let redirectUri = cleanUrl(returnUrl, unWantedParams);
      window.history.replaceState({}, '', decodeURIComponent(redirectUri));
    }
  }
};

export const IDMSuccessScroll = (retriveSuccessCallback, userData) => {
  const successCallData = retriveSuccessCallback.split(',');
  if (successCallData && successCallData[0] === 'successScroll') {
    const isSubscribedContent = successCallData[1] && successCallData[1] === 'true';
    const userSubscribed = checkSubscription(userData);
    if (!isSubscribedContent || (isSubscribedContent && userSubscribed)) {
      window.scrollTo(Number(successCallData[2]), Number(successCallData[3]) - 300);
      const url = window.location.href;
      let redirectUri = cleanUrl(url, [...unWantedParams, 'state']);
      //const urlMain = url.split('?')[0] ?? url;
      window.history.replaceState({}, null, redirectUri);
    }
  }
};

export const IDMSuccessCallback = (retriveSuccessCallback, userData) => {
  const successCallback = retriveSuccessCallback.split(',');
  if (successCallback && successCallback[0] === 'successCallbck') {
    const isSubscribedContent = successCallback[1] && successCallback[1] === 'true';
    const userSubscribed = checkSubscription(userData);
    if (!isSubscribedContent || (isSubscribedContent && userSubscribed)) {
      if (successCallback[2] === 'destinationUrl') {
        if (successCallback[3] && typeof window !== 'undefined')
          window.location = createDestinationUrl(successCallback[3]);
        else window.location = redirectUrl();
      } else if (successCallback[2] === 'googleCalendarUrl') {
        if (successCallback[3]) handleAuthClick(successCallback[3]);
        else window.location = redirectUrl();
      } else if (successCallback[2] === 'openDestinationUrl') {
        if (successCallback[3] && successCallback[4] && typeof window !== 'undefined')
          window.open(successCallback[3], successCallback[4]);
        else window.location = redirectUrl();
      } else window.location = redirectUrl();
    } else window.location = redirectUrl();
  } else window.location = redirectUrl();
};

export const doUserLogout = () => {
  if (typeof window !== 'undefined' && MU.isIDMEnabled) {
    removeUserData();
    window.location = xss(createLogoutUrl());
    afterLogout();
  } else {
    onUserLogout();
  }
};

export const triggerLoginFlow = (getLoginDetail, isMutv) => {
  MU.isIDMEnabled ? redirectToIDMLogin() : showLogin(getLoginDetail, isMutv);
};

export const createEditProfileUrl = () =>
  `${
    MU.iDMLoginURL
  }/profile?culture=${langParameters()}&returnUrl=${createBaseUrl()}/myunited/settings`;

export const createChangePasswordUrl = () =>
  `${
    MU.iDMLoginURL
  }/change-password?culture=${langParameters()}&returnUrl=${createBaseUrl()}/myunited/settings`;

export const createChangeEmaildUrl = () =>
  `${
    MU.iDMLoginURL
  }/change-email?culture=${langParameters()}&returnUrl=${createBaseUrl()}/myunited/settings`;

export const changePreferencesUrl = () =>
  `${MU.iDMLoginURL}/change-preferences?culture=${langParameters()}`;

const langParameters = () => {
  const lang = MU.language;
  switch (lang) {
    case 'en': {
      return 'en-GB';
    }
    case 'fr': {
      return 'fr-FR';
    }
    case 'de': {
      return 'de-DE';
    }
    case 'es': {
      return 'es-ES';
    }
    case 'it': {
      return 'it-IT';
    }
    case 'zh': {
      return 'zh-Hans';
    }
    case 'ko': {
      return 'ko-KR';
    }
    case 'ja': {
      return 'ja-JP';
    }
    case 'ar': {
      return 'ar-SA';
    }
    default:
      return 'en-GB';
  }
};

export const setUserFavPlayer = (userLoginDetail, favPlayerId) => {
  const route = `${SET_USER_PROFILE}`;
  const baseURL = `${MU.awsEndPoint}/${MU.awsAPIVersion}/${MU.language}/`;
  const header = { Accept: 'application/json' };
  const isHardcoredGeo = false;
  const data = userFavPlayerDataObj(userLoginDetail, favPlayerId);
  return api
    .post({ baseURL, route, isHardcoredGeo, header, data })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log('userInfo POST error', err);
    });
};
const userFavPlayerDataObj = (userLoginDetail, favPlayerId) => {
  let userObj = {
    UID: userLoginDetail && userLoginDetail.UID && userLoginDetail.UID,
    UIDSignature: userLoginDetail && userLoginDetail.UIDSignature && userLoginDetail.UIDSignature,
    signatureTimestamp:
      userLoginDetail && userLoginDetail.signatureTimestamp && userLoginDetail.signatureTimestamp,
    data: {
      favoritePlayerID: favPlayerId && favPlayerId,
    },
  };
  return userObj;
};

export const setUserJerseyNumber = (userLoginDetail, JerseyNo) => {
  const route = `${SET_USER_PROFILE}`;
  const baseURL = `${MU.awsEndPoint}/${MU.awsAPIVersion}/${MU.language}/`;
  const header = { Accept: 'application/json' };
  const isHardcoredGeo = false;
  const data = userJerseyNumberDataObj(userLoginDetail, JerseyNo);
  return api
    .post({ baseURL, route, isHardcoredGeo, header, data })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log('userInfo POST error', err);
    });
};
const userJerseyNumberDataObj = (userLoginDetail, JerseyNo) => {
  let userObj = {
    UID: userLoginDetail && userLoginDetail.UID && userLoginDetail.UID,
    UIDSignature: userLoginDetail && userLoginDetail.UIDSignature && userLoginDetail.UIDSignature,
    signatureTimestamp:
      userLoginDetail && userLoginDetail.signatureTimestamp && userLoginDetail.signatureTimestamp,
    data: {
      jerseyNo: JerseyNo && JerseyNo,
    },
  };
  return userObj;
};

export const saveUserIDCookie = (uid) => {
  if (!MU.isIDMEnabled && uid) {
    setCookie('manutd_uid', uid, cookiesTimeout, ';domain=.manutd.com');
  }
};

export const removeUserIDCookie = () => {
  if (document) {
    document.cookie =
      'manutd_uid=; Path=/; domain=.manutd.com;  expires=Thu, 01 Jan 1970 00:00:00 UTC';
  }
};

export const setIDMCookie = (name, value, days, tokenDate) => {
  var expires = '';
  var date;
  if (days) {
    date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  } else if (tokenDate) {
    date = new Date(tokenDate * 1000);
    expires = '; expires=' + date;
  }
  document.cookie = name + '=' + (value || '') + expires + '; secure; path=/';
};
export const getIDMCookie = (name) => {
  var nameEQ = name + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};
export const eraseIDMCookie = (name) => {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};

// UserinfoAnalytics
export const handleUserinfoAnalytics = (response) => {
  const memberExpireDate = response && response.memberExpiredDate;
  const mensTicketExpiredDate = response && response.mensTicketExpiredDate;
  const womensTicketExpiredDate = response && response.womensTicketExpiredDate;

  let member_type,
    memberPrice_type,
    mensTicket_type,
    mensTicketPrice_type,
    womensTicket_type,
    womensTicketPrice_type;

  if (new Date() > new Date(memberExpireDate)) {
    member_type = NONE;
    memberPrice_type = NONE;
  } else {
    member_type = isValid(response && response.memberType);
    memberPrice_type = isValid(response && response.memberPriceType);
  }

  if (new Date() > new Date(mensTicketExpiredDate)) {
    mensTicket_type = NONE;
    mensTicketPrice_type = NONE;
  } else {
    mensTicket_type = isValid(response && response.mensTicketType);
    mensTicketPrice_type = isValid(response && response.mensTicketPriceType);
  }

  if (new Date() > new Date(womensTicketExpiredDate)) {
    womensTicket_type = NONE;
    womensTicketPrice_type = NONE;
  } else {
    womensTicket_type = isValid(response && response.womensTicketType);
    womensTicketPrice_type = isValid(response && response.womensTicketPriceType);
  }

  track.analytics({
    memberType: member_type,
    memberPriceType: memberPrice_type,
    mensTicketType: mensTicket_type,
    mensTicketPriceType: mensTicketPrice_type,
    womensTicketType: womensTicket_type,
    womensTicketPriceType: womensTicketPrice_type,
  });
};

function isValid(value) {
  if (value === null || value === undefined || value === '') {
    return NONE;
  }
  return value;
}
