/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useState, useEffect } from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';
import ImgCrop from '../../Common/ImgCrop';
import { GenericConditional } from './GenericConditional';
import { useSponsorValue } from '../../../context/sponsorContext';
import * as track from '../../../utils/analytics';
import {
  fetchSearchReport,
  fetchGenericPersistReport,
  postEmailData,
  postFeedbackData,
} from '../../../actions/formAction';
import { FormProvider, useFormValue } from '../../../context/formContext';
import MU from '../../../constants/muConstants';
import {
  DataRequiredValidation,
  getLoaderHtml,
  EmailValidation,
  MaxLengthValidation,
  MinLengthValidation,
  NumericValidation,
  setInputTextData,
  setName,
  AlphaNumericValidation,
} from '../../../utils/FormUtils';
import { redirectToSignIn } from '../../../utils/gigyaScreen';
import moment from 'moment';
import { checkLocalCodeMissing, redirectToIDMLogin } from '../../../utils/idmUtils';
import { getParameterByName } from '../../../utils/utils';

const GenericForm = (props) => (
  <FormProvider>
    <GenericFormContainer {...props} />
  </FormProvider>
);

const GenericFormContainer = (props) => {
  const { fields, t } = props;
  const [{ isGigyaAvilable, userLoginDetail, isUserLogin }] = useSponsorValue();
  const {
    Image,
    Description,
    Captcha,
    IsCustomisedEmail,
    IsLoginRequire,
    ResetButton,
    ButtonText,
    DuplicateCheckDays,
    IsFanFeedBack,
    Title,
    All_Inclusive_Options,
    AttachmentCheckBox,
    'AttachmentFileSize-MB': AttachmentFileSizeMB,
    AttachmentFileTypeErrorMessege,
    EMailCheckBox,
    AttachmentFileSizeErrorMessege,
    AttachmentFileTypes,
    Subject,
    FooterInformationMessage,
    DuplicateMessage,
    ThankYouMessage,
    manutds,
    RepresentativeName,
    FormType,
  } = fields;

  const [
    {
      isCaptchaAvilable,
      loader,
      searchReportData,
      genericPersistReportData,
      emailResponse,
      feedbackData,
      status,
    },
    dispatch,
  ] = useFormValue();

  const [formDetails, setFormDetails] = useState({});
  const [grecaptcha, setGrecaptcha] = useState(null);
  const [reloginErr, setReloginErr] = useState({ display: 'none' });
  const [thankuMsg, setThankuMsg] = useState({ display: 'none' });
  const [showHideForm, setShowHideForm] = useState({ display: 'block' });
  const [showHideHero, setShowHideHero] = useState({ display: 'block' });
  const [checkValidFile, setCheckValidFile] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [formError, setFormError] = useState({});
  const [initial, setInitial] = useState(true);
  const [fileName, setFileName] = useState(null);
  const [screenReaderText, setScreenReaderText] = useState('');

  useEffect(() => {
    trackFormView();
  }, []);

  useEffect(() => {
    if (isGigyaAvilable && IsLoginRequire && IsLoginRequire.value && !isUserLogin) {
      if (MU.isIDMEnabled) {
        const code = getParameterByName('code', true);
        if (checkLocalCodeMissing() && !code) {
          redirectToIDMLogin();
        }
      } else redirectToSignIn();
    }
  }, [isGigyaAvilable, isUserLogin, IsLoginRequire]);

  useEffect(() => {
    isUserLogin && checkLoginStatus();
  }, [isUserLogin]);

  useEffect(() => {
    isCaptchaAvilable && setGrecaptcha(window.grecaptcha);
  }, [isCaptchaAvilable]);
  useEffect(() => {
    searchReportData && updateSearchReportData(searchReportData);
  }, [searchReportData]);
  useEffect(() => {
    genericPersistReportData && updatePersistReportData(genericPersistReportData);
  }, [genericPersistReportData]);

  // PBL-1870 Temporary Fix till email api issue is fixed, un comment below hook after fix.
  // useEffect(() => {
  //   emailResponse && updateEmailResponse(emailResponse);
  // }, [emailResponse]);
  useEffect(() => {
    feedbackData && updateFeedbackData(feedbackData);
  }, [feedbackData]);
  useEffect(() => {
    let obj = {};
    All_Inclusive_Options &&
      All_Inclusive_Options.length > 0 &&
      All_Inclusive_Options.forEach((item) => {
        let newData = item && item.fields;
        let i = newData && setInputTextData(item.fields);
        if (newData && newData.UploadButtonCheck && newData.UploadButtonCheck.value) {
          let fileTitle =
            fields.AttachmentCheckBox &&
            fields.AttachmentCheckBox.value &&
            fields.AttachmentFileTypes &&
            fields.AttachmentFileTypes.length > 0 &&
            newData.Title &&
            newData.Title.value;
          // fileTitle && setFileName(fileTitle);
        }
        obj = i && i.Name && { ...obj, [setName(i.Name)]: null };
      });
    setFormDetails({ ...formDetails, ...obj });
  }, [All_Inclusive_Options]);

  const trackFormView = () => {
    const initialData = track.data('formView');
    track.analytics({
      ...initialData,
      form_name: Title && Title.value,
    });
  };
  const checkLoginStatus = () => {
    const emailId = userLoginDetail && userLoginDetail.profile && userLoginDetail.profile.email;
    setUserEmail(emailId);
    setFormDetails({ ...formDetails, Email: emailId });
    setTimeout(() => {
      const emeilElm = document.getElementsByClassName('GenericFormemail');
      if (emeilElm && emeilElm.length > 0) {
        emeilElm[0].value = emailId;
        emeilElm[0].disabled = true;
      }
    }, 3000);
  };

  const JSONEmpty = (obj) => {
    return (
      !obj.length ||
      !obj.filter(function (a) {
        return Object.keys(a).length;
      }).length
    );
  };
  const trackForm = () => {
    const initialData = track.data('formSubmit');
    track.analytics({
      ...initialData,
      form_name: Title && Title.value,
      button_name: ButtonText && ButtonText.value,
    });
  };
  const updateFormContainer = () => {
    //$('.generic_errormsg').hide(); // need to handle (network issue validation)
    setShowHideForm({ display: 'none' });
    setShowHideHero({ display: 'none' });
    setThankuMsg({ display: 'block' });
    const target = document.getElementById('united_form');
    if (target) {
      target.reset();
      resetFormDetails(target);
    }
    setTimeout(() => {
      scrollToUp();
    }, 1000);
  };
  const resetFormDetails = (target) => {
    setInitial(true);
    setFormError({});
    const select = target.querySelectorAll('.formElem.inputSelectBox');
    select &&
      select.forEach((a) => {
        const b = a.querySelector('option');
        b && b.setAttribute('selected', true);
      });
    let newObj = {};
    formDetails &&
      Object.entries(formDetails).forEach((a) => {
        newObj = { ...newObj, [a[0]]: null };
      });
    newObj && setFormDetails({ ...formDetails, ...newObj });
    setFileName(null);
    setCheckValidFile(false);
  };
  const updateEmailResponse = (emailResponse) => {
    updateFormContainer();
    trackForm();
  };
  const updateFeedbackData = (feedbackData) => {
    updateFormContainer();
    trackForm();
  };
  const updateSearchReportData = (searchReportData) => {
    const searchResult =
      searchReportData && searchReportData.results && JSONEmpty(searchReportData.results);
    if (!searchResult) {
      const formName = getName();
      const gigyaFormObjArr =
        searchReportData &&
        searchReportData.results.length > 0 &&
        searchReportData.results[0].data &&
        searchReportData.results[0].data[formName];
      const searchGetDate =
        gigyaFormObjArr && new Date(gigyaFormObjArr[gigyaFormObjArr.length - 1].submitTime);
      const GenericValidId = getGenericValidId();
      var subtractDate =
        searchGetDate && new Date(searchGetDate.setDate(searchGetDate.getDate() + GenericValidId));
      var todayDate = new Date();
      if (todayDate > subtractDate) {
        formSubmitCheck();
      } else {
        setReloginErr({ display: 'block' });
        setShowHideForm({ display: 'none' });
        setShowHideHero({ display: 'none' });
        scrollToUp();
      }
    } else {
      formSubmitCheck();
    }
  };
  const getDomValue = (string) => {
    const a = document.querySelector(string);
    if (a) {
      return a.value;
    }
    return '';
  };
  const sendUploadedFile = () => {
    if (window.FormData !== undefined) {
      let fileData = new FormData();
      if (AttachmentCheckBox && AttachmentCheckBox.value && checkValidFile) {
        fileData.append(selectedFile.name, selectedFile);
      }
      fileData.append('Language', MU && MU.language ? MU.language : 'en');
      fileData.append('FirstName', getDomValue('.generic_firstname'));
      fileData.append('EmailId', getDomValue('.GenericFormemail')); // can be replace with formDetails.Email
      fileData.append('AllInclusive', `{${getAllInclusiveId()}}`);
      if (getFormName() === 'CustomEmail') {
        for (const prop in formDetails) {
          fileData.append('Extras[]', formDetails[prop]);
          // if (Object.keys(formDetails)[Object.keys(formDetails).length - 1] === prop) {
          //   // postEmailData(dispatch, fileData);
          // }
        }
      }
      postEmailData(dispatch, fileData);
      //PBL-1870 Temporary Fix till email api issue is fixed, remove below line after fix.
      updateEmailResponse();
    }
  };
  const sendFanFeedBack = () => {
    try {
      const feedbackData = JSON.stringify({
        Title: formDetails.Honorific,
        FirstName: formDetails.FirstName,
        Surname: formDetails.Surname,
        Email: getUserEmail(),
        SubjectLine: `${RepresentativeName && RepresentativeName.value}: ${
          Subject && Subject.value
        }`,
        QuestionDetails: formDetails.QuestiontoRaise,
        FanFormReference: getFeedbackId(),
      });
      postFeedbackData(dispatch, feedbackData);
    } catch (err) {
      console.log('Error in Feedback Data');
    }
  };
  const updatePersistReportData = (genericPersistReportData) => {
    if (EMailCheckBox && EMailCheckBox.value) {
      trackForm();
      sendUploadedFile();
    } else if (getFormName() === 'FanFormFeedBack' && getFeedbackId() != '') {
      sendFanFeedBack();
    } else {
      updateFormContainer();
    }
  };
  const postPersistReportData = () => {
    try {
      let dataObj = {};
      let genformData = {};
      genformData['submitTime'] = new Date();
      if (getFormName() === 'FanFormFeedBack' && getFeedbackId() !== '') {
        genformData['submitTime'] = `${RepresentativeName && RepresentativeName.value} : ${
          Subject && Subject.value
        }`;
      }
      if (FormType && FormType.value) {
        genformData['formType'] = FormType.value;
      }
      if (getName() !== '') {
        genformData['formName'] = getName();
      }
      dataObj[getName()] = [{ ...formDetails, ...genformData }];
      const email = formDetails.Email ? formDetails.Email : getDomValue('.GenericFormemail');
      const postObj = `type=MuGenericReports&updateBehavior=arrayPush&oid=${email}&data=${JSON.stringify(
        dataObj
      )}`;
      fetchGenericPersistReport(dispatch, postObj);
    } catch (e) {
      console.log('error in submit data');
    }
  };
  const formSubmitCheck = () => {
    if (Captcha && Captcha.value) {
      const captchRes = grecaptcha && grecaptcha.getResponse();
      if (captchRes && captchRes.length == 0) {
        return false;
      } else {
        postPersistReportData();
      }
    } else {
      postPersistReportData();
    }
  };
  const getName = () => {
    return manutds && manutds.value && manutds.value.__Name
      ? manutds.value.__Name.replace(/\s/g, '')
      : '';
  };
  const getUserEmail = () => {
    return userEmail ? userEmail : formDetails.Email;
  };
  const getAllInclusiveId = () => {
    return manutds && manutds.value && manutds.value.Id ? manutds.value.Id : null;
  };
  const getGenericValidId = () => {
    const days = DuplicateCheckDays && DuplicateCheckDays.value;
    return days ? parseInt(days) : '';
  };
  const validateFields = (value, item, keyArray) => {
    let error = false;
    for (let i = 0; i < keyArray.length; i++) {
      error = individualMapping[keyArray[i]] ? individualMapping[keyArray[i]](value, item) : false;
      if (error) {
        break;
      }
    }
    return error;
  };

  const getValidationOnSubmit = (e) => {
    try {
      let obj = {};
      let isValid = true;
      const target = e && e.target;
      const SelectField = target && target.querySelectorAll('select');
      const InputTextField = target && target.querySelectorAll('input[type=text]');
      const InputEmailField = target && target.querySelectorAll('input[type=email]');
      const InputRadioField = target && target.querySelectorAll('ul[type=radio]'); //('input[type=radio]');
      const DatePickerField = target && target.querySelectorAll('div.genericform-datepicker'); //'input[type=date]');
      const TextAreaField = target && target.querySelectorAll('textarea');
      const FileUploadField = target && target.querySelectorAll('input[type=file]');
      const InputCheckBoxField = target && target.querySelectorAll('input[type=checkbox]');
      const array = [
        ...InputTextField,
        ...InputEmailField,
        ...SelectField,
        ...TextAreaField,
        ...InputRadioField,
        ...FileUploadField,
        ...InputCheckBoxField,
      ];
      array &&
        array.forEach((b) => {
          const validationObject = JSON.parse(b && b.getAttribute('data-validation-object'));
          const name = b && b.getAttribute('name');
          let keyArray = validationObject && Object.keys(validationObject);
          let value = b.value;
          if (b && b.type === 'radio') {
            const a = b && b.querySelector('input[data-radio-checked=true]');
            value = a && a.value ? a.value : false;
          }
          if (b && b.type === 'checkbox') {
            value = b.checked;
          }
          let error = keyArray ? validateFields(value, validationObject, keyArray) : false;
          if (b.getAttribute('type') === 'file' && !error && !checkValidFile) {
            error = formError[name];
          }
          if (isValid && error) {
            isValid = false;
            if (b && b.type === 'radio') {
              b.querySelector('input[type=radio]') && b.querySelector('input[type=radio]').focus();
            } else {
              b && b.focus();
            }
          }
          obj = { ...obj, [name]: error ? error : false };
        });
      DatePickerField &&
        DatePickerField.forEach((b) => {
          const validationObject = JSON.parse(b && b.getAttribute('data-validation-object'));
          const name = b && b.getAttribute('name');
          let c = b.querySelector('input[type=date]');
          b.value = c ? c.value : false;
          let keyArray = validationObject && Object.keys(validationObject);
          let error = keyArray ? validateFields(b.value, validationObject, keyArray) : false;
          if (isValid && error) {
            isValid = false;
            c && c.focus();
          }
          obj = { ...obj, [name]: error ? error : false };
        });
      setFormError({ ...formError, ...obj });
      return isValid;
    } catch (e) {
      console.log('Validation failed on submit');
    }
  };
  const submitHandler = (e) => {
    e.preventDefault();
    setInitial(false);
    if (getValidationOnSubmit(e)) {
      const GenericValidId = getGenericValidId();
      if (isNaN(GenericValidId) || GenericValidId === '') {
        formSubmitCheck(formDetails, setFormDetails);
      } else {
        fetchSearchReport(dispatch, getName(), getUserEmail());
      }
    } else {
      console.log('Please Fill All Required Fields ');
    }
  };
  const validateFile = (e, item) => {
    const files = e.target.files && e.target.files[0];
    setSelectedFile(files);
    const attachedFileSize = AttachmentFileSizeMB && AttachmentFileSizeMB.value;
    let fileExtArray = [];
    const fileExtArr = AttachmentFileTypes && AttachmentFileTypes.length > 0 && AttachmentFileTypes;
    fileExtArr &&
      fileExtArr.forEach((a) => {
        a.fields &&
          a.fields.name &&
          a.fields.name.value &&
          fileExtArray.push(a.fields.name.value.toLowerCase());
      });
    const fileExt =
      files && files.name && files.name.substr(files.name.lastIndexOf('\\') + 1).split('.')[1];
    const uploadFileName = files && files.name;
    let fileSize = files && files.size;
    let error = false;
    if (fileExt && fileExtArr) {
      if (fileExtArray.includes(`.${fileExt}`)) {
        error = false;
        if (fileSize) {
          fileSize = fileSize / 1048576;
          if (fileSize <= attachedFileSize) {
            setCheckValidFile(true);
            setFileName(uploadFileName);
            error = false;
          } else {
            setCheckValidFile(false);
            setFileName(null);
            error =
              AttachmentFileSizeErrorMessege && AttachmentFileSizeErrorMessege.value
                ? AttachmentFileSizeErrorMessege.value
                : false;
          }
        } else {
          setCheckValidFile(false);
          setFileName(null);
          error =
            AttachmentFileSizeErrorMessege && AttachmentFileSizeErrorMessege.value
              ? AttachmentFileSizeErrorMessege.value
              : false;
        }
      } else {
        setCheckValidFile(false);
        setFileName(null);
        error =
          AttachmentFileTypeErrorMessege && AttachmentFileTypeErrorMessege.value
            ? AttachmentFileTypeErrorMessege.value
            : false;
      }
    } else {
      setCheckValidFile(false);
      setFileName(null);
      error =
        AttachmentFileTypeErrorMessege && AttachmentFileTypeErrorMessege.value
          ? AttachmentFileTypeErrorMessege.value
          : false;
    }
    let requiredError = DataRequiredValidation(files, item);
    if (item.True && requiredError) {
      error = requiredError;
    }
    setFormError({ ...formError, [e.target.name]: error ? error : false });
  };

  const setScreenReaderValue = (e) => {
    if (e) {
      setScreenReaderText(`${moment.parseZone(e).format('DD MMMM YYYY')} selected`);
      setTimeout(() => setScreenReaderText(''), 300);
    }
  };

  const onChangeFunction = (e, item, validation, type) => {
    if (type && type === 'DatePicker') {
      setFormDetails({ ...formDetails, [item]: e });
      setFormError({
        ...formError,
        [item]: e ? false : validation ? validation.DataRequiredMessage : '',
      });
      e && setScreenReaderValue(e);
    } else {
      if (e.target && e.target.type === 'file') {
        validateFile(e, item);
      } else {
        let keyArray = Object.keys(validation);
        let value = e.target.value;
        if (e.target && e.target.type === 'checkbox') {
          value = e.target.checked ? 'True' : 'False';
        }
        let error = item && validation ? validateFields(value, item, keyArray) : false;
        setFormError({ ...formError, [e.target.name]: error ? error : false });
        setFormDetails({ ...formDetails, [e.target.name]: value });
      }
    }
  };

  const individualMapping = {
    DataRequiredMessage: DataRequiredValidation,
    NumericValidationMessage: NumericValidation,
    MinNumberMessage: MinLengthValidation,
    MaxNumberMessage: MaxLengthValidation,
    Email: EmailValidation,
    AlphaNumericMessage: AlphaNumericValidation,
  };
  const getFormName = () => {
    if (IsCustomisedEmail && IsCustomisedEmail.value) {
      return 'CustomEmail';
    } else if (IsFanFeedBack && IsFanFeedBack.value && getFeedbackId()) {
      return 'FanFormFeedBack';
    }
    return '';
  };
  const getFormsDescription = () => {
    if (
      IsFanFeedBack &&
      IsFanFeedBack.value &&
      getFeedbackId() &&
      RepresentativeName &&
      RepresentativeName.value &&
      Description &&
      Description.value
    ) {
      const replaceValue = Description.value.replace(
        new RegExp('__name__', 'g'),
        RepresentativeName.value
      );
      return <RichText field={{ value: replaceValue }} />;
    } else {
      return <RichText field={Description} />;
    }
  };
  const getFeedbackId = () => {
    return MU && MU.queryString ? MU.queryString.id : null;
  };
  const scrollToUp = () => {
    const yOffset = -200;
    const e = document.getElementById('formContainerList');
    const y = e && e.getBoundingClientRect().top + window.pageYOffset + yOffset;
    y && window.scrollTo({ top: y, behavior: 'smooth' });
  };
  return (
    fields && (
      <React.Fragment>
        {loader && getLoaderHtml()}
        <div
          className="hero__accordian"
          data-module="navShowMore"
          data-classnameobj="{'grid':'heroaccordion','cropType':'components'}"
        >
          <div className="home-hero card-xl">
            <div className="img-holder">
              {Image && Image.value && Image.value.Crops && (
                <LazyLoad once={true} offset={100}>
                  <ImgCrop imgCrop={Image.value.Crops} alt="" title=""></ImgCrop>
                </LazyLoad>
              )}
            </div>
            <div className="hero__accordian__intro">
              <div className="contact-us__block">
                {Title && Title.value && (
                  <h1 className="home-hero__title" data-share-title="">
                    <RichText tag="span" field={Title} />
                  </h1>
                )}
              </div>
            </div>
          </div>
          <section className="container unitedForm">
            <div className="contact-us__block">
              <div
                className="hero__accordian--content"
                data-module="navShowMoreContent"
                tabIndex="0"
                data-navshowmoreflag="true"
                aria-label="View More Contact Details"
                style={showHideHero}
              >
                {getFormsDescription()}
              </div>
            </div>
          </section>
        </div>
        <div>
          <section
            className="container generic-form"
            data-module="generic-form"
            data-an-track="true"
            data-track-type="form"
          >
            <div className="contact-us__block">
              <div className="travel__container genericformhide" style={showHideForm}>
                <form
                  action=""
                  id="united_form"
                  data-module={getFormName()}
                  data-feedbackid={getFeedbackId()}
                  data-rep-name={RepresentativeName && RepresentativeName.value}
                  onSubmit={(e) => submitHandler(e)}
                  onReset={(e) => e.target && resetFormDetails(e.target)}
                >
                  {All_Inclusive_Options &&
                    All_Inclusive_Options.length > 0 &&
                    All_Inclusive_Options.map((item, index) => (
                      <React.Fragment key={index}>
                        {/* {item.fields &&
                          item.fields.HeadingCheck &&
                          item.fields.HeadingCheck.value && (
                            <div className="generic_heading">{item.fields.Title.value}</div>
                          )} */}
                        <div className="formField">
                          <GenericConditional
                            data={item}
                            index={index}
                            fields={fields}
                            t={t}
                            onChangeFunction={onChangeFunction}
                            formError={formError}
                            initial={initial}
                            formDetails={formDetails}
                            isUserLogin={isUserLogin}
                            fileName={fileName}
                          />
                        </div>
                      </React.Fragment>
                    ))}
                  {Captcha && Captcha.value && (
                    <div className="grid-12 generic-checkbox_con" id="genricCaptcha">
                      <div id="dvCaptcha" className="field-area">
                        <div
                          className="g-recaptcha"
                          data-callback="recaptcha_callback"
                          data-sitekey={MU && MU.manutdGoogleSiteKey}
                        ></div>
                      </div>
                      <p id="captchaMessage" className="field-area"></p>
                      <p className="captch-error" role="alert">
                        Invalid Captcha
                      </p>
                    </div>
                  )}
                  <input
                    type="hidden"
                    name="formType"
                    id="GenericFormTypeId"
                    value={FormType && FormType.value}
                  />
                  <input type="hidden" name="formName" id="GenericFormNameId" value={getName()} />
                  <div className="grid-12 generic_btnCon">
                    <input
                      type="submit"
                      aria-invalid="false"
                      aria-label={ButtonText && ButtonText.value}
                      className="generic_skewBtn gen_submitBtn"
                      value={ButtonText && ButtonText.value}
                      data-track-submit="true"
                    />
                    {ResetButton && ResetButton.value && (
                      <input
                        type="reset"
                        aria-invalid="false"
                        aria-label="Reset"
                        className="generic_skewBtn gen_resetBtn"
                        value="Reset"
                      />
                    )}
                  </div>
                  <div className="grid-12 generic_btnCon">
                    <div
                      className="generic_errormsg"
                      role="alert"
                      style={{ display: `${status === 'error' ? 'block' : 'none'}` }}
                    >
                      Network issue try again
                    </div>
                  </div>
                  <div style={{ clear: 'both' }}></div>
                </form>
              </div>
              <div className="generic_reloginErr" style={reloginErr}>
                <RichText field={DuplicateMessage} />
                <div className="gen_thanks_footer">
                  <RichText field={FooterInformationMessage} />
                </div>
              </div>
              <div className="generic_thanku" style={thankuMsg} id="formContainerList">
                <RichText field={ThankYouMessage} />
                <div className="gen_thanks_footer">
                  <RichText field={FooterInformationMessage} />
                </div>
              </div>
            </div>
          </section>
          <div className="screenreader" aria-live="assertive">
            {screenReaderText}
          </div>
        </div>
      </React.Fragment>
    )
  );
};

export default withTranslation()(React.memo(GenericForm));
