/**
 *  © 2022 -2023 DXC Technology, all rights reserved.
 *  Material published by DXC Technology on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useRef, useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import Slider from 'react-slick';
import moment from 'moment';
import ErrorBoundary from '../Common/ErrorBoundary';
import {
  afterChangeAccessbility,
  beforeChangeAccessbility,
  NextArrowComingUp,
  onInitAccessibility,
  PrevArrowComingUp,
} from '../../utils/carouselUtils';
import MU from '../../constants/muConstants';
import ComingupCard from './ComingupCard';
import { checkDevice, getRandomId, getActiveModalCount } from '../../utils/utils';
import { THRESHOLD } from '../../constants/globalConstants';
import { useSponsorValue } from '../../context/sponsorContext';
import { SPOTLIGHT } from '../../constants/endpoints';
import api from '../../utils/muApi';
import { isLiveByPeriod } from '../../utils/matchUtils';

const ComimgupCarousel = (props) => {
  const { dataResponse, t } = props;
  const [oldDataArr, setOldDataArr] = useState([]);
  const [allDataResp, setAllDataResp] = useState([]);
  const [allDataResponse, setAllDataResponse] = useState([]);
  const [spotlightResponseData, setSpotlightResponseData] = useState(null);
  const {
    OldComingUpEventsResponse,
    MatchResultDataResponse,
    ComingUpCarouselDataResponse,
    ComingUpCarouselResponse,
  } = dataResponse;
  const [{ isGigyaAvilable, userLoginDetail, isUserLogin }, dispatch] = useSponsorValue();

  const [userInfo, setUserInfo] = useState({});
  const [userBdayEnable, setUserBdayEnable] = useState(false);
  const commonResponse = ComingUpCarouselResponse.response.docs[0];
  const [favPlayerName, setFavPlayerName] = useState();
  const fetchSpotlight = () => {
    const route = `${SPOTLIGHT}`;
    return api
      .get({ route })
      .then((response) => {
        if (response && response.data) {
          const spotlightResponse =
            response.data.SpotlightResponse &&
            response.data.SpotlightResponse.response &&
            response.data.SpotlightResponse.response.docs[0];
          setSpotlightResponseData(spotlightResponse);
        }
      })
      .catch((err) => {
        console.log('fetchSpotlight err', err);
      });
  };
  const checkLiveMatchTeamLevels = (liveMatchLevels, item) => {
    const cardLevel = item && item.levelunique_s;
    const isLevelsMatch =
      cardLevel && liveMatchLevels.filter((elem) => elem.split('/')[1] === cardLevel);
    return isLevelsMatch.length > 0 ? true : false;
  };
  const isDataValid = (item) => {
    if (moment().isSameOrBefore(item.matchdate_tdt)) {
      return true;
    } else if (
      commonResponse &&
      commonResponse.displaylivematchupdates_b &&
      checkLiveMatchTeamLevels(commonResponse.cominguplivematchteamlevels_sm, item)
    ) {
      if (isLiveByPeriod(item.resultdata_t.Period)) {
        return true;
      } else {
        const womenLiveCardWaitTime =
          commonResponse && commonResponse.womenlivecarddisplayminwhennooptadata_t;
        const u18LiveCardWaitTime =
          commonResponse && commonResponse.u18livecarddisplayminwhennooptadata_t;
        const u21LiveCardWaitTime =
          commonResponse && commonResponse.u21livecarddisplayminwhennooptadata_t;
        let levelLiveWaitTime;
        const levelUnique = item && item.levelunique_s;
        if (levelUnique === 'Women Team') {
          levelLiveWaitTime = womenLiveCardWaitTime;
        } else if (levelUnique === 'Academy') {
          levelLiveWaitTime = u18LiveCardWaitTime;
        } else if (levelUnique === 'Reserves') {
          levelLiveWaitTime = u21LiveCardWaitTime;
        }
        if (levelLiveWaitTime) {
          const extraaTime = moment(item.matchdate_tdt).add(+levelLiveWaitTime, 'minutes');
          if (moment().isSameOrBefore(extraaTime)) {
            return true;
          } else return false;
        } else return false;
      }
    } else return false;
  };
  commonResponse &&
    commonResponse.removespotlightfixture_b &&
    !spotlightResponseData &&
    fetchSpotlight();
  useEffect(() => {
    if (userLoginDetail) {
      const userBday =
        userLoginDetail &&
        userLoginDetail.profile &&
        userLoginDetail.profile.birthDay &&
        userLoginDetail.profile.birthMonth &&
        `${userLoginDetail.profile.birthDay}-${userLoginDetail.profile.birthMonth}`;
      const userFirstName =
        userLoginDetail && userLoginDetail.profile && userLoginDetail.profile.firstName;
      const userPhoto =
        userLoginDetail && userLoginDetail.profile && userLoginDetail.profile.photoURL;
      const userAge = userLoginDetail && userLoginDetail.profile && userLoginDetail.profile.age;
      const userCountry =
        userLoginDetail && userLoginDetail.profile && userLoginDetail.profile.country;
      const userBdayStartTime =
        commonResponse && commonResponse.userbirthdaystarttime_t
          ? commonResponse.userbirthdaystarttime_t
          : '00:00';
      const userBdayEndTime =
        commonResponse && commonResponse.userbirthdayendtime_t
          ? commonResponse.userbirthdayendtime_t
          : '23:59';

      if (userBday) {
        if (userBday === moment().format('D-M')) {
          const currentTime = moment();
          const extra_time = moment().format('YYYY-MM-DD') + ' ';
          const start_time = moment(extra_time + userBdayStartTime);
          const end_time = moment(extra_time + userBdayEndTime);

          if (moment(currentTime).isBetween(start_time, end_time)) {
            const matchdate_tdt = moment().format('YYYY-MM-DD');
            const userBdayInfo = {
              matchdate_tdt,
              userFirstName,
              userPhoto,
              userAge,
              userCountry,
              contenttype_t: 'userBday',
            };
            setUserInfo({ item: userBdayInfo, type: 'userBday' });
            setUserBdayEnable(true);
          } else {
            setUserBdayEnable(false);
          }
        }
      }
      const favPlayer =
        userLoginDetail && userLoginDetail.data && userLoginDetail.data.favoritePlayerID;
      favPlayerName !== favPlayer && setFavPlayerName(favPlayer);
    }
  }, [isGigyaAvilable, isUserLogin, userLoginDetail]);

  let timeoutId;
  const [isTouchDevice, setIsTouchDevice] = useState(true);
  const [uniqueId, setUniqueId] = useState(getRandomId());
  useEffect(() => {
    setIsTouchDevice(checkDevice());
  }, []);
  useEffect(() => {
    const touchDevice = checkDevice();
    if (!touchDevice) {
      window.addEventListener('resize', resizeListener);
    }
    return () => {
      !touchDevice && window.removeEventListener('resize', resizeListener);
    };
  }, []);
  const resizeListener = () => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      const modalCount = getActiveModalCount();
      setIsTouchDevice(checkDevice());
      modalCount === 0 && setUniqueId(getRandomId());
    }, THRESHOLD);
  };
  const refElement = useRef(null);
  const [slicker, setSlicker] = useState({});
  const [screenReaderText, setScreenReaderText] = useState('');
  const slidesCount = () => {
    let count = 0;
    if (allDataResponse.length > 0) {
      count += allDataResponse.length;
    }
    return count;
  };
  const setScreenReaderValue = (index) => {
    const a = index;
    const b = comingSlideShowCount();
    const c = slidesCount();
    if (a + b <= c) {
      setScreenReaderText(`Displayed ${a + 1} of ${a + b} out of ${c}`);
    }
    setTimeout(() => {
      setScreenReaderText('');
    }, 300);
  };
  const nextPrevArrow = (oldIndex, newIndex, slicker, bypass) => {
    setTimeout(() => {
      if (oldIndex < newIndex || bypass) {
        slicker && slicker.prevArrow && slicker.prevArrow.classList.remove('clicked');
        slicker && slicker.nextArrow && slicker.nextArrow.classList.add('clicked');
      }
      if (oldIndex > newIndex) {
        slicker && slicker.prevArrow && slicker.prevArrow.classList.add('clicked');
        slicker && slicker.nextArrow && slicker.nextArrow.classList.remove('clicked');
      }
    }, 100);
  };
  const comingSlideShowCount = () => {
    let slideCount = 3;
    if (typeof window !== 'undefined' && window.innerWidth <= 681) {
      slideCount = 1;
    } else if (
      typeof window !== 'undefined' &&
      window.innerWidth >= 682 &&
      window.innerWidth <= 1039
    ) {
      slideCount = 2;
    } else if (
      typeof window !== 'undefined' &&
      window.innerWidth >= 1040 &&
      window.innerWidth <= 1374
    ) {
      slideCount = 3;
    } else if (
      typeof window !== 'undefined' &&
      window.innerWidth >= 1375 &&
      window.innerWidth <= 1694
    ) {
      slideCount = 4;
    } else {
      slideCount = 5;
    }
    return slideCount;
  };
  const settings = () => {
    return {
      className: 'carousal-list center',
      lazyLoad: 'progressive',
      variableWidth: true,
      infinite: false,
      speed: 400,
      swipe: true,
      accessibility: false,
      slidesToShow: comingSlideShowCount(),
      slidesToScroll: comingSlideShowCount(),
      draggable: false,
      // swipeToSlide: true,
      touchMove: true,
      mobileFirst: true,
      touchThreshold: 20,
      initialSlide: 0,
      rtl: false,
      arabic: MU.language === 'ar' ? true : false,
      prevArrow: <PrevArrowComingUp />,
      nextArrow: <NextArrowComingUp slideShow={comingSlideShowCount()} />,

      onLazyLoad: (e) => {
        if (e.includes(slidesCount() - 1))
          setTimeout(() => {
            if (refElement) {
              const slider = refElement.current;
              let list = slider && slider.querySelector('.slick-list');
              let slideTrack = list && list.querySelector('.slick-track');
              let slides = slideTrack && slideTrack.querySelectorAll('.slick-slide');
              let nextArrow = slider && slider.querySelector('.slider-next.slick-arrow');
              let prevArrow = slider && slider.querySelector('.slider-prev.slick-arrow');
              onInitAccessibility(slideTrack, slides, 'themed');
              setSlicker({
                slider: slider,
                list: list,
                track: slideTrack,
                slides: slides,
                nextArrow: nextArrow,
                prevArrow: prevArrow,
              });
            }
          }, 500);
      },
      beforeChange: (oldIndex, newIndex) => {
        setScreenReaderValue(newIndex);
        nextPrevArrow(oldIndex, newIndex, slicker);
        beforeChangeAccessbility(slicker, 'themed');
      },
      afterChange: () => {
        afterChangeAccessbility(slicker);
      },
    };
  };
  const getSortedOldDataResponse = (value) => {
    const oldEventsList = [];
    const currentTime = moment();
    value.map((i) => {
      if (i.matchdate_tdt && i.comingupend_tdt) {
        if (
          i.contenttype_t.toLowerCase() !== 'matchdata' &&
          moment(currentTime).isSameOrAfter(i.matchdate_tdt) &&
          moment(currentTime).isSameOrBefore(i.comingupend_tdt)
        ) {
          oldEventsList.push({ item: i, type: 'oldResponse' });
        }
        oldEventsList.sort(
          (a, b) => new Date(a.item.matchdate_tdt) - new Date(b.item.matchdate_tdt)
        );
        oldEventsList.sort((a, b) => {
          if (a.item.matchdate_tdt === b.item.matchdate_tdt) {
            const t1 = a.item && a.item.title;
            const t2 = b.item && b.item.title;
            if (t1 && t2) return t1.localeCompare(t2);
          }
        });
        setOldDataArr(oldEventsList);
      }
    });
  };

  const getSortedAllDataResponse = (value) => {
    const weekDate = [];
    const allDataArr = {};
    if (commonResponse.displayuserbirthday_b && userBdayEnable && userInfo) {
      value.push(userInfo);
    }
    value.map((elem) => {
      const resDate =
        elem.item &&
        elem.item.matchdate_tdt &&
        moment(elem.item.matchdate_tdt).format('YYYY-MM-DD');
      if (resDate) {
        if (weekDate.indexOf(resDate) === -1) {
          weekDate.push(resDate);
        }
      }
    });
    const sortedWeekDate =
      weekDate && weekDate.length > 0 && weekDate.sort((a, b) => new Date(a) - new Date(b));
    const currentTime = moment();
    sortedWeekDate &&
      sortedWeekDate.length > 0 &&
      sortedWeekDate.map((i, index) => {
        allDataArr[i] = [];
        value.map((j) => {
          try {
            if (
              j.item &&
              j.item.matchdate_tdt &&
              i === moment(j.item.matchdate_tdt).format('YYYY-MM-DD')
            ) {
              if (j.type === 'userBday') {
                j.key = 1;
                allDataArr[i].push(j);
              } else if (
                j.item &&
                j.item.contenttype_t &&
                (j.item.contenttype_t.toLowerCase() === 'playerprofile' ||
                  j.item.contenttype_t.toLowerCase() === 'managerprofile')
              ) {
                if (commonResponse.displayplayerbirthday_b) {
                  if (
                    moment().format('YYYY-MM-DD') ===
                    moment(j.item.matchdate_tdt).format('YYYY-MM-DD')
                  ) {
                    const playerBdayStartTime =
                      commonResponse && commonResponse.playerdobstarttime_t
                        ? commonResponse.playerdobstarttime_t
                        : '00:00';
                    const playerBdayEndTime =
                      commonResponse && commonResponse.playerdobendtime_t
                        ? commonResponse.playerdobendtime_t
                        : '23:59';

                    const extra_time = moment().format('YYYY-MM-DD') + ' ';
                    const start_time = moment(extra_time + playerBdayStartTime);
                    const end_time = moment(extra_time + playerBdayEndTime);
                    if (moment(currentTime).isBetween(start_time, end_time)) {
                      if (
                        favPlayerName &&
                        j.item &&
                        j.item.playertag_t &&
                        favPlayerName.toLowerCase() === j.item.playertag_t.toLowerCase()
                      ) {
                        j.key = 14;
                        allDataArr[i].push(j);
                      } else {
                        j.key = 15;
                        allDataArr[i].push(j);
                      }
                    }
                  } else {
                    if (moment(currentTime).isSameOrBefore(j.item.matchdate_tdt)) {
                      if (
                        favPlayerName &&
                        j.item &&
                        j.item.playertag_t &&
                        favPlayerName.toLowerCase() === j.item.playertag_t.toLowerCase()
                      ) {
                        j.key = 14;
                        allDataArr[i].push(j);
                      } else {
                        j.key = 15;
                        allDataArr[i].push(j);
                      }
                    }
                  }
                }
              } else if (
                j.item &&
                j.item.contenttype_t &&
                j.item.contenttype_t.toLowerCase() === 'matchdata' &&
                ((j.type === 'resultResponse' && j.item.isresultdeclared_b) ||
                  (j.type === 'allDataResponse' && j.item.isresultdeclared_b)) &&
                j.item.comingupenddate_s
              ) {
                if (moment(currentTime).isSameOrBefore(j.item.comingupenddate_s)) {
                  if (j.item.level_s === 'First Team') {
                    j.key = 8;
                  } else if (j.item.level_s === 'MU Women') {
                    j.key = 9;
                  } else if (j.item.level_s === 'Under-18s') {
                    j.key = 10;
                  } else if (j.item.level_s === 'Under-19s') {
                    j.key = 11;
                  } else if (j.item.level_s === 'Under-21s') {
                    j.key = 12;
                  } else if (j.item.level_s === 'Under-23s') {
                    j.key = 13;
                  }
                  allDataArr[i].push(j);
                }
              } else if (
                j.item &&
                j.item.contenttype_t &&
                j.item.contenttype_t.toLowerCase() === 'matchdata' &&
                j.type === 'allDataResponse' &&
                !j.item.isresultdeclared_b
              ) {
                if (isDataValid(j.item)) {
                  if (
                    spotlightResponseData &&
                    spotlightResponseData.opta_mid_t &&
                    j.item &&
                    j.item.opta_mid_t
                  ) {
                    if (spotlightResponseData.opta_mid_t === j.item.opta_mid_t) {
                      return true;
                    } else {
                      if (j.item.level_s === 'First Team') {
                        j.key = 2;
                      } else if (j.item.level_s === 'MU Women') {
                        j.key = 3;
                      } else if (j.item.level_s === 'Under-18s') {
                        j.key = 4;
                      } else if (j.item.level_s === 'Under-19s') {
                        j.key = 5;
                      } else if (j.item.level_s === 'Under-21s') {
                        j.key = 6;
                      } else if (j.item.level_s === 'Under-23s') {
                        j.key = 7;
                      }
                      allDataArr[i].push(j);
                    }
                  } else {
                    if (j.item.level_s === 'First Team') {
                      j.key = 2;
                    } else if (j.item.level_s === 'MU Women') {
                      j.key = 3;
                    } else if (j.item.level_s === 'Under-18s') {
                      j.key = 4;
                    } else if (j.item.level_s === 'Under-19s') {
                      j.key = 5;
                    } else if (j.item.level_s === 'Under-21s') {
                      j.key = 6;
                    } else if (j.item.level_s === 'Under-23s') {
                      j.key = 7;
                    }
                    allDataArr[i].push(j);
                  }
                }
              } else {
                if (
                  moment(currentTime).isSameOrBefore(j.item.matchdate_tdt) ||
                  (moment(currentTime).isSameOrAfter(j.item.matchdate_tdt) &&
                    moment(currentTime).isSameOrBefore(j.item.comingupend_tdt))
                ) {
                  j.key = 16;
                  allDataArr[i].push(j);
                }
              }
            }
          } catch (e) {
            console.error('Error in ComingUpCarouselDataResponse');
          }
        });
      });

    const totalData = [];
    Object.keys(allDataArr).length !== 0 &&
      Object.entries(allDataArr).map((elem) => {
        if (elem[1].length !== 0) {
          const sortedPlayerBday = [];
          const sortedEventsByAlpha = [];
          elem[1].map((item) => {
            if (item.key === 15) {
              sortedPlayerBday.push(item);
            } else if (item.key === 16) {
              sortedEventsByAlpha.push(item);
            }
          });
          const filteredArr = elem[1].filter((item) => item.key !== 15 && item.key !== 16);
          if (sortedPlayerBday && sortedPlayerBday.length > 0) {
            sortedPlayerBday.sort((a, b) => {
              const t1 = a.item && a.item.firstname_t;
              const t2 = b.item && b.item.firstname_t;
              if (t1 && t2) return t1.localeCompare(t2);
            });
          }
          if (sortedEventsByAlpha && sortedEventsByAlpha.length > 0) {
            sortedEventsByAlpha.sort((a, b) => {
              if (a.item.matchdate_tdt === b.item.matchdate_tdt) {
                const t1 = a.item && a.item.title;
                const t2 = b.item && b.item.title;
                if (t1 && t2) return t1.localeCompare(t2);
              }
            });
          }

          const mergedArr = [...filteredArr, ...sortedPlayerBday, ...sortedEventsByAlpha];
          mergedArr
            .sort((a, b) => a.key - b.key)
            .map((arr) => {
              totalData.push(arr);
            });
        }
      });
    setAllDataResponse((oldData) => [...oldData, ...totalData]);
  };

  useEffect(() => {
    const matchDataArr = [];
    const allTypeDataArr = [];
    MatchResultDataResponse &&
      MatchResultDataResponse.response &&
      MatchResultDataResponse.response.docs &&
      MatchResultDataResponse.response.docs.length > 0 &&
      MatchResultDataResponse.response.docs.map((i) => {
        matchDataArr.push({ item: i, type: 'resultResponse' });
      });
    setAllDataResp((oldData) => [...oldData, ...matchDataArr]);

    ComingUpCarouselDataResponse &&
      ComingUpCarouselDataResponse.response &&
      ComingUpCarouselDataResponse.response.docs &&
      ComingUpCarouselDataResponse.response.docs.length > 0 &&
      ComingUpCarouselDataResponse.response.docs.map((i) => {
        allTypeDataArr.push({ item: i, type: 'allDataResponse' });
      });
    setAllDataResp((oldData) => [...oldData, ...allTypeDataArr]);
  }, [dataResponse]);

  useEffect(() => {
    setAllDataResponse([]);
    OldComingUpEventsResponse &&
      OldComingUpEventsResponse.response &&
      OldComingUpEventsResponse.response.docs &&
      OldComingUpEventsResponse.response.docs.length > 0 &&
      getSortedOldDataResponse(OldComingUpEventsResponse.response.docs);

    allDataResp && allDataResp.length > 0 && getSortedAllDataResponse(allDataResp);
  }, [dataResponse, allDataResp, favPlayerName, userBdayEnable, userInfo]);

  const renderMobileView = () => {
    return (
      <React.Fragment>
        <div className={`custom-mobile-slider`}>
          {oldDataArr &&
            oldDataArr.length > 0 &&
            oldDataArr.map((elem, i) => (
              <div className="mobile-listitem" key={i}>
                <div className="carousal-card small-devices">
                  <ComingupCard
                    key={i}
                    item={elem.item}
                    type={elem.type}
                    commonResponse={commonResponse}
                  />
                </div>
              </div>
            ))}
          {allDataResponse.length > 0 &&
            allDataResponse.map((elem, i) => (
              <div className="mobile-listitem" key={i}>
                <div className="carousal-card small-devices">
                  <ComingupCard
                    key={i}
                    item={elem.item}
                    type={elem.type}
                    commonResponse={commonResponse}
                  />
                </div>
              </div>
            ))}
        </div>
      </React.Fragment>
    );
  };

  const rednerDesktopView = () => {
    return (
      <React.Fragment>
        <Slider {...settings()}>
          {oldDataArr &&
            oldDataArr.length > 0 &&
            oldDataArr.map((elem, i) => (
              <div className="carousal-card" key={i}>
                <ComingupCard
                  key={i}
                  item={elem.item}
                  type={elem.type}
                  commonResponse={commonResponse}
                />
              </div>
            ))}

          {allDataResponse &&
            allDataResponse.length > 0 &&
            allDataResponse.map((elem, i) => (
              <div className="carousal-card" key={i}>
                <ComingupCard
                  key={i}
                  item={elem.item}
                  type={elem.type}
                  commonResponse={commonResponse}
                />
              </div>
            ))}
        </Slider>
      </React.Fragment>
    );
  };

  return (
    <ErrorBoundary>
      {allDataResponse.length > 0 && (
        <div className="carousel-list" ref={refElement} key={uniqueId}>
          {!isTouchDevice && rednerDesktopView()}
          {isTouchDevice && renderMobileView()}
          {!isTouchDevice && (
            <div className="screenreader" aria-live="assertive">
              {screenReaderText}
            </div>
          )}
        </div>
      )}
    </ErrorBoundary>
  );
};

export default withTranslation()(ComimgupCarousel);
