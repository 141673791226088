/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useRef, useState } from 'react';
import { insertAdobeScript } from '../../../lib/AdobeAnalyticsPlugin';
import { nonEssCookies, nonEssLocalStorage } from '../../../constants/globalConstants';
import { dialog, openCloseCookieDialog } from './CookiePolicyAccessibility';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { useSponsorValue } from '../../../context/sponsorContext';
import { checkDevice, isApp, getCookie, updateHead } from '../../../utils/utils';
import { callBraze } from '../../../utils/brazeUtil';
const CookieConsent = (props) => {
  const {
    title,
    cookiePolicyMessage,
    confirmationButtonText,
    settingsButtonText,
    heading,
    description,
    essentialCookiesText,
    essentialCookiesDetails,
    alwaysOn,
    nonEssentialCookiesText,
    nonEssentialCookiesDetails,
    offText,
    onText,
    cancelButtonText,
    frequency,
    layoutId,
    context,
    rejectAll,
    manage,
    currentPage,
  } = props;

  const [{ isUserLogin, userLoginDetail }, dispatch] = useSponsorValue();
  const on = (onText && onText.toLowerCase()) || 'on';
  const off = (offText && offText.toLowerCase()) || 'off';
  const aboutCookieMesgEl = useRef(null);
  const dialogAboutEl = useRef(null);
  const dialogEl = useRef(null);
  const overlayEl = useRef(null);
  const focusedElBeforeOpen = useRef(null);
  const firstFocusable = useRef(null);
  const lastFocusable = useRef(null);
  const focusableList = useRef(null);
  const consent = useRef(null);
  const [showHiddenButton, setShowHiddenButton] = useState(false);
  const [isTouchDevice, setIsTouchDevice] = useState(true);
  const [enableCookie, setEnableCookie] = useState(
    context && context.SEO.opengraph.loadBodyScript ? context.SEO.opengraph.loadBodyScript : false
  );
  const [state, setState] = useState({
    consent: false,
    showCookiePolicy: false,
    showManageSetting: false,
    showOverlay: false,
    showConsent: false,
    isHasConsent: false,
  });
  useEffect(() => {
    setShowHiddenButton(true);
    if (window.navigator && window.navigator.cookieEnabled) {
      initCookiePolicy();
      openCloseCookieDialog(
        '.open-dialog',
        '.close-dialog',
        setState,
        manageSettings,
        overlayEl,
        dialogEl,
        focusableList,
        firstFocusable,
        lastFocusable,
        focusedElBeforeOpen
      );
    }
  }, []);
  const initCookiePolicy = () => {
    const getCookieFlag = getConsentCookie('userConsentCookiePolicy');
    setState((state) => ({
      ...state,
      showCookiePolicy: getCookieFlag === '' || getCookieFlag === 'null' ? true : false,
    }));
    if (!getCookieFlag || getCookieFlag === 'null') {
      setState((state) => ({
        ...state,
        showConsent: true,
      }));
      legalConsentOnLoad();
      setBodyTag(true);
      setTimeout(() => {
        dialog(
          setState,
          manageSettings,
          overlayEl,
          dialogAboutEl,
          focusableList,
          firstFocusable,
          lastFocusable,
          focusedElBeforeOpen
        );
        aboutCookieMesgEl && aboutCookieMesgEl.current && aboutCookieMesgEl.current.focus();
      }, 500);
    } else {
      if (getCookieFlag === 'on') {
        enableLegalConsent();
      } else {
        disableLegalConsent();
      }
      setState((state) => ({
        ...state,
        showCookiePolicy: false,
        consent: getCookieFlag === 'on' ? true : false,
        isHasConsent: true,
      }));
      setBodyTag(false);
    }
  };

  useEffect(() => {
    setIsTouchDevice(checkDevice());
  }, []);

  const setBodyTag = (type) => {
    const body = document.getElementsByTagName('body');
    if (type && body) {
      setState((state) => ({
        ...state,
        showOverlay: true,
      }));
      body && body[0] && (body[0].style.overflow = 'hidden');
    } else {
      setState((state) => ({
        ...state,
        showOverlay: false,
      }));
      body && body[0] && (body[0].style.overflow = null);
    }
  };

  const enableLegalConsent = () => {
    enableCookie && updateHead();
    const userConsent_off = getCookie('userConsentCookiePolicy') === 'off';
    if (!userConsent_off) {
      insertAdobeScript(layoutId);
    }
    dispatch && dispatch({ type: 'SET_USER_CONSENT', response: true });
    window.googletag && window.googletag.pubads && window.googletag.pubads().setCookieOptions(0);
    if (typeof window.Kameleoon != 'undefined' && typeof window.Kameleoon != undefined) {
      window.Kameleoon && window.Kameleoon.API.Core.enableLegalConsent();
    }
    return '';
  };
  const disableLegalConsent = () => {
    deleteCookies();
    deleteLocalStorage();
    deleteScriptTags();
    window._satellite = undefined;
    window.googletag && window.googletag.pubads && window.googletag.pubads().setCookieOptions(1);
    if (typeof window.Kameleoon != 'undefined' && typeof window.Kameleoon != undefined) {
      window.Kameleoon && window.Kameleoon.API.Core.disableLegalConsent();
    }
  };
  const deleteScriptTags = () => {
    document.querySelectorAll('.third-party-scripts').forEach((s, b) => {
      s.remove();
    });
  };
  const deleteCookies = () => {
    for (let i = 0; i < nonEssCookies.length; i++) {
      document.cookie = nonEssCookies[i] + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;';
      document.cookie =
        nonEssCookies[i] + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=.manutd.com;';
      document.cookie =
        nonEssCookies[i] +
        '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=' +
        window.location.hostname;
      document.cookie =
        nonEssCookies[i] + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=www.manutd.com;';
      document.cookie =
        nonEssCookies[i] + '=; path=/; domain=.manutd.com; expires=' + new Date(0).toUTCString();
    }
    return '';
  };
  const deleteLocalStorage = () => {
    for (let i = 0; i < nonEssLocalStorage.length; i++) {
      window && window.localStorage && window.localStorage.removeItem(nonEssLocalStorage[i]);
    }
  };
  const legalConsentOnLoad = () => {
    window._satellite = undefined;
    window.googletag && window.googletag.pubads && window.googletag.pubads().setCookieOptions(1);
    if (typeof window.Kameleoon != 'undefined' && typeof window.Kameleoon != undefined) {
      window.Kameleoon && window.Kameleoon.API.Core.disableLegalConsent();
    }
    return '';
  };
  const getConsentCookie = (cname) => {
    const name = cname;
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return '';
  };
  const manageSettings = () => {
    setState((state) => ({
      ...state,
      showConsent: true,
    }));
    const getCookieFlag = getConsentCookie('userConsentCookiePolicy');
    if (!!getCookieFlag) {
      if (getCookieFlag === 'on') {
        setState((state) => ({
          ...state,
          consent: true,
        }));
      } else {
        setState((state) => ({
          ...state,
          consent: false,
        }));
      }
    } else {
      setState((state) => ({
        ...state,
        consent: false,
      }));
    }
    setState((state) => ({
      ...state,
      showManageSetting: true,
      showCookiePolicy: false,
    }));
    setTimeout(() => {
      dialog(
        setState,
        manageSettings,
        overlayEl,
        dialogEl,
        focusableList,
        firstFocusable,
        lastFocusable,
        focusedElBeforeOpen
      );
    }, 500);
    return '';
  };
  const acceptAllCookies = () => {
    updateCookieSettings(true);
    return '';
  };
  const setConsentCookie = (cname, cvalue) => {
    const exdays = frequency ? frequency : null;
    if (exdays) {
      let domain, domainParts, date, expires, host;
      const days = exdays;
      const value = cvalue;
      const name = cname;
      if (days) {
        date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = '; expires=' + date.toGMTString();
      } else {
        expires = '';
      }
      host = window.location.host;
      if (host.split('.').length === 1) {
        document.cookie = name + '=' + value + expires + '; path=/; secure';
      } else {
        domainParts = host.split('.');
        domainParts.shift();
        domain = '.' + domainParts.join('.');
        document.cookie = name + '=' + value + expires + '; path=/; domain=' + domain + '; secure';
        if (getConsentCookie(name) === 'null' || getConsentCookie(name) !== value) {
          domain = '.' + host;
          document.cookie =
            name + '=' + value + expires + '; path=/; domain=' + domain + '; secure';
        }
      }
    } else {
      document.cookie = cname + '=' + cvalue + '; secure';
    }
  };
  const updateCookieSettings = (type) => {
    setBodyTag(false);
    setConsentCookie('userConsentCookiePolicy', type ? 'on' : 'off');
    if (!isApp()) {
      const notification = context?.Notification;
      if (isUserLogin && userLoginDetail) {
        callBraze(userLoginDetail, notification, currentPage);
      } else {
        callBraze('', notification, currentPage);
      }
    }
    if (type) {
      if (document.querySelectorAll('.third-party-scripts').length === 0) {
        enableLegalConsent();
      }
      setState((state) => ({
        ...state,
        showConsent: false,
      }));
    } else {
      disableLegalConsent();
    }
    setState((state) => ({
      ...state,
      showCookiePolicy: false,
      showManageSetting: false,
      consent: type,
    }));
  };
  const consentOnOff = () => {
    setState((state) => ({
      ...state,
      consent: !state.consent,
    }));
    return '';
  };
  const saveMyCookieSettings = () => {
    updateCookieSettings(state.consent);
    return '';
  };
  return (
    <React.Fragment>
      {state.showConsent && (
        <React.Fragment>
          {!state.isHasConsent && (
            <React.Fragment>
              <div
                id="tint-overlay"
                className="dialog-overlay"
                style={{ display: state.showOverlay ? 'block' : 'none' }}
                ref={overlayEl}
              ></div>
              <div
                className="cookie-policy-message cookie-box-wrap"
                role="dialog"
                aria-labelledby="aboutCookieMesgEl"
                style={{ display: state.showCookiePolicy ? 'block' : 'none' }}
                ref={dialogAboutEl}
              >
                <div className="cookie-message__content">
                  {title && (
                    <h2 id="aboutCookieMesgEl" tabIndex="0" ref={aboutCookieMesgEl}>
                      {title}
                    </h2>
                  )}
                  {cookiePolicyMessage && (
                    <div dangerouslySetInnerHTML={{ __html: cookiePolicyMessage }}></div>
                  )}
                </div>
                <div className="cookie-buttons">
                  {manage && settingsButtonText && (
                    <button
                      className="black-btn-skew btn"
                      id="settings-btn"
                      onClick={manageSettings}
                    >
                      {isTouchDevice ? <span>{manage}</span> : <span>{settingsButtonText}</span>}
                    </button>
                  )}
                  {rejectAll && (
                    <button
                      className="black-btn-skew btn"
                      id="reject-btn"
                      onClick={saveMyCookieSettings}
                    >
                      <span>{rejectAll}</span>
                    </button>
                  )}
                  {confirmationButtonText && (
                    <button className="red-btn-skew btn" id="accept-btn" onClick={acceptAllCookies}>
                      <span>{confirmationButtonText}</span>
                    </button>
                  )}
                </div>
              </div>
            </React.Fragment>
          )}
          <div
            className="cookie-setting-message cookie-box-wrap"
            role="dialog"
            aria-label="Manage your cookie settings"
            style={{ display: state.showManageSetting ? 'block' : 'none' }}
            ref={dialogEl}
          >
            <div className="cookie-setting__content">
              {heading && <h2 tabIndex="0">{heading}</h2>}
              {description && <RichText tag="p" field={{ value: description }}></RichText>}
              <div className="cookie-text">
                <div className="cookie-text__wrapper">
                  {essentialCookiesText && <span>{essentialCookiesText}</span>}
                  {essentialCookiesDetails && <p>{essentialCookiesDetails}</p>}
                </div>
                <div className="cookie-controls__button">
                  {alwaysOn && <span>{alwaysOn}</span>}
                  <em className="icon-check-circle"></em>
                </div>
              </div>
              <div className="cookie-controls">
                <div className="cookie-controls__wrapper">
                  {nonEssentialCookiesText && <span>{nonEssentialCookiesText}</span>}
                  {nonEssentialCookiesDetails && <p>{nonEssentialCookiesDetails}</p>}
                </div>
                <button
                  tabIndex="0"
                  role="switch"
                  aria-checked={state.consent ? 'true' : 'false'}
                  id="speakerPower"
                  className={`cookie-controls__tooltip ${state.consent ? 'toggle-on' : ''}`}
                  onClick={(e) => consentOnOff(e)}
                  ref={consent}
                  style={{ background: 'none', border: 0 }}
                  aria-label={state.consent ? on : off}
                  aria-describedby={`${
                    nonEssentialCookiesText + ' ' + nonEssentialCookiesDetails
                  } off`}
                >
                  <span id="toggle-text" style={{ color: '#fff' }}>
                    {state.consent ? on : off}
                  </span>
                  <span className="tooltip-btn">
                    <span className="tooltip-dot"></span>
                  </span>
                </button>
              </div>
            </div>
            <div className="cookie-buttons">
              {cancelButtonText && (
                <button
                  className="black-btn-skew btn"
                  id="cancel-btn"
                  onClick={saveMyCookieSettings}
                >
                  <span>{cancelButtonText}</span>
                </button>
              )}
              {confirmationButtonText && (
                <button className="red-btn-skew btn" id="acceptCookie" onClick={acceptAllCookies}>
                  <span>{confirmationButtonText}</span>
                </button>
              )}
            </div>
          </div>
        </React.Fragment>
      )}
      {showHiddenButton && (
        <div
          id="showconsenthidden"
          tabIndex="-1"
          aria-hidden="true"
          style={{ visibility: 'hidden' }}
          onClick={() => manageSettings()}
        ></div>
      )}
    </React.Fragment>
  );
};
export default React.memo(CookieConsent);
