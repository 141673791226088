/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useState, useRef } from 'react';
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import LazyLoad from 'react-lazyload';
import ImgCrop from '../../Common/ImgCrop';
import {
  buildImgUrl,
  getLiveTimerData,
  getAbbreviationPeriod,
  getKeyValueByName,
  getItemId,
  validateCbr,
  transformLiveStreamViaEPG,
  getParameterByName,
  checkResponseDocs,
  getLoggedInStatus,
} from '../../../utils/utils';
import { withTranslation } from 'react-i18next';
import { MODULE, DARK_THEME, LIGHT_THEME, CONTENT_TYPE } from '../../../constants/globalConstants';
import Sponsor from '../../Common/Sponsor';
import { getMatchDate, getAbbrTimeZone } from '../../../utils/momentDate';
import * as track from '../../../utils/analytics';
import api from '../../../utils/muApi';
import { SPOTLIGHT, LIVESTREAMVIAEPG } from '../../../constants/endpoints';
import { SPOTLIGHT as spotlightContainer } from '../../../constants/containerConstants';
import { calculateTimeLeft } from '../../../utils/matchUtils';
import { cloneDeep } from 'lodash';
import MU from '../../../constants/muConstants';
import ErrorBoundary from '../../Common/ErrorBoundary';
import loadable from '@loadable/component';
import { useSponsorValue } from '../../../context/sponsorContext';
import { useModal } from '../../Common/Modal';
import { REGISTERED, ACCOUNT_SUBSCRIPTION } from '../../../constants/globalConstants';
import moment from 'moment';
import muApi from '../../../utils/muApi';
import Loader from '../../Common/Loader';
import { HERO_VIDEO } from '../../../constants/containerConstants';
const LiveStreamModal = loadable(() => import('../../Modals/VideoModal/LiveStreamModal'));
const RegistrationModal = loadable(() =>
  import('../../Modals/RegistrationModal/RegistrationModal')
);

const SpotlightandEpg = (props) => {
  const { t } = props;
  const { LiveVideoFreeText, LiveVideoRegText, LiveVideoSubsText } = props?.fields;
  const [{ isUserLogin, isLoginChecked, isUserSubscribe, isUserSkyId }] = useSponsorValue();
  const { showModal, closeModal, isModalVisible } = useModal();
  const {
    showModal: cbrShowModal,
    closeModal: cbrCloseModal,
    isModalVisible: cbrModalVisible,
  } = useModal();
  const [successScrollState, setSuccessScrollState] = useState({
    x: '',
    y: '',
  });
  let cronTimeInterval = 30000;
  const IntId = useRef(0);
  const timer = useRef(null);
  const retryRef = useRef(0);
  const [crestErrImg, setCrestErrImg] = useState({ away: false, home: false });
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(spotlightResponse));
  const ctaUrlFromFields = props?.fields?.SpotLight?.value?.Spotlight?.value?.CTAUrl;
  const EPGLiveStreamResponse = props?.fields?.EPG?.value?.EPGLiveStreamResponse;
  const EPGScheduleMetadata =
    EPGLiveStreamResponse &&
    EPGLiveStreamResponse.Response &&
    EPGLiveStreamResponse.Response.schedulemetadata_t &&
    EPGLiveStreamResponse.Response.schedulemetadata_t;
  const formattedSpotlightData = props?.fields?.SpotLight?.value?.Spotlight?.value?.Response;

  const [spotlightResponse, setSpotlightResponse] = useState(
    formattedSpotlightData?.SpotlightResponse?.response?.docs?.[0]
  );
  const [timerResponse, setTimerResponse] = useState(
    formattedSpotlightData?.TimerResponse?.response?.docs?.[0]
  );
  const [contentTypeSpotlight, setContentTypeSpotlight] = useState('');
  const [showLiveModal, setShowLiveModal] = useState(false);
  const getImageDetail = () => {
    return {
      imgCrop: getKeyValueByName(
        spotlightResponse && spotlightResponse.defaultimage_t,
        'PreviewURL'
      ),
      altText: getKeyValueByName(spotlightResponse && spotlightResponse.defaultimage_t, 'AltText'),
      singleSrc: true,
      isWebpAvailable: false,
    };
  };
  const [imageDetail, setImageDetail] = useState(getImageDetail());
  const [imageResponseData, setImageResponseData] = useState();
  const imgRef = useRef({ initialLoad: true, imgArray: [] });
  const intervalRef = useRef(null);
  const [matchDate, setMatchDate] = useState('');
  const timerFunction = () => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      !isExperienceEditorActive() && callApi();
    }, 60000);
  };
  const getData = ({ Response, LiveVideoStreamId }) => {
    if (Response.enablehomepagespotlightvideo_b) {
      timerFunction();
      const destinationUrl =
        props?.fields?.EPG?.value?.EPGLiveStreamResponse?.EPGLiveStreamDestinationUrl;
      return transformLiveStreamViaEPG({
        data: Response,
        LiveVideoStreamId,
        destinationUrl,
      });
    }
    return null;
  };

  const setStateData = () => {
    if (EPGLiveStreamResponse?.Response) {
      const { Response, LiveVideoStreamId } = EPGLiveStreamResponse;
      return getData({
        Response,
        LiveVideoStreamId,
      });
    }
    return null;
  };

  const [state, setState] = useState(setStateData());
  const isLiveStream = state?.fields?.ContentType?.value === 'livestream';
  const destinationUrl = state?.fields?.manutds?.value?.DestinationUrl;
  let ContentAccess, ContentType, manutds;
  if (state?.fields) {
    ContentAccess = state?.fields.ContentAccess;
    ContentType = state?.fields.ContentType;
    manutds = state?.fields.manutds;
  }

  const itemId = getItemId(props);
  const getCbrValue = () => {
    return validateCbr({
      isUserLogin,
      isUserSubscribe,
      contentAccess: ContentAccess && ContentAccess.value,
      contentType: ContentType && ContentType.value,
      manutds,
    });
  };
  const contentAccessStateFunc = () => {
    const cbrValue = state?.fields && getCbrValue();
    if (cbrValue?.accessCheck) {
      return cbrValue.accessCheck;
    } else return null;
  };
  const currentLiveStream = (item) => {
    const startTime = moment(item.startdatetime_tdt);
    const endTime = moment(item.enddatetime_tdt);
    return moment().isBetween(startTime, endTime);
  };

  const onCardClick = (e) => {
    e.stopPropagation();
    setSuccessScrollState({ x: e.pageX, y: e.pageY });
    const showCbr = state?.fields && getCbrValue();
    trackCardForEPGLive(state?.fields, e, showCbr && showCbr.accessCheck);
    if (showCbr && showCbr.value) {
      cbrShowModal(destinationUrl);
    } else {
      if (state) {
        setShowLiveModal(true);
      } else {
        showModal();
      }
    }
  };

  const [mediaStatus, setMediaStatus] = useState({});
  const [liveStreamEnded, setLiveStreamEnded] = useState({ value: false });
  const retriveSuccessCallback = typeof window !== 'undefined' && getParameterByName('state', true);

  useEffect(() => {
    if (retriveSuccessCallback && isLoginChecked) {
      const successCallData = retriveSuccessCallback.split(',');
      if (successCallData && successCallData[0] === 'spotlightEpgLiveVideo') {
        const isSubscribedContent = successCallData[1] && successCallData[1] === 'true';
        const userSubscribed = isUserSubscribe;
        if (!isSubscribedContent || (isSubscribedContent && userSubscribed)) {
          if (state) {
            setShowLiveModal(true);
          } else {
            showModal();
          }
          const url = window.location.href;
          const urlMain = url.split('?')[0] ?? url;
          window.history.replaceState({}, null, urlMain);
        }
      }
    }
  }, [retriveSuccessCallback, isLoginChecked]);

  const callApi = () => {
    const route = LIVESTREAMVIAEPG;
    return muApi
      .get({ route })
      .then((apiData) => {
        let finalData;
        retryRef.current = 0;
        if (apiData && apiData.data) {
          const Response =
            checkResponseDocs(apiData.data.EpgLiveStreamResponse) &&
            apiData.data.EpgLiveStreamResponse.response.docs.find((item) =>
              currentLiveStream(item)
            );
          const LiveVideoStreamId =
            checkResponseDocs(apiData.data.AudioStreamResponse) &&
            apiData.data.AudioStreamResponse.response.docs[0].videostreamid_t;

          finalData =
            Response &&
            getData({
              Response,
              LiveVideoStreamId,
            });
        }
        setState(finalData);
      })
      .catch((err) => {
        retryRef.current = retryRef.current + 1;
        if (retryRef.current > 2) {
          setState(null);
        } else {
          !isExperienceEditorActive() && callApi();
        }
      });
  };

  const renderEndedMsg = () => {
    if (liveStreamEnded && liveStreamEnded.value) {
      return liveStreamEnded.contentAccess === REGISTERED
        ? t('LiveStreamEpgNoRegAccess')
        : t('LiveStreamEpgNoSubAccess');
    }
    return t('LiveStreamEpgEnded');
  };
  let spotlightCss,
    moduleName,
    ctaTitle,
    ctaUrl,
    isAbandoned,
    isPostponed,
    period,
    image,
    optaContentValue,
    competationDetails,
    matchDetails,
    matchType,
    isSecondLeg,
    secondLegAwayTeamAggregateScore,
    secondLegHomeTeamAggregateScore,
    homeTeam,
    awayTeam,
    homeTeamFid,
    awayTeamFid,
    awayTeamName,
    homeTeamName,
    secondLegResultString,
    timeoutId,
    containerName,
    timerSeconds,
    timerMinutes,
    liveTimerData,
    liveHomeTeamScore,
    liveAwayTeamScore;

  const fetchSpotlight = () => {
    const route = `${SPOTLIGHT}`;
    return api
      .get({ route })
      .then((response) => {
        if (response && response.data) {
          const spotlightResponseData = response.data.SpotlightResponse;
          const timerResponseData = response.data.TimerResponse;
          setImageResponseData(response.data.ImageResponse);
          setContentTypeSpotlight(spotlightResponseData?.response?.docs?.[0]?.contenttype_t);
          setSpotlightResponse(spotlightResponseData?.response?.docs?.[0]);
          setTimerResponse(timerResponseData?.response?.docs?.[0]);
        }
      })
      .catch((err) => {
        console.log('fetchSpotlight err', err);
      });
  };

  const setImageResponse = (imageResponseDocs) => {
    if (imgRef.current && imgRef.current.initialLoad) {
      imgRef.current = { initialLoad: false, imgArray: imageResponseDocs };
      populateImages();
    } else {
      imgRef.current = { ...imgRef.current, imgArray: imageResponseDocs };
    }
  };

  const populateImages = () => {
    clearInterval(intervalRef.current);
    let imgIndex = 0;
    const firstImg =
      imgRef.current.imgArray[imgIndex] &&
      imgRef.current.imgArray[imgIndex].celumimagesvariant_s &&
      imgRef.current.imgArray[imgIndex].celumimagesvariant_s.ImageCropUrl;
    firstImg &&
      firstImg.CropUrl &&
      setImageDetail({
        imgCrop: firstImg.CropUrl,
        singleSrc: false,
        isWebpAvailable: firstImg.isWebpAvailable,
        altText: firstImg.AltText,
      });
    imgIndex++;
    intervalRef.current = setInterval(() => {
      const nextImg =
        imgRef.current.imgArray[imgIndex] &&
        imgRef.current.imgArray[imgIndex].celumimagesvariant_s &&
        imgRef.current.imgArray[imgIndex].celumimagesvariant_s.ImageCropUrl;
      nextImg &&
        nextImg.CropUrl &&
        setImageDetail({
          imgCrop: nextImg.CropUrl,
          singleSrc: false,
          isWebpAvailable: nextImg.isWebpAvailable,
          altText: nextImg.AltText,
        });
      if (imgIndex === imgRef.current.imgArray.length - 1) {
        imgIndex = 0;
      } else {
        imgIndex++;
      }
    }, 10000);
  };
  let sponsoData = typeof window !== 'undefined' && window?.sessionStorage?.getItem('sponsorObj');
  let sponsorObj;
  let sponsorDetailInfo;
  if (sponsoData !== null) {
    let moduleSponsor = JSON.parse(sponsoData);
    sponsorObj = moduleSponsor?.ModuleSponsorResponse?.grouped?.modulemappingtype_t?.groups;
    let spotlightSponsor = sponsorObj?.filter(function (group) {
      return group.groupValue === 'spotlightsponsor';
    });
    sponsorDetailInfo = spotlightSponsor?.[0]?.doclist?.docs?.[0]?.sponsordetailinfo_s;
  } else {
    console.log('sponsor error');
  }

  useEffect(() => {
    setContentTypeSpotlight(
      spotlightResponse && spotlightResponse.contenttype_t
        ? spotlightResponse.contenttype_t.toLowerCase()
        : ''
    );
    setMatchDate(spotlightResponse && spotlightResponse.matchdate_tdt);
  }, [spotlightResponse]);

  useEffect(() => {
    clearInterval(IntId.current);
    if (contentTypeSpotlight && contentTypeSpotlight === 'live') {
      cronTimeInterval = 15000;
    } else {
      cronTimeInterval = 30000;
    }
    contentTypeSpotlight &&
      (contentTypeSpotlight === 'result' || contentTypeSpotlight === 'live') &&
      enableCronJob();
    contentTypeSpotlight &&
      (contentTypeSpotlight === 'live' || contentTypeSpotlight === 'result') &&
      !isExperienceEditorActive() &&
      fetchSpotlight();
    return () => clearInterval(IntId.current);
  }, [contentTypeSpotlight]);

  useEffect(() => {
    if (contentTypeSpotlight && contentTypeSpotlight === 'timer') {
      timeoutId = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(spotlightResponse));
      }, 1000);
    }
    return () => clearTimeout(timeoutId);
  });

  useEffect(() => {
    if (imageResponseData) {
      imageResponseData &&
        imageResponseData.response &&
        imageResponseData.response.docs &&
        imageResponseData.response.docs.length > 0 &&
        setImageResponse(imageResponseData.response.docs);
    } else {
      if (EPGScheduleMetadata) {
        EPGScheduleMetadata &&
          EPGScheduleMetadata.Image &&
          EPGScheduleMetadata.Image.CropURL &&
          EPGScheduleMetadata.Image.CropURL.img1x &&
          setImageDetail({
            imgCrop: EPGScheduleMetadata.Image.CropURL.img1x,
            singleSrc: true,
            altText: EPGScheduleMetadata.Image.AltText,
          });
      }
    }
  }, [imageResponseData, EPGLiveStreamResponse]);

  const enableCronJob = () => {
    clearInterval(IntId.current);
    IntId.current = setInterval(() => {
      !isExperienceEditorActive() && fetchSpotlight();
    }, cronTimeInterval);
  };

  const CTAforLiveEPG = () => {
    if (!isUserLogin && ContentAccess && ContentAccess.value === REGISTERED) {
      return (
        LiveVideoRegText &&
        LiveVideoRegText.value && (
          <div className="red-btn-skew primary-btn">
            <a onClick={(e) => onCardClick(e)} role="button">
              <span style={{ outline: 'none' }}>{LiveVideoRegText.value}</span>
            </a>
          </div>
        )
      );
    } else if (!isUserSubscribe && ContentAccess && ContentAccess.value === ACCOUNT_SUBSCRIPTION) {
      return (
        LiveVideoSubsText &&
        LiveVideoSubsText.value && (
          <div className="red-btn-skew primary-btn">
            <a onClick={(e) => onCardClick(e)} role="button">
              <span style={{ outline: 'none' }}>{LiveVideoRegText.value}</span>
            </a>
          </div>
        )
      );
    } else {
      return (
        LiveVideoFreeText &&
        LiveVideoFreeText.value && (
          <div className="red-btn-skew primary-btn">
            <a onClick={(e) => onCardClick(e)} role="button">
              <span style={{ outline: 'none' }}>{LiveVideoFreeText.value}</span>
            </a>
          </div>
        )
      );
    }
  };

  if (spotlightResponse) {
    ctaTitle = spotlightResponse.ctatitle_t;
    ctaUrl = spotlightResponse.ctaurl_t;
    isAbandoned = spotlightResponse.isabandoned_b;
    isPostponed = spotlightResponse.ispostponed_b;

    if (spotlightResponse.contenttype_t) {
      if (spotlightResponse.contenttype_t.toLowerCase() === 'live') {
        spotlightCss = 'live state__live';
        containerName = 'Match clock';
        moduleName = 'matchclock';
      } else if (spotlightResponse.contenttype_t.toLowerCase() === 'timer') {
        spotlightCss = 'countdown state__timer';
        containerName = 'Count Down Timer';
        moduleName = 'countdownclock';
        ctaUrl = ctaUrl + ctaUrlFromFields;
      } else if (spotlightResponse.contenttype_t.toLowerCase() === 'fixture') {
        spotlightCss = 'default state__fixture';
        ctaUrl = ctaUrl + ctaUrlFromFields;
        moduleName = 'matchclock';
      } else if (spotlightResponse.contenttype_t.toLowerCase() === 'result') {
        spotlightCss = 'state__result';
        moduleName = 'matchclock';
      }
    }

    if (spotlightResponse.optacontent) {
      optaContentValue = spotlightResponse.optacontent.value;
      if (optaContentValue && optaContentValue.length > 0) {
        competationDetails = optaContentValue[0];
        if (competationDetails) {
          matchDetails = competationDetails.MatchDataList;
          if (matchDetails != null && matchDetails.length > 0 && matchDetails[0]) {
            if (timerResponse) {
              const timerOptaContent = timerResponse.optacontent;
              if (timerOptaContent && timerOptaContent.Period) {
                period = timerOptaContent.Period.toLowerCase();
              }
            }

            if (!period && matchDetails[0].Period) {
              period = matchDetails[0].Period.toLowerCase();
            }
            if (matchDetails[0].MatchType) {
              matchType = matchDetails[0].MatchType.toLowerCase();
              if (matchType) {
                if (matchType.includes('2nd leg') || matchType.includes('2ndleg')) {
                  isSecondLeg = true;
                  if (matchDetails[0].SecondLegAwayTeamAggregateScore >= 0) {
                    secondLegAwayTeamAggregateScore =
                      matchDetails[0].SecondLegAwayTeamAggregateScore.toString() ||
                      (matchDetails[0].SecondLegAwayTeamAggregateScore.Value &&
                        matchDetails[0].SecondLegAwayTeamAggregateScore.Value.toString());
                  }
                  if (matchDetails[0].SecondLegHomeTeamAggregateScore >= 0) {
                    secondLegHomeTeamAggregateScore =
                      matchDetails[0].SecondLegHomeTeamAggregateScore.toString() ||
                      matchDetails[0].SecondLegHomeTeamAggregateScore.Value ||
                      matchDetails[0].SecondLegHomeTeamAggregateScore.Value.toString();
                  }
                }
              }
            }

            homeTeam = cloneDeep(matchDetails[0].HomeTeam);
            awayTeam = cloneDeep(matchDetails[0].AwayTeam);

            if (homeTeam && homeTeam.FID) {
              homeTeamFid = homeTeam.FID;
            }

            if (homeTeam && homeTeam.TeamName) {
              homeTeamName = homeTeam.TeamName;
              if (!homeTeam.ShortClubName && homeTeamName) {
                homeTeam.ShortClubName = homeTeamName;
              }
            }

            if (awayTeam && awayTeam.FID) {
              awayTeamFid = awayTeam.FID;
            }

            if (awayTeam && awayTeam.TeamName) {
              awayTeamName = awayTeam.TeamName;
              if (!awayTeam.ShortClubName && awayTeamName) {
                awayTeam.ShortClubName = awayTeamName;
              }
            }

            if (contentTypeSpotlight === 'live' || contentTypeSpotlight === 'result') {
              const timerOptaContent = timerResponse && timerResponse.optacontent;
              if (timerOptaContent) {
                timerMinutes = timerOptaContent.TimerMinutes;
                timerSeconds = timerOptaContent.TimerSeconds;
                homeTeam.ShortClubName =
                  matchDetails[0].HomeTeam && matchDetails[0].HomeTeam.ShortClubName;
                awayTeam.ShortClubName =
                  matchDetails[0].AwayTeam && matchDetails[0].AwayTeam.ShortClubName;
                const homeScore =
                  homeTeamFid == 1
                    ? timerOptaContent.GoalByManUtd
                    : timerOptaContent.GoalByOpponent;
                const awayScore =
                  awayTeamFid == 1
                    ? timerOptaContent.GoalByManUtd
                    : timerOptaContent.GoalByOpponent;

                if (contentTypeSpotlight === 'live') {
                  liveHomeTeamScore = homeScore;
                  liveAwayTeamScore = awayScore;
                }
                liveTimerData = getLiveTimerData(timerMinutes, timerSeconds, period);
              }
            }

            if (isSecondLeg && matchDetails[0].ResultType) {
              let winnerTeamName,
                winnerTeamScore,
                looserTeamScore = '';

              const matchWinner =
                matchDetails[0].MatchWinner && matchDetails[0].MatchWinner.toString();
              if (matchWinner === awayTeamFid) {
                winnerTeamName = awayTeamName;
                winnerTeamScore = secondLegAwayTeamAggregateScore;
                looserTeamScore = secondLegHomeTeamAggregateScore;
              } else if (matchWinner === homeTeamFid) {
                winnerTeamName = homeTeamName;
                winnerTeamScore = secondLegHomeTeamAggregateScore;
                looserTeamScore = secondLegAwayTeamAggregateScore;
              }
              if (matchDetails[0].ResultType) {
                if (matchDetails[0].ResultType.toUpperCase() === 'AGGREGATE') {
                  if (winnerTeamName && winnerTeamScore && looserTeamScore) {
                    secondLegResultString = `${winnerTeamName} wins ${winnerTeamScore} - ${looserTeamScore} on aggregate`;
                  }
                } else if (matchDetails[0].ResultType.toUpperCase() === 'AWAY_GOALS') {
                  if (winnerTeamName) {
                    secondLegResultString = `${winnerTeamName} win on away goals`;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  const successCallback = ({ showContent, isSubscribedContent }) => {
    if (showContent) {
      if (!MU.isIDMEnabled) {
        if (state) {
          setShowLiveModal(true);
        } else {
          showModal();
        }
      } else {
        if (state) {
          return ['spotlightEpgLiveVideo', isSubscribedContent];
        } else {
          return [
            'successScroll',
            isSubscribedContent,
            JSON.stringify(successScrollState.x),
            JSON.stringify(successScrollState.y),
          ];
        }
      }
    }
  };
  const trackCard = (e) => {
    const initialData = track.data('spotlight');
    track.analytics(
      {
        ...initialData,
        container_type: 'spotlight',
        item_id: `${homeTeam.ShortClubName} ${t('versus')} ${awayTeam.ShortClubName}`,
        content_type: 'spotlight',
        tag: '',
        created_datetime: '',
        author: '',
        spotlight_name: containerName ? containerName : contentTypeSpotlight,
        destination_url: ctaUrl,
      },
      e
    );
  };
  const trackLink = (e) => {
    const initialData = track.data('link');
    track.analytics(
      {
        ...initialData,
        button_name: ctaTitle,
        destination_url: ctaUrl,
      },
      e
    );
    e.stopPropagation();
  };
  const getEPGLiveAnalyticsData = (fields, contentStatus) => {
    const initialData = track.data('card');
    return {
      ...initialData,
      card_name: fields.ShortHeadline && fields.ShortHeadline.value,
      container_type: HERO_VIDEO,
      item_id: itemId,
      content_type: fields.ContentType,
      content_status: contentStatus,
      subscription_status: getLoggedInStatus({
        isLoginChecked,
        isUserLogin,
        isUserSubscribe,
        isUserSkyId,
      }),
      tag: fields.manutds && fields.manutds.value && fields.manutds.value.Tags,
      created_datetime: fields.__Published && fields.__Published.value,
      author: fields.AuthorName && fields.AuthorName.value,
      destination_url: destinationUrl,
    };
  };
  const trackCardForEPGLive = (fields, e, contentStatus) => {
    track.analytics(getEPGLiveAnalyticsData(fields, contentStatus), e);
  };
  const renderSponsor = () => {
    return sponsorDetailInfo ? (
      <Sponsor
        contentType="spotlight"
        varient=""
        sponsorInfo={sponsorDetailInfo && sponsorDetailInfo}
        grid="grid-12"
        type={CONTENT_TYPE}
        container={containerName}
        itemId={`${homeTeam.ShortClubName} ${t('versus')} ${awayTeam.ShortClubName}`}
        // isRelative={true}
      />
    ) : null;
  };
  /**
   * @method {getSponsorTheam sponsor theme}
   */

  return (
    <>
      {!showLiveModal && formattedSpotlightData && competationDetails && awayTeam && homeTeam && (
        <ErrorBoundary>
          <div className="home-row">
            <div className={`spotlightandepg follow ${spotlightCss}`}>
              <div className="grid-12 ratio">
                <div className="mu-content xlarge">
                  {contentTypeSpotlight === 'result' ? (
                    <span className="screenreader">{t('Matchinformationresult')}</span>
                  ) : (
                    <span className="screenreader">{t('MatchInformation')}</span>
                  )}
                  <div className="img-holder">
                    {imageDetail && imageDetail.imgCrop && (
                      <LazyLoad once={true} offset={100}>
                        <ImgCrop
                          imgCrop={imageDetail.imgCrop}
                          altText={imageDetail.altText}
                          title={imageDetail.altText}
                          // className={'mu-item__gradient'}
                          isWebpAvailable={imageDetail.isWebpAvailable}
                          singleSrc={imageDetail.singleSrc}
                          gridClass={'grid-12'}
                          isUpdateImage={true}
                        ></ImgCrop>
                      </LazyLoad>
                    )}
                  </div>
                  {renderSponsor()}
                  <div className="info">
                    <div className="info__league" role="presentation">
                      <img
                        src={
                          competationDetails.LightBgLogo &&
                          buildImgUrl(competationDetails.LightBgLogo.img1x)
                        }
                        alt={competationDetails.CompetitionName}
                        title=""
                      />
                    </div>
                    <div className="info__matches">
                      {(contentTypeSpotlight === 'live' ||
                        contentTypeSpotlight === 'timer' ||
                        contentTypeSpotlight === 'result') && (
                        <div
                          className={`timer mobile-view ${
                            liveTimerData && liveTimerData.injuryTime ? 'timer-result' : ''
                          }`}
                          role="presentation"
                        >
                          <div className="timer__logo">
                            {moduleName && (
                              <Sponsor
                                type={MODULE}
                                hidePartnerText={true}
                                moduleName={moduleName}
                                theme={LIGHT_THEME}
                                container={spotlightContainer}
                                contentType={contentTypeSpotlight}
                                cardName={`Match Clock`}
                              ></Sponsor>
                            )}
                          </div>
                          {contentTypeSpotlight === 'live' && period && (
                            <>
                              {liveTimerData && !liveTimerData.injuryTime && (
                                <div className="timer__time live-content" id="update-timer">
                                  <div id="timer__time" data-time="true">
                                    <span
                                      className="timer__time-hour"
                                      dangerouslySetInnerHTML={{
                                        __html: liveTimerData && liveTimerData.timerText,
                                      }}
                                    ></span>
                                  </div>
                                </div>
                              )}
                              {liveTimerData && liveTimerData.injuryTime && (
                                <>
                                  <span className="screenreader">
                                    {getAbbreviationPeriod('timeelapsed')}
                                  </span>
                                  <div className="result__time injury-content">
                                    <span
                                      className="timer__time-hour"
                                      dangerouslySetInnerHTML={{
                                        __html: liveTimerData && liveTimerData.timerText,
                                      }}
                                    ></span>
                                    <span className="injury-time">{liveTimerData.injuryTime}</span>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                          {contentTypeSpotlight === 'timer' && (
                            <div className="timer__time timer-content" id="update-timer">
                              <div className="" id="timer__time">
                                <span className="timer__time-hour">
                                  {timeLeft.hours === 'zero' ? '00' : timeLeft.hours}
                                </span>
                                <span className="countdown-seprator">:</span>
                                <span className="timer__time-min">{timeLeft.minutes}</span>
                                <span className="timer__time-sec">{timeLeft.seconds}</span>
                              </div>
                            </div>
                          )}
                          {contentTypeSpotlight === 'result' &&
                            liveTimerData &&
                            liveTimerData.timerText && (
                              <div className="timer__time result-content" id="update-timer">
                                <div id="timer__time" data-time="true">
                                  <span
                                    className="timer__time-hour"
                                    dangerouslySetInnerHTML={{
                                      __html: liveTimerData && liveTimerData.timerText,
                                    }}
                                  ></span>
                                </div>
                              </div>
                            )}
                        </div>
                      )}
                      {isAbandoned && (
                        <p className="info__kick-off mobile-view" role="presentation">
                          <span>{matchDate && getMatchDate(matchDate, 'dddd D MMMM yyyy')}</span>
                          <span className="dot"></span>
                          <span>{t('Matchabandoned')}</span>
                        </p>
                      )}
                      {isPostponed && (
                        <p className="info__kick-off mobile-view" role="presentation">
                          <span>{t('Datetobeconfirmed')}</span>
                        </p>
                      )}
                      {!isAbandoned && !isPostponed && matchDate && (
                        <p className="info__kick-off mobile-view" role="presentation">
                          <span>{getMatchDate(matchDate, 'dddd D MMMM yyyy')}</span>
                          {contentTypeSpotlight !== 'result' && (
                            <>
                              <span className="dot"></span>
                              <span>
                                {t('KickOff')} {getMatchDate(matchDate, 'HH:mm')}{' '}
                                {getAbbrTimeZone()}
                              </span>
                            </>
                          )}
                        </p>
                      )}
                      <div className="teams_info">
                        <div className="team_info homeTeam_info">
                          <span aria-hidden="true" className="team__logo">
                            <img
                              src={
                                homeTeam.TeamCrestImage &&
                                buildImgUrl(homeTeam.TeamCrestImage.img1x)
                              }
                              alt={homeTeam.TeamName}
                              title=""
                              className={`${crestErrImg.home ? 'no-img' : ''} img-responsive`}
                              onError={() => setCrestErrImg({ ...crestErrImg, home: true })}
                            />
                          </span>
                          <span className="team__name">{homeTeam.ShortClubName}</span>
                          {/* for Home team match score --desktop view-- */}
                          {contentTypeSpotlight && contentTypeSpotlight === 'live' && (
                            <div className="match-score desktop-view">
                              <span role="presentation">
                                {(MU && MU.language === 'ar'
                                  ? liveAwayTeamScore
                                  : liveHomeTeamScore) || 0}
                              </span>
                            </div>
                          )}
                          {contentTypeSpotlight && contentTypeSpotlight === 'result' && (
                            <div className="match-score desktop-view">
                              <span role="presentation">
                                {MU && MU.language === 'ar' ? awayTeam.Score : homeTeam.Score}
                              </span>
                            </div>
                          )}
                        </div>

                        {/* match vs text code */}
                        {(contentTypeSpotlight === 'fixture' ||
                          contentTypeSpotlight === 'timer') && (
                          <div className="vs-wrapper">
                            <span className="match-vs mobile-view">{t('VS')}</span>
                            {isSecondLeg &&
                              secondLegAwayTeamAggregateScore &&
                              secondLegHomeTeamAggregateScore && (
                                <div className="agg-score">{`(${t(
                                  'Agg'
                                )} ${secondLegHomeTeamAggregateScore}-${secondLegAwayTeamAggregateScore})`}</div>
                              )}
                          </div>
                        )}

                        {/* match score mbile view --start-- */}
                        {contentTypeSpotlight === 'live' && (
                          <span className="match-score mobile-view">
                            <div className="scores">
                              <span role="presentation">
                                {(MU && MU.language === 'ar'
                                  ? liveAwayTeamScore
                                  : liveHomeTeamScore) || 0}
                              </span>
                              <span role="presentation">-</span>
                              <span role="presentation">
                                {(MU && MU.language === 'ar'
                                  ? liveHomeTeamScore
                                  : liveAwayTeamScore) || 0}
                              </span>
                            </div>
                            {isSecondLeg &&
                            secondLegAwayTeamAggregateScore &&
                            secondLegHomeTeamAggregateScore ? (
                              <div className="agg-score">{`(${t(
                                'Agg'
                              )} ${secondLegHomeTeamAggregateScore}-${secondLegAwayTeamAggregateScore})`}</div>
                            ) : (
                              <div className="agg-score"> </div>
                            )}
                          </span>
                        )}
                        {contentTypeSpotlight && contentTypeSpotlight === 'result' && (
                          <span className="match-score mobile-view">
                            <div className="scores">
                              <span role="presentation">
                                {MU && MU.language === 'ar' ? awayTeam.Score : homeTeam.Score}
                              </span>
                              <span role="presentation">-</span>
                              <span role="presentation">
                                {MU && MU.language === 'ar' ? homeTeam.Score : awayTeam.Score}
                              </span>
                            </div>
                            {isSecondLeg &&
                              secondLegAwayTeamAggregateScore &&
                              secondLegHomeTeamAggregateScore && (
                                <div className="agg-score">{`(${t(
                                  'Agg'
                                )} ${secondLegHomeTeamAggregateScore}-${secondLegAwayTeamAggregateScore})`}</div>
                              )}
                          </span>
                        )}
                        {/* match score mbile view --end-- */}
                        {/* --awayteam info --start-- */}
                        <div className="team_info awayTeam_info">
                          <span className="team__logo">
                            <img
                              src={
                                awayTeam.TeamCrestImage &&
                                buildImgUrl(awayTeam.TeamCrestImage.img1x)
                              }
                              alt={awayTeam.TeamName}
                              title=""
                              className={`${crestErrImg.away ? 'no-img' : ''} img-responsive`}
                              onError={() => setCrestErrImg({ ...crestErrImg, away: true })}
                            />
                          </span>
                          <span className="team__name">{awayTeam.ShortClubName}</span>
                          {/* for Away team match score  --desktop view--*/}
                          {contentTypeSpotlight && contentTypeSpotlight === 'live' && (
                            <div className="match-score desktop-view">
                              <span role="presentation" className="mobile-view">
                                -
                              </span>
                              <span role="presentation">
                                {(MU && MU.language === 'ar'
                                  ? liveHomeTeamScore
                                  : liveAwayTeamScore) || 0}
                              </span>
                            </div>
                          )}
                          {contentTypeSpotlight && contentTypeSpotlight === 'result' && (
                            <div className="match-score desktop-view">
                              <span role="presentation" className="mobile-view">
                                -
                              </span>
                              <span role="presentation">
                                {MU && MU.language === 'ar' ? homeTeam.Score : awayTeam.Score}
                              </span>
                            </div>
                          )}
                        </div>
                        {/* awayteam info --end-- */}
                        {/* agg score desktop  */}
                        {isSecondLeg &&
                          secondLegAwayTeamAggregateScore &&
                          secondLegHomeTeamAggregateScore && (
                            <div className="agg-score desktop-view">{`(${t(
                              'Agg'
                            )} ${secondLegHomeTeamAggregateScore}-${secondLegAwayTeamAggregateScore})`}</div>
                          )}
                      </div>

                      {/* <--start -->desktop timer */}
                      {(contentTypeSpotlight === 'live' ||
                        contentTypeSpotlight === 'timer' ||
                        contentTypeSpotlight === 'result') && (
                        <div
                          className={`timer desktop-view ${
                            liveTimerData && liveTimerData.injuryTime ? 'timer-result' : ''
                          }`}
                          role="presentation"
                        >
                          <div className="timer__logo">
                            {moduleName && (
                              <Sponsor
                                type={MODULE}
                                hidePartnerText={true}
                                moduleName={moduleName}
                                theme={LIGHT_THEME}
                                container={spotlightContainer}
                                contentType={contentTypeSpotlight}
                                cardName={`Match Clock`}
                              ></Sponsor>
                            )}
                          </div>
                          {contentTypeSpotlight === 'live' && period && (
                            <>
                              {liveTimerData && !liveTimerData.injuryTime && (
                                <div className="timer__time live-content" id="update-timer">
                                  <div id="timer__time" data-time="true">
                                    <span
                                      className="timer__time-hour"
                                      dangerouslySetInnerHTML={{
                                        __html: liveTimerData && liveTimerData.timerText,
                                      }}
                                    ></span>
                                  </div>
                                </div>
                              )}
                              {liveTimerData && liveTimerData.injuryTime && (
                                <>
                                  <span className="screenreader">
                                    {getAbbreviationPeriod('timeelapsed')}
                                  </span>
                                  <div className="result__time injury-content">
                                    <span
                                      className="timer__time-hour"
                                      dangerouslySetInnerHTML={{
                                        __html: liveTimerData && liveTimerData.timerText,
                                      }}
                                    ></span>
                                    <span className="injury-time">{liveTimerData.injuryTime}</span>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                          {contentTypeSpotlight === 'timer' && (
                            <div className="timer__time timer-content" id="update-timer">
                              <div className="" id="timer__time">
                                <span className="timer__time-hour">
                                  {timeLeft.hours === 'zero' ? '00' : timeLeft.hours}
                                </span>
                                <span className="countdown-seprator">:</span>
                                <span className="timer__time-min">{timeLeft.minutes}</span>
                                <span className="timer__time-sec">{timeLeft.seconds}</span>
                              </div>
                            </div>
                          )}
                          {contentTypeSpotlight === 'result' &&
                            liveTimerData &&
                            liveTimerData.timerText && (
                              <div className="timer__time result-content" id="update-timer">
                                <div id="timer__time" data-time="true">
                                  <span
                                    className="timer__time-hour"
                                    dangerouslySetInnerHTML={{
                                      __html: liveTimerData && liveTimerData.timerText,
                                    }}
                                  ></span>
                                </div>
                              </div>
                            )}
                        </div>
                      )}
                      {/* <--end--> */}
                      {isAbandoned && (
                        <p className="info__kick-off desktop-view" role="presentation">
                          <span>{matchDate && getMatchDate(matchDate, 'dddd D MMMM yyyy')}</span>
                          <span className="dot"></span>
                          <span>{t('Matchabandoned')}</span>
                        </p>
                      )}
                      {isPostponed && (
                        <p className="info__kick-off desktop-view" role="presentation">
                          <span>{t('Datetobeconfirmed')}</span>
                        </p>
                      )}
                      {!isAbandoned && !isPostponed && matchDate && (
                        <p className="info__kick-off desktop-view" role="presentation">
                          <span>{getMatchDate(matchDate, 'dddd D MMMM yyyy')}</span>
                          {contentTypeSpotlight !== 'result' && (
                            <>
                              <span className="dot"></span>
                              <span>
                                {t('KickOff')} {getMatchDate(matchDate, 'HH:mm')}{' '}
                                {getAbbrTimeZone()}
                              </span>
                            </>
                          )}
                        </p>
                      )}
                      {state && EPGScheduleMetadata && (
                        <div className="info__kick-off live-text" role="presentation">
                          <div className="live-text-container">
                            <span className="icon-livevideo"></span>
                            <i className="icon-live-blink" />
                            {EPGScheduleMetadata &&
                            EPGScheduleMetadata.Title &&
                            EPGScheduleMetadata.Title.toLowerCase().includes('live') ? (
                              <span className="live-now-text">{t('LiveNow')}</span>
                            ) : (
                              <span className="live-now-text">{t('NowPlaying')}</span>
                            )}
                            <span className="dash">:</span>
                          </div>
                          <span
                            className={
                              EPGScheduleMetadata &&
                              EPGScheduleMetadata.Title &&
                              EPGScheduleMetadata.Title.length > 33
                                ? 'marqueeAnimwidth'
                                : ''
                            }
                          >
                            {EPGScheduleMetadata && EPGScheduleMetadata.Title && (
                              <span className="marqueeAnim">
                                <p className="marquee-title">{EPGScheduleMetadata.Title}</p>
                              </span>
                            )}
                          </span>
                        </div>
                      )}
                    </div>
                    {state ? (
                      <div className="spotlight-btn-wrapper">
                        <div className="btn-group">
                          {CTAforLiveEPG()}
                          {ctaTitle && ctaUrl && (
                            <div className="red-btn-skew secondary-btn">
                              <a href={ctaUrl} onClick={(e) => trackLink(e)} role="button">
                                <span style={{ outline: 'none' }}>{ctaTitle}</span>
                              </a>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      ctaTitle &&
                      ctaUrl && (
                        <div className="spotlight-btn-wrapper">
                          <div className="red-btn-skew primary-btn">
                            <a href={ctaUrl} onClick={(e) => trackLink(e)} role="button">
                              <span style={{ outline: 'none' }}>{ctaTitle}</span>
                            </a>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ErrorBoundary>
      )}
      {state && showLiveModal && (
        <div className="home-row">
          <div className={`home-hero grid-12 ratio ${state && 'live-card'}`}>
            <ErrorBoundary>
              <LiveStreamModal
                itemid={itemId}
                showModal={showLiveModal}
                videoData={state?.fields}
                onModalClose={() => setShowLiveModal(false)}
                // is360Video={is360Video}
                isLiveStream={isLiveStream}
                playerStop={setShowLiveModal}
                contentAccessState={contentAccessStateFunc()}
                isLiveStreamViaEPG={true}
                LiveStreamViaEPGCallback={setMediaStatus}
                setLiveStreamEnded={setLiveStreamEnded}
              />
            </ErrorBoundary>
          </div>
        </div>
      )}
      {((liveStreamEnded && liveStreamEnded.value) ||
        (!state && mediaStatus && mediaStatus.playing)) && (
        <div className="home-row">
          <div className={`home-hero grid-12 ratio ${state && 'live-card'}`}>
            <div className="dialog" role="dialog">
              <div className="live-card">
                <div className={`videoModalWrapper open live-error-msg`}>
                  <div className="close-btn-wrapper" tabIndex="0">
                    <button
                      className="close-btn icon-panel live-dialog-close-btn accessibilityCloseModal videoCloseIcon"
                      tabIndex="0"
                      title="close"
                      aria-label="close"
                      onClick={(e) => {
                        if (liveStreamEnded && liveStreamEnded.value) {
                          setLiveStreamEnded({ value: false });
                        } else {
                          !isExperienceEditorActive() && callApi();
                          setMediaStatus({ playing: false });
                          const element = document.getElementsByTagName('BODY');
                          if (element) {
                            element[0].removeAttribute('style');
                          }
                        }
                      }}
                    >
                      <span>
                        <i className="icon-cross" data-closemodal="close" />
                      </span>
                    </button>
                  </div>
                  <div
                    className={`videoModal card-expan`}
                    style={mediaStatus && mediaStatus.modalCss ? mediaStatus.modalCss : {}}
                  >
                    <div
                      className={`stream__content ${
                        mediaStatus && mediaStatus.modalCss ? '' : 'img-holder'
                      }`}
                    >
                      <div className="mu-item__gradient" />
                      <h1 className="stream__title">{renderEndedMsg()}</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {cbrModalVisible && (
        <ErrorBoundary>
          <RegistrationModal
            successCallback={successCallback}
            closeModal={cbrCloseModal}
            fallback={<Loader />}
            previousActiveElement={document.activeElement}
            accessType={contentAccessStateFunc()}
            analyticsObj={getEPGLiveAnalyticsData(state?.fields, contentAccessStateFunc())}
          />
        </ErrorBoundary>
      )}
    </>
  );
};

export default withTranslation()(SpotlightandEpg);
