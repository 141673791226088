/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import componentFactory from '../temp/componentFactory';
/*
    -- import the transform components here
    -- transform component name must be same as scaffold component name
*/
import { Article } from './components/Article';
import { Video } from './components/Video';
import { Collection } from './components/Collection';
import { LiveStream } from './components/LiveStream';
import { Quotes } from './components/Quotes';
import { Quiz } from './components/Quiz';
import { Image } from './components/Image';
import { ImageGallery } from './components/ImageGallery';
import { LiveBlogEvent } from './components/LiveBlogEvent';
import { AdCard } from './components/AdCard';
import { Googleprovider } from './components/Googleprovider';
import { FanaticsProduct } from './components/FanaticsProduct';
import { PlayerProfile } from './components/PlayerProfile';
import { Social } from './components/Social';
import MatchCard from '../components/Cards/MatchCard';
import { Poll } from './components/Poll';
import { Podcast } from './components/Podcast';
import { Unitedcompetition } from './components/Unitedcompetition';
import { ExternalProductCarousel } from './components/ExternalProductCarousel';
import { EComProductFooter } from './components/EComProductFooter';
import { SelectEComProductFooter } from './components/SelectEComProductFooter';

/*
    -- Add the transform component name as a key and value here
    -- Value must be transform component name
    -- Key can be the contenttype_t in such cases
*/
const components = {
  article: { data: Article, name: 'Article' },
  video: { data: Video, name: 'Video' },
  collection: { data: Collection, name: 'Collection' },
  video360degree: { data: Video, name: 'Video' },
  livestream: { data: LiveStream, name: 'LiveStream' },
  quote: { data: Quotes, name: 'Quotes' },
  image: { data: Image, name: 'Image' },
  gallery: { data: ImageGallery, name: 'ImageGallery' },
  eventmaster: { data: LiveBlogEvent, name: 'LiveBlogEvent' },
  adcard: { data: AdCard, name: 'AdCard' },
  googleprovider: { data: Googleprovider, name: 'Googleprovider' },
  quiz: { data: Quiz, name: 'Quiz' },
  shop: { data: FanaticsProduct, name: 'FanaticsProduct' },
  MatchCard: { data: MatchCard, name: 'MatchCard' },
  playerprofile: { data: PlayerProfile, name: 'PlayerProfile' },
  social: { data: Social, name: 'Social' },
  poll: { data: Poll, name: 'Poll' },
  podcast: { data: Podcast, name: 'Podcast' },
  unitedcompetition: { data: Unitedcompetition, name: 'Unitedcompetition' },
  externalProduct: { data: ExternalProductCarousel, name: 'ExternalProductCarousel' },
  ecomProductFooter: { data: EComProductFooter, name: 'ExternalProductCarousel' },
  selectEComProductFooter: { data: SelectEComProductFooter, name: 'ExternalProductCarousel' },
};
/*
    -- Function is responsible to import the scaffold and transform component dynamically
*/

export const importComponent = (componentName) => {
  return {
    data: components[componentName] && components[componentName].data,
    component: componentFactory(components[componentName] && components[componentName].name),
  };
};
